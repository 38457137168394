import CustomTable from '../shared/Table';
import Title from '../shared/Title';
import {
	Box,
	Button,
	Center,
	FormControl,
	HStack,
	Icon,
	Input,
	InputGroup,
	useBreakpointValue,
	VStack,
} from '@chakra-ui/react';
import Paginator from '../shared/Paginator';
import { FiSearch } from 'react-icons/fi';
import { MdOutlineAdd } from 'react-icons/md';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, PAGES } from '../../shared/routes';
import { useFetch } from '../../shared/hooks';
import { generateQueryString } from '../../shared/helpers';
import { PaginationOptions, Roles } from '../../shared/enums';
import { useStore } from '../../store';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import { DarkGreen } from '../../shared/enums';


function Clients() {
	const searchElement = useRef<HTMLInputElement>(null);
	const [search, setSearch] = useState('');
	const [clients, setClients] = useState([]);
	const [pagination, setPagination] = useState(PaginationOptions);
	const flexDirection = useBreakpointValue({ base: 'column', lg: 'row' });
	const { state, isAuthorized } = useStore();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const isLargeScreen = useBreakpointValue({ base: false, xl: true });	

	useEffect(() => {
		if (!isAuthorized([Roles.ADMIN]) && state.user.groups)
			navigate(PAGES.NOT_AUTHORIZED);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.user]);

	const { loading } = useFetch(
		generateQueryString(API.get.CLIENTS, {
			search,
			limit: pagination.limit,
			offset: pagination.offset,
		}),
		null,
		({ data }: any) => {
			setPagination({
				...pagination,
				total: data.count,
			});
			setClients(
				data.results.map((client) => ({
					...client,
					country: client.country ? (
						<ReactCountryFlag
							style={{
								width: '2rem',
								height: '2rem',
							}}
							countryCode={client.country}
							svg
							title={client.country}
						/>
					) : (
						'-'
					),
					actions: (
						<HStack gap="0.5rem" justify="center">
							<Button
								borderRadius="0.25rem"
								bg="transparent"
								border="1px solid"
								borderColor={DarkGreen.tableBorderColor}
								size="sm"
								_hover={{
									color: 'black',
									background: DarkGreen.iconColor,
								}}
								onClick={() => {
									navigate(`${PAGES.PROJECTS}?client=${client.domain}`);
								}}
							>
								{t('Details')}
							</Button>
							<Button
								borderRadius="0.25rem"
								bg="transparent"
								border="1px solid"
								borderColor={DarkGreen.tableBorderColor}
								size="sm"
								_hover={{
									color: 'black',
									background: DarkGreen.iconColor,
								}}
								onClick={() => {
									navigate(`${PAGES.EMPLOYEES}?client=${client.domain}`);
								}}
							>
								{t('Employees')}
							</Button>
							<Button
								borderRadius="0.25rem"
								bg="transparent"
								border="1px solid"
								borderColor={DarkGreen.tableBorderColor}
								size="sm"
								_hover={{
									color: 'black',
									background: DarkGreen.iconColor,
								}}
								onClick={() => {
									navigate(`${PAGES.CLIENTS}/${client.id}/edit`);
								}}
							>
								{t('Edit')}
							</Button>
						</HStack>
					),
				})),
			);
		},
	);

	return (
		<>
			<Title
				data-aos="fade-down"
				title={t('Clients')}
				px={{ md: '4rem', base: '2rem' }}
				mb="3rem"
			/>
			<VStack
				data-aos="fade-down"
				mx={{ md: '6rem', base: '1rem' }}
				spacing="0"
				gap="1rem"
				mb="2rem"
			>
				<HStack
					justify="space-between"
					w="100%"
					flexDir={{ lg: 'row', base: 'column' }}
					rowGap="1rem"
					columnGap="2rem"
				>
					<Formik
						initialValues={{}}
						onSubmit={(e) => {
							setPagination({
								...pagination,
								offset: 0,
								currentPage: 1,
							});
							setSearch(searchElement.current?.value);
						}}
					>
						<Form
							style={{
								width: '100%',
								display: 'flex',
								gap: '1rem',
								flexDirection: flexDirection === 'row' ? 'row' : 'column',
							}}
						>
							<FormControl display="flex" gap="1rem" alignItems="center">
								<HStack>
								<InputGroup w="auto" maxW="20rem">
									<Input
										w="420px"
										bg="transparent"
										borderColor={DarkGreen.tableBorderColor}
										color="white"
										borderRadius="0.25rem"
										placeholder={t('Search')}
										_placeholder={{
											color: "white"
										}}
										onChange={(e) => {
											if (e.target.value.length === 0) {
												setSearch('');
											}
										}}
										ref={searchElement}
									/>
								</InputGroup>
								<Center
										h="0.6rem"
										w="4rem"
										pos="relative"
										mt="0.2rem"
										ml="-5rem"
										padding={"1rem"}
										cursor="pointer"
										as="button"
										type="submit"
									>
										<Box
											clipPath="polygon(10% 0, 100% 0, 90% 100%, 0 100%);"
											h="0.6rem"
											w="4rem"
											pos="absolute"
											bg={DarkGreen.tableBorderColor}
										></Box>
										<Icon
											pos="absolute"
											w="2rem"
											h="2rem"
											color="white"
											
											as={FiSearch}
										/>
									</Center> 
								</HStack>
							</FormControl>
							<HStack
								w="100%"
								justify={{ lg: 'flex-end', base: 'flex-start' }}
								gap="1rem"
								spacing={0}
							>
								{isAuthorized([Roles.CLIENT_ADMIN, Roles.ADMIN]) && (
									<Icon
										onClick={() => {
											navigate(PAGES.NEW_CLIENT);
										}}
										as={MdOutlineAdd}
										cursor="pointer"
										w="48px"
										h="48px"
										title={t('projects.New')}
										color={DarkGreen.iconColor}
									/>
								)}
							</HStack>
						</Form>
					</Formik>
				</HStack>
				<Box w="100%" overflowX="visible" maxH="50rem" 
				>
					<CustomTable
						minW="100%"
						min_width={isLargeScreen? "none":"500px" }
						columns= {isLargeScreen? [
							
							{
								title: t('clients.Id'),
								accessor: 'id',
								sortable: true,
							},
							{
								title: t('Name'),
								accessor: 'name',
								sortable: true,
							},
							{
								title: t('Country'),
								accessor: 'country',

							},
							{
								title: t('CreatedOn'),
								accessor: 'created_on',
								sortable: true,
							},
							{
								title: '',
								accessor: 'actions',
							},
						]: [
							
							// {
							// 	title: t('ID'),
							// 	accessor: 'id',
							// 	sortable: true,
							// },
							{
								title: t('Country'),
								accessor: 'country',

							},
							{
								title: t('Name'),
								accessor: 'name',
								sortable: true,
							},
							{
								title: '',
								accessor: 'actions',
							},
						]}
						data={clients}
						isLoading={loading}
					/>
				</Box>

				<Paginator
					isLoading={loading}
					total={pagination.total}
					onPageChange={(number) => {
						setPagination({
							...pagination,
							currentPage: number,
							offset: (number - 1) * pagination.limit,
						});
					}}
					onPerPageChange={(number) => {
						setPagination({
							...pagination,
							limit: number,
							offset: 0,
							currentPage: 1,
						});
					}}
					currentPage={pagination.currentPage}
				/>
			</VStack>
		</>
	);
}

export default Clients;
