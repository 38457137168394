import { HeadingProps, Heading } from '@chakra-ui/react';
interface TitleProps extends HeadingProps {
	title: string;
}

function Title(props: TitleProps) {
	const { title, ...rest } = props;
	return (
		<Heading
			px={{ md: '6rem', base: '1rem' }}
			ml={{md: '1.5rem' ,base: '0'}}
			{...rest}
			fontSize="2.6875rem"
			color="whitesmoke"
			fontWeight="normal"
		>
			{title}
		</Heading>
	);
}

export default Title;
