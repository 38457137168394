import CustomTable from '../shared/Table';
import Title from '../shared/Title';
import {
	Avatar,
	Box,
	Button,
	Center,
	FormControl,
	HStack,
	Icon,
	Input,
	InputGroup,
	Text,
	useBreakpointValue,
	VStack,
} from '@chakra-ui/react';
import Paginator from '../shared/Paginator';
import { FiSearch } from 'react-icons/fi';
import { MdOutlineAdd } from 'react-icons/md';
import { useState, useRef, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API, PAGES } from '../../shared/routes';
import { useFetch } from '../../shared/hooks';
import { generateQueryString, getClientAPIUrl } from '../../shared/helpers';
import { DarkGreen, PaginationOptions, Roles } from '../../shared/enums';
import { useStore } from '../../store';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

function Employees() {
	const searchElement = useRef<HTMLInputElement>(null);
	const [search, setSearch] = useState('');
	const [employees, setEmployees] = useState([]);
	const [pagination, setPagination] = useState(PaginationOptions);
	const [searchParams] = useSearchParams();
	const flexDirection = useBreakpointValue({ base: 'column', lg: 'row' });
	const { state, isAuthorized } = useStore();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const client = searchParams.get('client');
	const isDronodat = !isAuthorized([Roles.CLIENT_ADMIN]) && client;
	const isLargeScreen = useBreakpointValue({ base: false, xl: true });	
	const isMidScreen = useBreakpointValue({ base: false,xs:true,md:true, lg:true ,xl: false});	


	

	useEffect(() => {
		if (!isAuthorized([Roles.ADMIN, Roles.CLIENT_ADMIN]) && state.user.groups)
			navigate(PAGES.NOT_AUTHORIZED);
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.user]);

	const { loading } = useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.USERS,
			{
				search,
				limit: pagination.limit,
				offset: pagination.offset,
			},
		),
		null,
		({ data }: any) => {
			setPagination({
				...pagination,
				total: data.count,
			});
			setEmployees(
				data.results.map((employee) => ({
					...employee,
					name: (
							
							<HStack w="fit-content" ml="20px" textAlign={"center"}  >
							{isMidScreen &&
							<Avatar
								h="40px"
								w="40px"
								src={employee.avatar}
								name={employee.name}
							/>}
							<Text>{employee.name ? employee.name : '- -'}</Text>
						</HStack>
					),
					actions: (
						<HStack gap="0.5rem" justify="center">
							<Button
								borderRadius="0.25rem"
								bg="transparent"
								border="1px solid"
								borderColor={DarkGreen.ButtonBorderColor}
								color="white"
								size="sm"
								_hover={{
									color: 'black',
									background:DarkGreen.iconColor,
								}}
								onClick={() => {
									navigate(
										`${PAGES.USERS}/${employee.id}${
											isDronodat ? `?client=${client}` : ''
										}`,
									);
								}}
							>
								{t('Edit')}
							</Button>
						</HStack>
					),
				})),
			);
		},
	);

	return (
		<>
			<Title
				title={t('Employees')}
				px={{ md: '4rem', base: '2rem' }}
				mb="3rem"
				data-aos="fade-down"
			/>
			<VStack
				data-aos="fade-down"
				mx={{ md: '6rem', base: '1rem' }}
				spacing="0"
				gap="1rem"
				mb="2rem"
			>
				<HStack
					justify="space-between"
					w="100%"
					flexDir={{ lg: 'row', base: 'column' }}
					rowGap="1rem"
					columnGap="2rem"
					mt = "2rem" scale={"0.8"} 
				>
					<Formik
						initialValues={{}}
						onSubmit={(e) => {
							setPagination({
								...pagination,
								offset: 0,
								currentPage: 1,
							});
							setSearch(searchElement.current?.value);
						}}
					>
						<Form
							style={{
								width: '100%',
								display: 'flex',
								gap: '1rem',
								flexDirection: flexDirection === 'row' ? 'row' : 'column',
							}}
						>
							<FormControl display="flex" gap="1rem" alignItems="center">
							<HStack 
								ml="0"
								_hover={{
									"& > button > div": {
										bg: DarkGreen.iconColor
									}
								}}
							>
								<InputGroup w="auto" maxW="20rem">
									<Input
										w="420px"
										bg="transparent"
										borderColor={DarkGreen.tableBorderColor}
										color="white"
										borderRadius="0.25rem"
										placeholder={t('Search')}
										_placeholder={{
											color: "white"
										}}

										onChange={(e) => {
											if (e.target.value.length === 0) {
												setSearch('');
											}
										}}
										type="text"
										size="md"
										ref={searchElement}
									/>
								</InputGroup>
								
								<Center
										h="0.6rem"
										w="4rem"
										pos="relative"
										mt="0.2rem"
										ml="-5rem"
										padding={"1rem"}
										cursor="pointer"
										as="button"
										type="submit"
									>
										<Box
											clipPath="polygon(10% 0, 100% 0, 90% 100%, 0 100%);"
											h="0.6rem"
											w="4rem"
											pos="absolute"
											bg={DarkGreen.tableBorderColor}
										></Box>
										<Icon
											pos="absolute"
											w="2rem"
											h="2rem"
											color="white"
											
											as={FiSearch}
										/>
									</Center> 
									</HStack>
							</FormControl>
							<HStack
								w="100%"
								justify={{ lg: 'flex-end', base: 'flex-start' }}
								gap="1rem"
								spacing={0}
							>
								{isAuthorized([Roles.CLIENT_ADMIN, Roles.ADMIN]) && (
									<Icon
										onClick={() => {
											navigate(
												`${PAGES.NEW_USER}${
													isDronodat ? `?client=${client}` : ''
												}`,
											);
										}}
										as={MdOutlineAdd}
										cursor="pointer"
										w="48px"
										h="48px"
										title={t('projects.New')}
										color={DarkGreen.iconColor}
									/>
								)}
							</HStack>
						</Form>
					</Formik>
				</HStack>
				<Box w="100%" overflowX="visible" maxH="40rem"
							minW={isLargeScreen? "950px": isMidScreen? "550px": "410px"}
							>
					<CustomTable
						minW="100%"
						min_width = "${{lg:'1000px',md:'600px',base:'500px'}}"
						columns={
							isLargeScreen ? [		
								{			
									title: t('Name'),			
									accessor: 'name',			
									sortable: true,		
								},		
								{
									title: t('Title'),
									accessor: 'title',
									sortable: true,
								},
								{
															
									title: t('Service'),
									accessor: 'service',			
									sortable: true,		
								},										
								{
									title: t('Phone'),
									accessor: 'phone_number',
								},
								{
									title: t('Email'),
									accessor: 'email',
									sortable: true,
								},
								{			
									title: '',			
									accessor: 'actions',		
								},	

								] : isMidScreen ? [
								{
									title: t('Name'),
									accessor: 'name',
									sortable: true,
								},
								{
									title: t('Email'),
									accessor: 'email',
									sortable: true,
								},
								{
									title: '',
									accessor: 'actions',
								},
							] : [
								{
									title: t('Name'),
									accessor: 'name',
									sortable: true,
									tdMaxWidth:"120px"

								},
								{
									title: t('Email'),
									accessor: 'email',
									sortable: true,
									tdMaxWidth:"200px"
								},
								{
									title: '',
									accessor: 'actions',
								},
							]
						}
						data={employees}
						isLoading={loading}
					/>
				</Box>

				<Paginator
					isLoading={loading}
					total={pagination.total}
					onPageChange={(number) => {
						setPagination({
							...pagination,
							currentPage: number,
							offset: (number - 1) * pagination.limit,
						});
					}}
					onPerPageChange={(number) => {
						setPagination({
							...pagination,
							limit: number,
							offset: 0,
							currentPage: 1,
						});
					}}
					currentPage={pagination.currentPage}
				/>
			</VStack>
		</>
	);
}

export default Employees;
