// eslint-disable-next-line import/no-anonymous-default-export
export default {
	global: {
		// styles for the `body`
		'body': {
			// bg: 'gray.400',
			color: 'white',
		},
		'.map .mapboxgl-ctrl-top-left': {
			right: '1rem',
			left: 'initial',
			top: '8.1875rem',
			width: '213px',
		},
		'.project .mapboxgl-ctrl-top-left': {
			right: '1rem',
			left: 'initial',
			top: '1rem',
			width: '213px',
		},
		'.mapboxgl-ctrl-geocoder': {
			minWidth: '213px',
			margin: '0 !important',
		},
		// '*': {
		// 	transition: 'all 0.1s ease-in-out',
		// },
		'_hover': {
			transition: '0.1s',
		},
		'::-webkit-calendar-picker-indicator': {
			filter: 'invert(1)',
			cursor: 'pointer',
		},
		'::-webkit-scrollbar': {
			width: '0.5rem',
			height: '0.5rem',
		},
		'::-webkit-scrollbar-track': {
			background: 'white',
		},
		'::-webkit-scrollbar-thumb': {
			background: 'darkGray',
			borderRadius: '0.1rem',
		},
		'::-webkit-scrollbar-thumb:hover': {
			background: 'gray',
		},
		'#mapContainer .chakra-modal__content-container': {
			width: '100% !important',
			height: 'initial !important',
		},
	},
};
