import { useTranslation } from 'react-i18next';
import { Image } from '@chakra-ui/react';
import { enFlag, deFlag } from '../../assets/icons';

function LanguageSwitcher() {
	const { i18n } = useTranslation();
	const isEnglish = i18n.language.includes('en');
	const flag = isEnglish ? deFlag : enFlag;
	return (
		<Image
			w="1.375rem"
			mt="0.4375rem"
			onClick={() => {
				i18n.changeLanguage(isEnglish ? 'de' : 'en');
			}}
			src={flag}
			cursor="pointer"
		/>
	);
}

export default LanguageSwitcher;
