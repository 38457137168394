import axios from 'axios';
import { getClientAPIUrl, getCookie, setCookie } from './helpers';
import jwt_decode from 'jwt-decode';
import { API, PAGES } from './routes';

const subdomain = window.location.host.split('.')[1]
	? window.location.host.split('.')[0]
	: false;

const API_URL: any =
	subdomain !== 'app' && !window.location.pathname.includes('reset-password')
		? getClientAPIUrl(subdomain)
		: process.env.REACT_APP_API;

const api = axios.create({
	withCredentials: true,
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

api.interceptors.request.use(
	async (config) => {
		const accessToken = getCookie('access_token');
		const refreshToken = getCookie('refresh_token');
		if (accessToken && refreshToken) {
			const decoded: any = jwt_decode(accessToken);
			const exp = decoded.exp * 1000;
			if (exp < Date.now()) {
				const res = await axios.post(
					process.env.REACT_APP_API + API.post.REFRESH,
					{
						refresh: refreshToken,
					},
				);
				setCookie('access_token', res.data.access);
				config.headers.Authorization = 'Bearer ' + res.data.access;
			} else {
				config.headers.Authorization = 'Bearer ' + accessToken;
			}
		} else {
			// window.location.href = PAGES.LOGIN;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export default api;
