import {
	Box,
	Text,
	HStack,
	VStack,
	Heading,
	Icon,
	useToast,
	SimpleGrid,
	Image,
	Center,
	Input,
	InputGroup,
	Button,
	Circle,
	useDisclosure,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useBreakpointValue,
	FormControl,
} from '@chakra-ui/react';
import { useState, useRef, useEffect } from 'react';
import { FaDownload, FaEdit } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { MdArrowBackIosNew, MdOutlineAdd } from 'react-icons/md';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PaginationOptions, Roles, Status ,DarkGreen} from '../../shared/enums';
import {
	generateQueryString,
	getClientAPIUrl,
	getDate,
} from '../../shared/helpers';
import { useFetch } from '../../shared/hooks';
import { API, PAGES } from '../../shared/routes';
import Loader from '../Loader';
import Paginator from '../shared/Paginator';
import Select from '../shared/Select';
import { Option } from '../shared/Select/Option';
import CustomTable from '../shared/Table';
import ReactApexChart from 'react-apexcharts';
import { RiUploadCloudLine } from 'react-icons/ri';
import { BsThreeDotsVertical } from 'react-icons/bs';
import api from '../../shared/api';
import { AiFillDelete } from 'react-icons/ai';
import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import ConfirmDelete from '../shared/ConfirmDelete';
import Map from '../Maps/Project';

function ProjectDetails() {
	const inputFile = useRef(null);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const missionSearchElement = useRef<HTMLInputElement>(null);
	const [fileTitle, setFileTitle] = useState('');
	const reportSearchElement = useRef<HTMLInputElement>(null);
	const [refresh, setRefresh] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const [progress, setProgress] = useState(0.0);
	const [missions, setMissions] = useState([]);
	const [missionsType, setMissionsType] = useState('');
	const [reports, setReports] = useState([]);
	const [reportsType, setReporstType] = useState('');
	const [missionsSearch, setMissionsSearch] = useState('');
	const [missionsPagination, setMissionsPagination] =
		useState(PaginationOptions);
	const [reportsSearch, setReportsSearch] = useState('');
	const [reportsPagination, setReportsPagination] = useState(PaginationOptions);
	const { isAuthorized } = useStore();
	const [searchParams] = useSearchParams();
	const { id } = useParams();
	const navigate = useNavigate();
	const toast = useToast();
	const { t } = useTranslation();
	const flexDirection = useBreakpointValue({ base: 'column', lg: 'row' });
	const statusColors = ['2E4765', '#CF304A', '#F2C018', '#03A66D'];
	const client = searchParams.get('client');
	const [ProgressUploadValue, setProgressUploadValue] = useState(null);
	const isDronodat =
		!isAuthorized([Roles.CLIENT_EMPLOYEE, Roles.CLIENT_ADMIN]) && client;
	const isLargeScreen = useBreakpointValue({ base: false, xl: true });	
	const isMidScreen = useBreakpointValue({ base: false, md:true });	

	// useEffect(() => {
	// 	if (
	// 		!isAuthorized([Roles.CLIENT_EMPLOYEE, Roles.CLIENT_ADMIN]) &&
	// 		state.user.groups &&
	// 		!client
	// 	)
	// 		navigate(PAGES.NOT_AUTHORIZED);
	// }, [state.user]);

	useEffect(() => {
		if (reports.length > 0) setRefresh(refresh + 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reports]);

	const CustomMenu = ({ report }) => {
		const {
			isOpen: isOpenDelete,
			onOpen: onOpenDelete,
			onClose: onCloseDelete,
		} = useDisclosure();

		return (
			<>
				<ConfirmDelete
					onClose={onCloseDelete}
					isOpen={isOpenDelete}
					onConfirm={async () => {
						try {
							setIsUploading(true);
							await api.delete(
								(isDronodat ? getClientAPIUrl(client) : '') +
									API.post.REPORTS +
									report.id +
									'/',
							);
							toast({
								title: t('reports.delete.toast.Title'),
								description: t('reports.delete.toast.Description'),
								status: 'success',
								duration: 3000,
								isClosable: true,
								position: 'bottom-right',
							});
						} catch (e) {
							toast({
								title: t('Error'),
								description: t('TryAgain'),
								status: 'error',
								duration: 3000,
								isClosable: true,
								position: 'bottom-right',
							});
						} finally {
							setIsUploading(false);
						}
					}}
				/>
				<Menu>
					<MenuButton
						as={IconButton}
						aria-label="Options"
						title={t("Options")}
						icon={<BsThreeDotsVertical />}
						variant="ghost"
						color="white"
						_hover={{
							color: 'white',
							bg: DarkGreen.iconColor,
						}}
						_active={{
							color: 'white',
							bg: DarkGreen.iconColor,
						}}
					/>
					<MenuList>
						<MenuItem
							onClick={() => {
								window.location.href = report.file;
							}}
							color="black"
							icon={<FaDownload />}
						>
							{t('Download')}
						</MenuItem>
						<MenuItem
							onClick={onOpenDelete}
							color="black"
							icon={<AiFillDelete />}
						>
							{t('Delete')}
						</MenuItem>
					</MenuList>
				</Menu>
			</>
		);
	};

	const {
		data: project,
		projectLoading,
		isError: projectError,
	}: any = useFetch(
		(isDronodat ? getClientAPIUrl(client) : '') + API.get.PROJECTS + id + '/',
		null,
		({ data }: any) => {
			const sum: any = Object.values(data?.summary.missions).reduce(
				(acc: any, curr: any) => acc + curr,
				0,
			);
			const result = (data?.summary.missions[3] / sum) * 100;
			setProgress(result ? result : 0);
		},
	);

	const { loading: missionsLoading, isError: missionsError } = useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.MISSIONS,
			{
				project: id,
				type: missionsType,
				search: missionsSearch,
				limit: missionsPagination.limit,
				offset: missionsPagination.offset,
			},
		),
		null,
		({ data }: any) => {
			setMissionsPagination({
				...missionsPagination,
				total: data.count,
			});
			setMissions(
				data.results.map((mission) => ({
					...mission,
					started_on: mission.started_on
						? new Date(mission.started_on).toISOString().split('T')[0]
						: '-',
						finished_on: mission.finished_on
						? new Date(mission.finished_on).toISOString().split('T')[0]
						: '-',
					status: (
						<Text color={statusColors[mission.status]}>
							{t(Status[mission.status])}
						</Text>
					),
					actions: (
						<Button
							// display={"none"}
							borderRadius="0.25rem"
							ml= "0rem"
							bg={DarkGreen.ButtonBackgroundColor}
							border={DarkGreen.ButtonBorderColor}
							borderColor={DarkGreen.tableBorderColor} //"rgba(0, 196, 106, 0.2)"//{DarkGreen.iconColor}
							color="rba(255,255,255,1)" //{DarkGreen.tableHighlightedColor}
							size="sm"
							_hover={{
								color: 'white',
								background: DarkGreen.ButtonHighlightColor,
							}}
							onClick={() => {
								navigate(
									`${PAGES.MISSIONS}/${mission.id}${
										isDronodat ? `?client=${client}` : ''
									}`,
								);
							}}
						>
							{t('Details')}
						</Button>
					),
				})),
			);
		},
		[missionsType],
	);

	const { loading: reportsLoading, isError: reportsError } = useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.REPORTS,
			{
				project: id,
				type: reportsType,
				search: reportsSearch,
				limit: reportsPagination.limit,
				offset: reportsPagination.offset,
			},
			),
			isUploading,
			({ data }: any) => {
				const types = { 1: 'kml', 2: 'pdf', 3: 'xlsx', 4: 'las' };
				const images = {
					1: '/assets/images/kml.jpeg',
					2: '/assets/images/pdf.jpeg',
					3: '/assets/images/xlsx.jpeg',
				4: '/assets/images/las.png',
			};
			setReportsPagination({
				...reportsPagination,
				total: data.count,
			});
			setReports(
				data.results.map((report) => ({
					...report,
					uploaded_on: new Date(report.uploaded_on).toISOString().split('T')[0],
					title: (<Text title={report.title}> {report.title}</Text>),
					type: (<Text color={DarkGreen.iconColor}> {types[report.type].toUpperCase()}</Text>),
					image: (
						<Image
						w="64px"
						h="32px"
						m="0 auto"
						src={images[report.type]}
						alt={types[report.type]}
						/>
						),
						actions: <CustomMenu report={report} />,
					})),
					);
				},
				[reportsType, isUploading],
				);
				
				if (projectError || missionsError || reportsError) navigate(PAGES.NOT_FOUND);
				
				useEffect(() => {
		if (!isOpen) {
			// setFile(null);
			setFileTitle('');
		}
	}, [isOpen]);

	return (
		<>
			<Input
				type="file"
				ref={inputFile}
				id="file"
				display="none"
				accept=".kml,.pdf,.xlsx,.las"
				
				onChange={async (e) => {
					
					const types = { kml: '1', pdf: '2', xlsx: '3', las: '4' };
					const file = e.target.files[0];
					
					// setFile(file);
					const formData = new FormData();
					
					formData.append('client', client);
					formData.append('file', file);
					formData.append('project', id);
					formData.append('title', fileTitle);
					formData.append('type', types[file.name.split('.').pop()]);
					
					
					try {
						setIsUploading(true);
						
						await api.post(
							(isDronodat ? getClientAPIUrl(client) : '') + API.post.REPORTS,
							formData,
							{
								headers: {
								  "Content-Type": "multipart/form-data",
								},
								onUploadProgress: (progressEvent) => {
									
									const progress = (progressEvent.loaded / progressEvent.total) * 50;
									//setProgress(progress);
									setProgressUploadValue(progress.toFixed(2)+'%')
								}
							}
						);
						toast({
							title: t('reports.new.toast.Title'),
							description: t('reports.new.toast.Description'),
							status: 'success',
							duration: 3000,
							isClosable: true,
							position: 'bottom-right',
						});
						onClose();
					} catch (e) {
						toast({
							title: t('Error'),
							description: t('TryAgain'),
							status: 'error',
							duration: 3000,
							isClosable: true,
							position: 'bottom-right',
						});
					} finally {
						setIsUploading(false);
						inputFile.current.value = '';
						
						//If we upload las full we reload the page 
						if(types[file.name.split('.').pop()] == 4){
								window.location.reload()	
							}

						}
				}}
			/>
			<Modal size="xl" onClose={onClose} isOpen={isOpen} isCentered>
				<ModalOverlay />
				<ModalContent
					borderColor="#73BCB6"
					borderWidth="2px"
					p="4rem"
					bg="rgba(0,0,0,1)"
				>
					<ModalCloseButton />
					<ModalBody textAlign="center">
						<Icon as={RiUploadCloudLine} w="10.8125rem" h="10.8125rem" />
						<Text fontSize="21px" fontWeight={300}>
							{t('upload.Description')}
						</Text>
						<Input
							mt="4rem"
							bg="transparent"
							color="#73BCB6"
							borderColor="#73BCB6"
							borderRadius="0.25rem"
							placeholder={t('Title')}
							type="text"
							size="md"
							onChange={(e) => {
								setFileTitle(e.target.value);
							}}
							value={fileTitle}
						/>
						<Button
							mt="2rem"
							background="transparent linear-gradient(180deg, #00965B 0%, #065688 100%) 0% 0% no-repeat padding-box;"
							_hover={{
								background:
								'transparent linear-gradient(180deg, #065688 0%, #00965B 100%) 0% 0% no-repeat padding-box;',
							}}
							type="button"
							borderRadius={'0.25rem'}
							color="white"
							w="full"
							onClick={() => {
								inputFile.current.click();
							}}
							isDisabled={fileTitle === '' ? true : false}
							isLoading={isUploading}
							loadingText={ProgressUploadValue}
						>
							{t('upload.Submit')} 
						</Button>
					</ModalBody>
				</ModalContent>
			</Modal>
			{projectLoading ? (
				<Loader />
				) : (
					<Box data-aos="fade-down" mx={{ md: '6rem', base: '1rem' }} mb="3rem">
						<HStack>
							<Icon
								cursor="pointer"
								color={DarkGreen.iconColor}
								onClick={() =>
									navigate(
										PAGES.PROJECTS +
											'/' +
											(isDronodat ? `?client=${client}` : ''),
											)
										}
										// pt="20px"
										w="3rem"
										h="3rem"
										mr="0.5rem"
										my="0"
										// pt="0.4rem"
										top="0"
										as={MdArrowBackIosNew}
							/>
						<Heading
						px={{ md: '1rem', base: '1rem' }}
						ml={{md: '0rem' ,base: '0'}}
						fontSize="2.6875rem"
						color="whitesmoke"
						fontWeight="normal"
						>
						{t("Project Info")}
						</Heading>

					</HStack>
					<HStack
						flexDir={{ xl: 'row', base: 'column' }}
						align="flex-start"
						gap="4rem"
						mb="4rem"
						
					>
						<Box mt="1.5rem !important" w="100%" h="25rem" mx="auto !important" boxShadow="4px 6px 20px #000000">
							<Map
								isDronodat={isDronodat}
								projectId={id}
								isUploading={isUploading}
							/>
						</Box>
					</HStack>
					<HStack
						flexDir={{ xl: 'row', lg: 'row',md:'column', base: 'column' }}
						w="100%"
						align="flex-start"
						justify={{lg: 'flex-start', base: 'flex-start'}}
						gap="4rem"
						mb="4rem"
						top="0"
					>
						<VStack   // Mission Details Box
						justify="flex-start"
						 w={{ lg: '25%', base: '100%' }}
						 order = {{lg:"1",base:"2"}}
						>
						<SimpleGrid
							rowGap="1rem"
							columns={1}
							bgColor = "transparent"// {DarkGreen.tableBackgroundColor}
							p={"20px"}
							boxShadow="1px 2px 20px #000000"
							border="1px solid"
							borderColor= {DarkGreen.tableBorderColor}
							borderRadius= "18px"

							minW={{lg:"300px",base:"300px"}}
							mt={{lg:"0rem",base:"0rem"}}
							ml={{lg:"0rem",base:"2rem"}}
							>
						<Box //Project Title
						width ="100%" 
						>
							<Box
								fontSize="24px"
								color="whitesmoke"
								fontWeight="normal"
								// alignItems={"center"}
								// marginTop="auto"

							>
								{project?.name}

								{isAuthorized([Roles.ADMIN, Roles.CLIENT_ADMIN]) && (
									<Icon
										color={DarkGreen.iconColor}
										cursor="pointer"
										onClick={() => {
											navigate(
												PAGES.PROJECTS +
													'/' +
													id +
													'/edit' +
													(isDronodat ? `?client=${client}` : ''),
											);
										}}
										w="2rem"
										h="2rem"
										pt="0.4rem"
										ml="1rem"



										as={FaEdit}
									/>
								)}
								</Box>
								</Box>
								<Box>
									<Text color="white" fontWeight={500} fontSize="18px">
										{t('Address')}
									</Text>
									<Text color={DarkGreen.layersBoxBorderTextColorMuted} fontSize="14px">
										{project?.address ? project.address : '-'}
									</Text>
								</Box>
								<Box >
									<Text color="white" fontWeight={500} fontSize="18px">
										{t('projects.PunchIn')}
									</Text>
									<Text color={DarkGreen.layersBoxBorderTextColorMuted} fontSize="14px">
										{project?.started_on ? getDate(project.started_on) : '-'}
									</Text>
								</Box>
								<Box>
									<Text color="white" fontWeight={500} fontSize="18px">
										{t('projects.PunchOut')}
									</Text>
									<Text color={DarkGreen.layersBoxBorderTextColorMuted} fontSize="14px">
										{project?.finished_on ? getDate(project.finished_on) : '-'}
									</Text>
								</Box>
								<Box>
									<Text color="white" fontWeight={500} fontSize="18px">
										{t('Status')}
									</Text>
									<Text
										color={
											project?.status ? statusColors[project.status] : '#2E4765'
										}
										fontSize="14px"
									>
										{t(Status[project?.status])}
									</Text>
								</Box>
									<Box //Powerlines and Generate Report Buttons
										w="100%"
										minW="250px"
										flexDir="row"
										>
											<Button
												w="100%"
												// ml="10%"
												borderRadius="0.25rem"
												bg="transparent"
												border="1px solid"
												size="sm"
												borderColor={DarkGreen.tableBorderColor} //"rgba(0, 196, 106, 0.5)"//{DarkGreen.iconColor}
												color="rba(255,255,255,1)" //{DarkGreen.tableHighlightedColor}
												_hover={{
													color: 'white',
													background: 'rgba(0, 196, 106, 0.5)',
												}}
												onClick={async () => {
													navigate(
														PAGES.POWERLINES +
														'/' +
															id +
															(isDronodat ? `?client=${client}` : ''),
													);
												}}
											>
												{t('Powerlines')}
											</Button>
											<Button
												// minW = "100%"
												w="100%"
												// ml="10%"
												
												mt="1rem"
												borderRadius="0.25rem"
												bg="transparent"
												border="1px solid"
												size="sm"
												borderColor={DarkGreen.tableBorderColor}
												color="rba(255,255,255,1)"//{DarkGreen.tableHighlightedColor}
												_hover={{
													color: 'white',
													background: 'rgba(0, 196, 106, 0.5)',
												}}
												onClick={async () => {
													const { data } = await api.post(
														(isDronodat ? getClientAPIUrl(client) : '') +
															API.post.GENERATE_REPORT(id),
															);
													window.open(data.file);
												}}
											>
												{t('GenerateReport')}
											</Button>
									</Box>
							</SimpleGrid>

						</VStack>
						{/* <Box w="100%" > */}




						<VStack //Missions Table
							// mb="4rem" 
							order = {{lg:"3",base:"1"}}
							width={{lg:'100%', base:'100%'}}
						>
							<HStack justify="space-between" w="100%"
							>
								<Formik
									initialValues={{}}
									onSubmit={(e) => {
										setMissionsPagination({
											...missionsPagination,
											offset: 0,
											currentPage: 1,
										});
										setMissionsSearch(missionSearchElement.current?.value);
									}}
								>
									<Form
										style={{
											width: '100%',
											display: 'flex',
											gap: '1rem',
											flexDirection: isLargeScreen ? 'row' : 'column',

										}}
									>
										<FormControl display="flex" gap="1rem" alignItems="center">
										<HStack
											ml="0"
											_hover={{
												"& > button > div": {
													bg: DarkGreen.iconColor
												}
											}}
											> 

											<InputGroup w="auto" maxW="20rem">
												<Input
													w="420px"
													bg="transparent"
													borderColor={DarkGreen.tableBorderColor}
													color="white"
													borderRadius="0.25rem"
													placeholder={t('Search')}
													_placeholder={{
														color: "white"
													}}
													textColor="white"
													type="text"
													size="md"
													onChange={(e) => {
														if (e.target.value.length === 0) {
															setMissionsSearch('');
														}
													}}
													ref={missionSearchElement}
												/>
											</InputGroup>
											
											<Center
												h="0.6rem"
												w="4rem"
												pos="relative"
												mt="0.2rem"
												ml="-5rem"
												padding={"1rem"}
												cursor="pointer"
												as="button"
												type="submit"
											>
												<Box
													clipPath="polygon(10% 0, 100% 0, 90% 100%, 0 100%);"
													h="0.6rem"
													w="4rem"
													pos="absolute"
													bg={DarkGreen.tableBorderColor}
												></Box>
												<Icon
													pos="absolute"
													w="2rem"
													h="2rem"
													color="white"
													
													as={FiSearch}
												/>
											</Center> 
										</HStack> 

										</FormControl>
										<HStack
											w="100%"
											justify={{ lg: 'flex-end', base: 'flex-start' }}
											gap="1rem"
											spacing={0}
										>
											<Select
												name="Status"
												bg="transparent"
												borderColor={DarkGreen.tableBorderColor}
												color="white"
												borderRadius="0.25rem"
												textColor="white"
												onChange={(type) => {
													if (type === '-') type = '';
													setMissionsType(type);
												}}
												placeholder={t('missions.FilterByMissionType')}
												whiteSpace="nowrap"
												w="14rem"
											>
												<Option value="-">{t('All')}</Option>
												<Option value="1">{t('projects.FlightMissions')}</Option>
												<Option value="2">
													{t('projects.TrimmingMissions')}
												</Option>
											</Select>
											<Icon flexDir={"row"}
												onClick={() => {
													navigate(
														PAGES.NEW_MISSION +
															`?project=${id}` +
															(isDronodat ? `&client=${client}` : ''),
													);
												}}
												as={MdOutlineAdd}
												cursor="pointer"
												w="48px"
												h="48px"
												title="New Mission"
												color={DarkGreen.iconColor}
											/>
										</HStack>
									</Form>
								</Formik>
							</HStack>
							<Box w="100%" overflowX="auto" maxH="40rem" 
								minW={isLargeScreen ? '600px': isMidScreen? '300px':'400px'}>

								<CustomTable //Mission Table
								// minW="100%"
								min_width={isLargeScreen ? '600px': isMidScreen? '300px':'400px'}
									columns={isLargeScreen ? [
										{
											title: t('missions.Id'),
											accessor: 'id',
											sortable: true,
										},
										{
											title: t('Name'),
											accessor: 'name',
											sortable: true,
											// tdMaxWidth:'{lg:"300px",md:"100px"}'
										},
										{
											title: t('projects.PunchIn'),
											accessor: 'started_on',
											sortable: true,
										},
										{
											title: t('projects.PunchOut'),
											accessor: 'finished_on',
											sortable: true,
										},
										{
											title: '',
											accessor: 'actions',
										},
									]:[
										{
											title: t('ID'),
											accessor: 'id',
											sortable: true,
											paddingY:"0"
										},
										{
											title: t('Name'),
											accessor: 'name',
											sortable: true,
											tdMaxWidth:"200px"
										},

										{
											title: '',
											accessor: 'actions',
											paddingY:"1rem"
										},
									]
								}
									data={missions}
									isLoading={missionsLoading}
								/>
							</Box>
							{missionsPagination.total > 0 && (
								<Paginator
									isLoading={missionsLoading}
									total={missionsPagination.total}
									onPageChange={(number) => {
										setMissionsPagination({
											...missionsPagination,
											currentPage: number,
											offset: (number - 1) * missionsPagination.limit,
										});
									}}
									onPerPageChange={(number) => {
										setMissionsPagination({
											...missionsPagination,
											limit: number,
											offset: 0,
											currentPage: 1,
										});
									}}
									currentPage={missionsPagination.currentPage}
								/>
							)}
						</VStack>
						{/* </Box> */}
					</HStack>
					<VStack // Detail Box
					w={{lg:"100%",base:"80%"}}
					ml={{lg:"0",base:"1rem"}}
					// display={{lg:"flex",base:"none"}}
					px="2rem"
					rowGap="2rem"
					flexDir={{lg:"row",base:"column"}}					// templateColumns="3f 1f 1f"
					// w="100%"
					alignItems="center"
					// justifyContent="center"
					minW={{lg:"800px", base:"100px"}}
						>
					<Box //Status circles
						h={{lg:"2rem",base:"2rem"}}
						// w="80%"
						alignContent="flex-start"
					>
						{/* <Text ml={{lg:"2rem",base:"2rem"}} mt="0.5rem"  mb="2rem" fontSize="20px" fontWeight={500} flexDir={isMidScreen? "column":"column"}>
							{t('Status')}
						</Text> */}
					</Box>
					
						<Box
							px="2rem"
							rowGap="2rem"
							display="flex"
							mt="1rem" 
							mx={{base:"2rem"}}
							w="100%"
							minW={{lg:"250px", base:"120px"}}		
						>
							<Circle
								mx={{base:"2rem"}}
								fontSize="22px"
								size="64px"
								border="3px solid #03A66D"
							>
								{project?.summary.missions[3]}
							</Circle>
							<Text mt="1rem"fontSize="18px">
								{t('missions.Completed')} 
							</Text>
						</Box>

						<Box 
							px="2rem"
							rowGap="2rem"
							display="flex"
							mt="1rem" 
							w="100%"
							minW={{lg:"250px", base:"150px"}}
							maxW={{lg:"400px", base:"400px"}}
							order="2"
							>
								<Circle
									mx={{base:"2rem"}}
									fontSize="22px"
									size="64px"
									border="3px solid #F2C018"
									>
									{project?.summary.missions[2]}
								</Circle>
								<Text 
									mt="1rem"
									fontSize="18px">{t('missions.Active')}
								</Text>
						</Box>
						<Box
							px="2rem"
							rowGap="2rem"
							display="flex"
							mt="1rem" 
							mx={{base:"2rem"}}
							w="100%"
							minW={{lg:"250px", base:"120px"}}
							>
							<Circle
								mx={{base:"2rem"}}
								fontSize="22px"
								size="64px"
								border="3px solid #CF304A"
								>
								{project?.summary.missions[1]}
							</Circle>
							<Text mt="1rem" fontSize="18px">
								{t('missions.Pending')}
							</Text>
						</Box>
					</VStack>
					<HStack
						flexDir={{ lg: 'row', base: 'column' }}
						py="2rem"
						w="100%"
						gap="5rem"
						justify="space-evenly"
						>
					</HStack>
					<HStack //Reports Tabel
						flexDir={{ xl: 'row', lg: 'column', base: 'column' }}
						align="center"
						justify={'space-between'}
						gap="4rem"
						mb="4rem"
					>
							{/* <ReactApexChart // Piechart
							display={{xl: "block", lg:"none", base:"none"}}
							width="100%"
								options={{
									plotOptions: {
										radialBar: {
											hollow: {
												size: `70%`,
											},
											dataLabels: {
												name: {
													color: '#fff',
												},
												value: {
													color: '#fff',
													fontSize: '30px',
												},
											},
										},
									},
									labels: [t('projects.Progress')],
									colors: ['#03A66D'],
								}}
								series={[+progress?.toFixed(2)]}
								type="radialBar"
								height={350}
							/> */}
					<VStack
					mb="4rem"
					width="100%"
					>
						<HStack justify="space-between" w="100%">
							<Formik
								initialValues={{}}
								onSubmit={(e) => {
									setReportsPagination({
										...reportsPagination,
										offset: 0,
										currentPage: 1,
									});
									setReportsSearch(reportSearchElement.current?.value);
								}}
							>
								<Form
									style={{
										width: '100%',
										display: 'flex',
										gap: '1rem',
										flexDirection: flexDirection === 'row' ? 'row' : 'column',
									}}
								>
									<FormControl display="flex" gap="1rem" alignItems="center">
									<HStack
										ml="0"
										_hover={{
											"& > button > div": {
												bg: DarkGreen.iconColor
											}
										}}>
										<InputGroup w="auto" maxW="20rem">
											<Input
												w="420px"
												bg="transparent"
												borderColor={DarkGreen.tableBorderColor}
												color="white"
												borderRadius="0.25rem"
												placeholder={t('Search')}
												_placeholder={{
													color: "white"
												}}
												textColor="white"
												onChange={(e) => {
													if (e.target.value.length === 0) {
														setReportsSearch('');
													}
												}}
												ref={reportSearchElement}
											/>
										</InputGroup>
										<Center
											h="0.6rem"
											w="4rem"
											pos="relative"
											mt="0.2rem"
											ml="-5rem"
											padding={"1rem"}
											cursor="pointer"
											as="button"
											type="submit"
										>
											<Box
												clipPath="polygon(10% 0, 100% 0, 90% 100%, 0 100%);"
												h="0.6rem"
												w="4rem"
												pos="absolute"
												bg={DarkGreen.tableBorderColor}
											></Box>
											<Icon
												pos="absolute"
												w="2rem"
												h="2rem"
												color="white"
												
												as={FiSearch}
											/>
										</Center> 
									</HStack>
									</FormControl>
									<HStack
										w="100%"
										justify={{ lg: 'flex-end', base: 'flex-start' }}
										gap="1rem"
										spacing={0}
									>
										<Select
											name="Status"
											bg="transparent"
											borderColor={DarkGreen.tableBorderColor}
											color="white"
											borderRadius="0.25rem"
											textColor="white"
											onChange={(type) => {
												if (type === '-') type = '';
												setReporstType(type);
											}}
											placeholder={t('reports.FilterByReportType')}
											whiteSpace="nowrap"
											w="14rem"
										>
											<Option value="-">{t('All')}</Option>
											<Option value="1">KML</Option>
											<Option value="2">PDF</Option>
											<Option value="3">XLSX</Option>
											<Option value="4">LAS</Option>
										</Select>
										<Icon
											onClick={() => {
												onOpen();
											}}
											as={MdOutlineAdd}
											cursor="pointer"
											w="48px"
											h="48px"
											title="New Report"
											color={DarkGreen.iconColor}
										/>
									</HStack>
								</Form>
							</Formik>
						</HStack>
						<Box w="100%" overflowX="auto" maxH="40rem" 
						minW={isLargeScreen ? '1000px': isMidScreen? '400px':'400px'}>
							<CustomTable //Reports Tabel
								minW="100%"
								min_width={isLargeScreen ? '1000px':'400px'}
								columns={isLargeScreen ?
								[
									// {
									// 	title: t('Reports'),
									// 	accessor: 'image',
									// },
									{
										title: t('reports.Id'),
										accessor: 'id',
										sortable: true,
									},
									{
										title: t('Type'),
										accessor: 'type',
										sortable: true,
									},
									{
										title: t('Name'),
										accessor: 'title',
										sortable: true,
										// tdMaxWidth: '500px',

									},
									{
										title: t('UploadedOn'),
										accessor: 'uploaded_on',
										sortable: true,
									},
									{
										title: '',
										accessor: 'actions',
									},
								]:[
									// {
									// 	title: t('ID'),
									// 	accessor: 'id',
									// 	sortable: true,
									// },
									{
										title: t('Type'),
										accessor: 'type',
										sortable: true,
									},
									{
										title: t('Name'),
										accessor: 'title',
										sortable: true,
										tdMaxWidth: '200px',
									},
									// {
									// 	title: t('UploadedOn'),
									// 	accessor: 'uploaded_on',
									// 	sortable: true,
									// },
									{
										title: '',
										accessor: 'actions',
									},
								]
							
							
							}
								data={reports}
								isLoading={reportsLoading}
							/>
						</Box>
						{reportsPagination.total > 0 && (
							<Paginator
								isLoading={reportsLoading}
								total={reportsPagination.total}
								onPageChange={(number) => {
									setReportsPagination({
										...reportsPagination,
										currentPage: number,
										offset: (number - 1) * reportsPagination.limit,
									});
								}}
								onPerPageChange={(number) => {
									setReportsPagination({
										...reportsPagination,
										limit: number,
										offset: 0,
										currentPage: 1,
									});
								}}
								currentPage={reportsPagination.currentPage}
							/>
						)}
					</VStack>
					</HStack>
				</Box>
			)}
		</>
	);
}

export default ProjectDetails;
