import Wrapper from '../shared/Wrapper';
import {
	Box,
	Container,
	Flex,
	Text,
	Image,
	FormControl,
	InputRightElement,
	Input,
	FormErrorMessage,
	Button,
	Link,
	VStack,
	HStack,
	useMediaQuery,
	useDisclosure,
	InputGroup,
	InputLeftElement,
	Icon,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useStore } from '../../store';
import { Field, Form, Formik } from 'formik';
import { PAGES } from '../../shared/routes';
import { login } from '../../models/auth';
import { useRef, useEffect, useState } from 'react';
import { bg, logo,logo2 } from '../../assets/images';
import ForgotPassword from '../ForgotPassword';
import { MdEmail } from 'react-icons/md';
import { FiKey } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Actions,DarkGreen } from '../../shared/enums';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';

function Login() {
	const [showPassword, setShowPassword] = useState(false);
	const { state, dispatch } = useStore();
	const container = useRef(null);
	const navigate = useNavigate();
	const [height, setHeight] = useState('100vh');
	const [notDesktop] = useMediaQuery('(max-width: 48em)');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { t } = useTranslation();

	useEffect(() => {
		window.addEventListener('resize', () => {
			setHeight(`${container.current?.offsetHeight}px`);
		});
		setHeight(`${container.current?.offsetHeight}px`);
		if (state.isAuthenticated) navigate(PAGES.HOME);

		return () => {
			window.removeEventListener('resize', () => {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				setHeight(`${container.current?.offsetHeight}px`);
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.isAuthenticated]);

	return (state.isAuthLoading || state.isAuthenticated) ? (
		<></>
	) : (
		<Wrapper backgroundSize={'auto'} backgroundImage={bg}>
			<Flex
				data-aos="fade-left"
				pos="relative"
				ref={container}
				h="100%"
				overflowY="auto"
			>
				{!notDesktop && (
					<>
						<Box w="40vw" h="100%" />
						<Box
							pos="absolute"
							right="60%"
							borderTop={`${height} solid `}
							borderTopColor={DarkGreen.backgroundColor}
							borderLeft="10vw solid transparent"
						/>
					</>
				)}
				<Box
					w={{ base: '100vw', md: '60vw' }}
					h="100%"
					bg="overlay"
					overflowY="auto"
				>
					<Container p={{ base: '2rem', md: '4rem' }}>
						<HStack  mt={{md:"1rem",base:"5rem"}} mr ={{md:"1rem",base:"4rem"}}>
							<Image h={{md:"7rem",base:"7rem"}} src={logo2} />
							<Image h={{md:"8rem",base:"8rem"}} src={logo} />
						</HStack>
						<Text
							ml={{ base: '1rem', md: '2rem' }}
							mt="4rem"
							fontSize="2.5em"
							color={DarkGreen.iconColor}
						>
							{t('login.Title')}
						</Text>
						<Text
							ml={{ base: '1rem', md: '2rem' }}
							fontWeight="300"
							fontSize="1.25rem"
							color="white"
						>
							{t('login.Description')}
						</Text>
						<Box mt="5rem" marginInline={{ base: '1rem', md: '2rem' }}>
							<Formik
								initialValues={{ email: '', password: '' }}
								validationSchema={Yup.object().shape({
									email: Yup.string()
										.email(t('InvalidEmail'))
										.required(t('Required')),
									password: Yup.string().required(t('Required')).min(8),
								})}
								onSubmit={async (values, actions) => {
									try {
										const res = await login(values.email.toLowerCase(), values.password);
										const tokenPayload: any = jwt_decode(res.refresh_token);
										dispatch({
											type: Actions.SET_AUTH_USER,
											payload: {
												id: tokenPayload.user_id,
												groups: tokenPayload.groups,
											},
										});
										navigate(PAGES.HOME);
									} catch (e) {
										console.log(e.message);
										actions.setErrors({
											email: t('InvalidEmailPassword'),
											password: t('InvalidEmailPassword'),
										});
									}

									actions.setSubmitting(false);
								}}
							>
								{(props) => (
									<Form>
										<Field name="email" 
											bg={"transparent"}
											>
											{({ field, form }) => (
												<FormControl
											bg={"transparent"}

													isInvalid={form.errors.email && form.touched.email}
												>
													<InputGroup 
											bg={"transparent"}
											>
														<InputLeftElement
															pointerEvents="none"
															pl="1rem"
															children={
																<Icon
																	w="1.5rem"
																	h="1.5rem"
																	color={DarkGreen.iconColor}
																	as={MdEmail}
																/>
															}
														/>
														<Input
															pl="4rem"
															color="#73BCB6"
															border="1px solid"
															borderColor={DarkGreen.iconColor}
															borderRadius="0.25rem"
															{...field}
															bg="transparent !important"
															id="email"
															placeholder={t('Email')}
														/>
													</InputGroup>
													<FormErrorMessage>
														{form.errors.email}
													</FormErrorMessage>
												</FormControl>
											)}
										</Field>
										<Field name="password"
												bgColor={"transparent"}
												>
											{({ field, form }) => (
												<FormControl
													isInvalid={
														form.errors.password && form.touched.password
													}
												>
													<InputGroup
											bg={"transparent"}
											>
														<InputLeftElement
															pointerEvents="none"
															pl="1rem"
															mt="2rem"
															children={
																<Icon
																	w="1.5rem"
																	h="1.5rem"
																	color={DarkGreen.iconColor}
																	as={FiKey}
																/>
															}
														/>
														<InputRightElement
															cursor="pointer"
															onClick={() => setShowPassword(!showPassword)}
															mt="2rem"
															children={
																<Icon
																	w="1.5rem"
																	h="1.5rem"
																	color={DarkGreen.iconColor}
																	as={
																		showPassword
																			? AiFillEyeInvisible
																			: AiFillEye
																	}
																/>
															}
														/>
														<Input
															bg="transparent"
															color="#73BCB6"
															border="1px solid"
															borderColor={DarkGreen.iconColor}
															borderRadius="0.25rem"
															{...field}
															id="password"
															type={showPassword ? 'text' : 'password'}
															placeholder={t('Password')}
															mt="2rem"
															pl="4rem"
														/>
													</InputGroup>

													<FormErrorMessage>
														{form.errors.password}
													</FormErrorMessage>
												</FormControl>
											)}
										</Field>
										<VStack spacing="2rem">
											<ForgotPassword isOpen={isOpen} onClose={onClose} />
											<Link
												color={DarkGreen.layersBoxBorderTextColorMuted}
												mt="1rem"
												fontSize="1rem"
												fontWeight="300"
												alignSelf="flex-start"
												onClick={onOpen}
											>
												{t('login.ForgetPassword')}
											</Link>
											<Button
												background="transparent"
												border="1px solid"
												borderColor={DarkGreen.iconColor}
												isLoading={props.isSubmitting}
												type="submit"
												borderRadius={'0.25rem'}
												w="full"
												maxW="310px"
												color="white"
												alignSelf="flex-end"
												_hover={{
													background:DarkGreen.ButtonHighlightColor,
												}}
											>
												Submit
											</Button>
										</VStack>
									</Form>
								)}
							</Formik>
						</Box>
					</Container>
				</Box>
			</Flex>
		</Wrapper>
	);
}

export default Login;
