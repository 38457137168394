import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { logo } from '../../assets/images';
import { PAGES } from '../../shared/routes';
import Wrapper from '../shared/Wrapper';
import { Button, Container, Image, useToast } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { resetPasswordConfirm } from '../../models/auth';
import * as Yup from 'yup';
import { FiKey } from 'react-icons/fi';
import Title from '../shared/Title';
import Subtitle from '../shared/Subtitle';
import CustomInput from '../shared/Input';

function ResetPassword() {
	const navigate = useNavigate();
	const toast = useToast();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');
	const uid = searchParams.get('uid');

	useEffect(() => {
		if (!token || !uid) {
			navigate(PAGES.NOT_FOUND);
		}
	}, []);

	return (
		<Wrapper showOverlay>
			<Container data-aos="fade-down" p={{ lg: '4rem', base: '1rem' }}>
				<Image
					cursor="pointer"
					onClick={() => {
						navigate(PAGES.LOGIN);
					}}
					src={logo}
					w="30rem"
					mb={{ md: '4rem', base: '2rem' }}
				/>
				<Title title="Reset Password" px={{ md: '4rem', base: '2rem' }} />
				<Subtitle
					subtitle="Strong password required. Do not include common words or names."
					px={{ md: '4rem', base: '2rem' }}
				/>
				<Formik
					initialValues={{ password: '', passwordConfirm: '' }}
					validationSchema={Yup.object().shape({
						password: Yup.string()
							.min(6, 'Password must be at least 6 characters')
							.required('Password is required'),
						passwordConfirm: Yup.string()
							.oneOf([Yup.ref('password'), null], 'Passwords must match')
							.required('Password confirmation is required'),
					})}
					onSubmit={async (values, actions) => {
						try {
							await resetPasswordConfirm(
								token,
								uid,
								values.password,
								values.passwordConfirm,
							);
							toast({
								title: 'Password Changed.',
								description: 'You will be redirected to the login page.',
								status: 'success',
								duration: 3000,
								isClosable: true,
								position: 'bottom-right',
							});
							setTimeout(() => {
								navigate(PAGES.LOGIN);
							}, 3000);
						} catch (e) {
							const errorKey = Object.keys(e.response.data);
							const errorValue = Object.values(e.response.data);
							toast({
								title: 'Something went wrong.',
								description: `${errorKey}: ${errorValue}`,
								status: 'error',
								duration: 3000,
								isClosable: true,
								position: 'bottom-right',
							});
						}

						actions.setSubmitting(false);
					}}
				>
					{(props) => (
						<Form>
							<Field name="password">
								{({ field, form }) => (
									<CustomInput
										label={'New password'}
										type={'password'}
										placeholder={'********'}
										icon={FiKey}
										error={form.errors.password}
										touched={form.touched.password}
										field={field}
									/>
								)}
							</Field>
							<Field name="passwordConfirm">
								{({ field, form }) => (
									<CustomInput
										label={'Confirm new password'}
										type={'password'}
										placeholder={'********'}
										icon={FiKey}
										error={form.errors.passwordConfirm}
										touched={form.touched.passwordConfirm}
										field={field}
									/>
								)}
							</Field>
							<Button
								mx={{ md: '6rem', base: '1rem' }}
								background="transparent linear-gradient(180deg, #00965B 0%, #065688 100%) 0% 0% no-repeat padding-box;"
								_hover={{
									background:
										'transparent linear-gradient(180deg, #065688 0%, #00965B 100%) 0% 0% no-repeat padding-box;',
								}}
								isLoading={props.isSubmitting}
								type="submit"
								borderRadius={'0.25rem'}
								color="white"
							>
								Change Password
							</Button>
						</Form>
					)}
				</Formik>
			</Container>
		</Wrapper>
	);
}

export default ResetPassword;
