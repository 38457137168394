import {
	Button,
	HStack,
	Icon,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import api from '../../shared/api';
import { API, PAGES } from '../../shared/routes';
import CustomInput from '../shared/Input';
import Title from '../shared/Title';
import * as Yup from 'yup';
import { useFetch } from '../../shared/hooks';
import { generateQueryString, getClientAPIUrl } from '../../shared/helpers';
import { useEffect, useState } from 'react';
import Dropdown from '../shared/Dropdown';
import { DarkGreen, Roles, Status } from '../../shared/enums';
import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';
import ConfirmDelete from '../shared/ConfirmDelete';
import { MdArrowBackIosNew } from 'react-icons/md';

function NewUpdateProject() {
	const [users, setUsers] = useState([]);
	// const [clients, setClients] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isAuthorized, state } = useStore();
	const [searchParams] = useSearchParams();
	const { id } = useParams();
	const navigate = useNavigate();
	const isEditMode = id !== 'new' && id !== undefined;
	const toast = useToast();
	const { t } = useTranslation();
	const client = searchParams.get('client');
	const isDronodat = isAuthorized([Roles.ADMIN]) && client;

	useEffect(() => {
		if (!isAuthorized([Roles.CLIENT_ADMIN, Roles.ADMIN]) && state.user.groups)
			navigate(PAGES.NOT_AUTHORIZED);
		if (isAuthorized([Roles.ADMIN]) && state.user.groups && !client)
			navigate(PAGES.NOT_FOUND);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.user]);

	const { data: project, isError }: any = useFetch(
		(isDronodat ? getClientAPIUrl(client) : '') + API.get.PROJECTS + id + '/',
		!isEditMode || !state.user.groups,
	);

	useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.ALL_USERS,
			{
				limit: 1000,
			},
		),
		null,
		({ data }: any) => {
			setUsers(data.results);
		},
	);

	// useFetch(
	// 	generateQueryString(API.get.CLIENTS, {
	// 		limit: 1000,
	// 	}),
	// 	!isAuthorized([Roles.ADMIN]),
	// 	({ data }: any) => {
	// 		setClients(data.results);
	// 	},
	// );

	if (isError && isEditMode) navigate(PAGES.NOT_FOUND);

	const initialValues = {
		name: '',
		address: '',
		assignees: [],
		contact_person: '',
		// client: '',
		...(isEditMode &&
			project && {
				// ...project,
				started_on: project.started_on ? project.started_on.split('T')[0] : '',
				finished_on: project.finished_on
					? project.finished_on.split('T')[0]
					: '',
				status: { value: project.status, label: t(Status[project.status]) },
				name: project.name,
				address: project.address,
				contact_person: users
					? {
							value: users?.find(
								(user) => user?.id === project?.contact_person?.id,
							)?.id,
							label: users?.find(
								(user) => user?.id === project?.contact_person?.id,
							)?.email,
					  }
					: '',
				assignees: users
					? project.assignees.map((assignee) => {
							return {
								value: assignee.id,
								label: users.find((user) => user.id === assignee.id)?.email,
							};
					  })
					: '',
			}),
	};

	return (
		<>
			<HStack mx={{ md: '6rem', base: '1rem' }}>
				<Icon //Titel
					cursor="pointer"
					onClick={() =>
						navigate(
							PAGES.PROJECTS + '/' + (isDronodat ? `?client=${client}` : ''),
						)
					}
					w="2rem"
					h="2rem"
					as={MdArrowBackIosNew}
				/>
				<Title
					title={
						isEditMode
							? `${t('projects.edit.Title')} ${id}`
							: t('projects.new.Title')
					}
					px={'0 !important'}
					mb="3rem"
					data-aos="fade-down"
				/>
			</HStack>
			<HStack ml = {{lg: "4rem", base:"1rem"}} mt = "2rem" scale={"0.8"} >
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={Yup.object({
					name: Yup.string().required(),
					address: Yup.string().optional(),
					assignees: Yup.array().optional(),
					contact_person: Yup.object().optional(),
					status: Yup.object().optional(),
					started_on: Yup.date().optional(),
					finished_on: Yup.date().optional(),
				})}
				onSubmit={async (values: any, actions) => {
					try {
						values = {
							...values,
							assignees: values.assignees.map((user) => user.value),
							contact_person: values.contact_person.value ?? null,
							status: isEditMode ? values.status.value : 1,
							started_on: values.started_on ? values.started_on : null,
							finished_on: values.finished_on ? values.finished_on : null,
						};
						if (isEditMode) {
							await api.put(
								`${isDronodat ? getClientAPIUrl(client) : ''}${
									API.put.PROJECTS
								}${id}/`,
								values,
							);
							toast({
								title: t('projects.edit.toast.Title'),
								description: t('projects.edit.toast.Description'),
								status: 'success',
								duration: 3000,
								isClosable: true,
								position: 'bottom-right',
							});
							navigate(
								PAGES.PROJECTS +
									'/' +
									id +
									(isDronodat ? `?client=${client}` : ''),
							);
						} else {
							const res = await api.post(
								`${isDronodat ? getClientAPIUrl(client) : ''}${
									API.post.PROJECTS
								}`,
								values,
							);
							toast({
								title: t('projects.new.toast.Title'),
								description: t('projects.new.toast.Description'),
								status: 'success',
								duration: 3000,
								isClosable: true,
								position: 'bottom-right',
							});
							navigate(
								PAGES.PROJECTS +
									'/' +
									res.data.id +
									(isDronodat ? `?client=${client}` : ''),
							);
						}
					} catch (e) {
						toast({
							title: t('Error'),
							description: t('TryAgain'),
							status: 'error',
							duration: 3000,
							isClosable: true,
							position: 'bottom-right',
						});
						const errors = e.response.data;
						actions.setErrors({
							name: errors.name?.pop(),
							address: errors.address?.pop(),
							assignees: errors.assignees?.pop(),
							contact_person: errors.contact_person?.pop(),
						});
					}
					actions.setSubmitting(false);
				}}
			>
				{(props) => (
					<Form data-aos="fade-down">
						<Field name="name">
							{({ field, form }) => (
								<CustomInput
									label={t('Name')}
									type={'text'}
									error={form.errors.name}
									touched={form.touched.name}
									field={field}
								/>
							)}
						</Field>
						<Field name="address">
							{({ field, form }) => (
								<CustomInput
									label={t('Address')}
									type={'text'}
									error={form.errors.address}
									touched={form.touched.address}
									field={field}
								/>
							)}
						</Field>
						<Field name="assignees">
							{({ field, form }) => (
								<Dropdown
									errors={form.errors.assignees}
									touched={form.touched.assignees}
									label={t('Assignees')}
									placeholder={t('SelectPerson')}
									options={users.map((user) => ({
										label: user.email,
										value: user.id,
									}))}
									onChange={(list) => {
										form.setFieldValue('assignees', list);
									}}
									isMulti
									values={field.value}
								/>
							)}
						</Field>
						<Field name="contact_person">
							{({ field, form }) => (
								<Dropdown
									errors={form.errors.contact_person}
									touched={form.touched.contact_person}
									label={t('ContactPerson')}
									placeholder={t('SelectPerson')}
									options={users.map((user) => ({
										label: user.email,
										value: user.id,
									}))}
									onChange={(value) => {
										form.setFieldValue('contact_person', value);
									}}
									values={field.value}
								/>
							)}
						</Field>
						<Field name="started_on">
							{({ field, form }) => (
								<CustomInput
									label={t('projects.PunchIn')}
									type={'date'}
									error={form.errors.started_on}
									touched={form.touched.started_on}
									field={field}
								/>
							)}
						</Field>
						<Field name="finished_on">
							{({ field, form }) => (
								<CustomInput
									label={t('projects.PunchOut')}
									type={'date'}
									error={form.errors.finished_on}
									touched={form.touched.finished_on}
									field={field}
								/>
							)}
						</Field>
						{isEditMode && (
							<Field name="status">
								{({ field, form }) => (
									<Dropdown
										errors={form.errors.status}
										touched={form.touched.status}
										label={t('Status')}
										placeholder={''}
										options={Object.keys(Status).map((key) => ({
											value: key,
											label: t(Status[key]),
										}))}
										onChange={(value) => {
											form.setFieldValue('status', value);
										}}
										values={field.value}
									/>
								)}
							</Field>
						)}
						<HStack
							columnGap="10"
							rowGap="5"
							flexWrap="wrap"
							mx={{ md: '6rem', base: '1rem' }}
							pb="2rem"
							spacing={0}
						>
							{isEditMode ? (
								<>
									<Button
										background="transparent"
										border={DarkGreen.ButtonBorderColor}
										_hover={{
											background:DarkGreen.ButtonHighlightColor
										}}
										isLoading={props.isSubmitting}
										type="submit"
										borderRadius={'0.25rem'}
										color="white"
										minW={{ lg: '210px', base: '130px' }}
									>
										{t('Update')}
									</Button>
									<Button
										background="transparent"
										borderRadius={'0.25rem'}
										borderWidth="2px"
										borderColor={DarkGreen.ButtonBorderColor}
										color="white"
										minW={{ lg: '210px', base: '130px' }}
										_hover={{
											color: 'white',
											borderColor: 'red',
											background: 'white',
										}}
										onClick={() => {
											navigate(-1);
										}}
									>
										{t('Cancel')}
									</Button>
									<ConfirmDelete
										onClose={onClose}
										isOpen={isOpen}
										onConfirm={async () => {
											try {
												await api.delete(
													(isDronodat ? getClientAPIUrl(client) : '') +
														API.delete.PROJECTS +
														id +
														'/',
												);
												toast({
													title: t('projects.delete.toast.Title'),
													description: t('projects.delete.toast.Description'),
													status: 'success',
													duration: 3000,
													isClosable: true,
													position: 'bottom-right',
												});
												navigate(
													PAGES.PROJECTS +
														(isDronodat ? `?client=${client}` : ''),
												);
											} catch {
												toast({
													title: t('Error'),
													description: t('TryAgain'),
													status: 'error',
													duration: 3000,
													isClosable: true,
													position: 'bottom-right',
												});
											}
										}}
									/>
									<Button
										background="transparent"
										borderRadius={'0.25rem'}
										borderWidth="2px"
										borderColor={DarkGreen.ButtonBorderColor}
										color="white"
										minW={{ lg: '210px', base: '130px' }}
										_hover={{
											background: 'transparent',
											borderColor:'red'
										}}
										onClick={onOpen}
									>
										{t('Delete')}
									</Button>
								</>
							) : (
								<>
									<Button
										background="transparent"
										border="1px solid"
										borderColor={DarkGreen.ButtonBorderColor}
										_hover={{
											background:
												DarkGreen.iconColor,
										}}
										isLoading={props.isSubmitting}
										type="submit"
										borderRadius={'0.25rem'}
										color="white"
										minW={{ lg: '210px', base: '130px' }}
									>
										{t('Create')}
									</Button>
									<Button
										background="transparent"
										borderRadius={'0.25rem'}
										borderWidth="1px"
										borderColor={DarkGreen.ButtonBorderColor}
										color="white"
										minW={{ lg: '210px', base: '130px' }}
										onClick={() => {
											navigate(-1);
										}}
										_hover={{
											color: 'black',
											background: DarkGreen.iconColor,
										}}
									>
										{t('Cancel')}
									</Button>
								</>
							)}
						</HStack>
					</Form>
				)}
			</Formik>
			</HStack>
		</>
	);
}

export default NewUpdateProject;
