import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import React from 'react';
import Select from 'react-select';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import {DarkGreen} from '../../../shared/enums';
// import 'react-multi-email/style.css';
import './styles.css';

function MultiEmail({
	isMulti = false,
	errors = false,
	touched = false,
	label = '',
	placeholder,
	onChange,
	options,
	values = undefined,
	setValues,
}) {
	return (
		
		<FormControl
			isInvalid={errors && touched}
			px={{ md: '2rem', base: '1rem' }}
			mb="2rem"
			
		>
			{label && (
				<FormLabel
					mb="1rem"
					as="legend"
					fontWeight="bold"
					fontSize="18px"
					color="white"
					borderRadius= '0.25rem'
				>
					{label}
				</FormLabel>
			)}
			<ReactMultiEmail 
			style={{
				background: 'transparent',
				bordercolor:DarkGreen.inputBorderColor,
				color:DarkGreen.inputTextColor,
				borderRadius: '0.25rem',
				input: { background: 'transparent' },
			}}

				
				emails={values}
				validateEmail={(email) => {
					return isEmail(email); // return boolean
				}}
				onChange={(emails: string[]) => {
					setValues(emails);
				}}
				getLabel={(
					email: string,
					index: number,
					removeEmail: (index: number) => void,
				) => {
					return (
						<div data-tag key={index}>
							{email}
							<span data-tag-handle onClick={() => removeEmail(index)}>
								×
							</span>
						</div>
					);
				}}
			/>
			<FormErrorMessage>{errors}</FormErrorMessage>
		</FormControl>
	);
}

export default MultiEmail;
