import {
	Box,
	Text,
	HStack,
	Heading,
	Icon,
	Flex,
	SimpleGrid,
	Tag,
	Button,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MissionTypes, Roles, Status,DarkGreen } from '../../shared/enums';
import {
	getClientAPIUrl,
	getDate,
	getRemainingDays,
} from '../../shared/helpers';
import { useFetch } from '../../shared/hooks';
import { API, PAGES } from '../../shared/routes';
import api from '../../shared/api';
import Loader from '../Loader';
import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';
import { MdArrowBackIosNew } from 'react-icons/md';
import { BsCheck, BsCheckAll } from 'react-icons/bs';
import Map from '../Maps/Mission';
import ConfirmSendEmail from '../shared/ConfirmSendEmail';

function MissionDetails() {
	const { isAuthorized, state } = useStore();
	const [searchParams] = useSearchParams();
	const { id } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const statusColors = ['2E4765', '#CF304A', '#F2C018', '#03A66D'];
	const client = searchParams.get('client');
	const toast = useToast();
	const [remainingDays, setRemainingDays] = useState(0);
	const isDronodat =
		!isAuthorized([Roles.CLIENT_EMPLOYEE, Roles.CLIENT_ADMIN]) && client;

	useEffect(() => {
		if (
			!isAuthorized([Roles.CLIENT_EMPLOYEE, Roles.CLIENT_ADMIN]) &&
			state.user.groups &&
			!client
		)
			navigate(PAGES.NOT_AUTHORIZED);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.user]);

	const { data: mission, missionLoading }: any = useFetch(
		(isDronodat ? getClientAPIUrl(client) : '') + API.get.MISSIONS + id + '/',
		false,
		({ data }: any) => {
			const days = getRemainingDays(data.started_on);
			days > 14 ? setRemainingDays(days - 14) : setRemainingDays(days);
		},
	);

	// const { data: annotations } = useFetch(
	// 	generateQueryString(
	// 		(isDronodat ? getClientAPIUrl(client) : '') + API.get.ANNOTATIONS,
	// 		{
	// 			mission: id,
	// 			limit: 100,
	// 		},
	// 	),
	// );

	return (
		<>
			{missionLoading ? (
				<Loader />
			) : (
				<Box 
					data-aos="fade-down" 
					// mx={{ md: '6rem', base: '1rem' }} 
					mb="2rem"
				>
				<HStack>
					<Icon
						cursor="pointer"
						onClick={() =>
							navigate(
								`${PAGES.PROJECTS}/${mission?.project}${
									isDronodat ? `?client=${client}` : ''
								}`,
							)
						}
						w="3rem"
						h="3rem"
						mr="0.5rem"
						my="0"
						ml = "4rem"
						// pt="0.4rem"
						top="0"
						color={DarkGreen.iconColor}
						as={MdArrowBackIosNew}
					/>
											<Heading
						px={{ md: '1rem', base: '1rem' }}
						ml={{md: '0rem' ,base: '0'}}
						fontSize="2.6875rem"
						color="whitesmoke"
						fontWeight="normal"
						>
						{t("Mission Info")}
						</Heading>
					</HStack>
					{/* <Box data-aos="fade-down"  mb="3rem"> */}
					<HStack flexDir={{ xl: 'row', base: 'column' }}  gap="1rem" pb="4rem" mx={{ md: '6rem', base: '1rem' }}>

										<ConfirmSendEmail
											isOpen={isOpen}
											onClose={onClose}
											onConfirm={async (send) => {
												try {
													await api.post(
														`${isDronodat ? getClientAPIUrl(client) : ''}${
															API.get.MISSIONS
														}${id}/send_email/`,
														{ data: {} },
													);

													toast({
														title: t('Email sent'),
														description: t(
															'Details have been sent to the owners',
														),
														status: 'success',
														duration: 3000,
														isClosable: true,
														position: 'bottom-right',
													});
												} catch {
													toast({
														title: t('Error'),
														description: t('TryAgain'),
														status: 'error',
														duration: 3000,
														isClosable: true,
														position: 'bottom-right',
													});
												}
												onClose();
											}}
										/>

								<SimpleGrid //Box Grid
									order={{xl:"1", base:"2"}}
									h="auto"
									rowGap="1rem"
									columns={{lg:2,base:2}}
									bgColor = "transparent"// {DarkGreen.tableBackgroundColor}
									p={"20px"}
									boxShadow="1px 2px 20px #000000"
									border="1px solid"
									borderColor= {DarkGreen.tableBorderColor}
									borderRadius= "18px"
									minW={{xl:"350px", base:"410px"}}
									// ml={{lg:"4rem",base:"1rem"}}
									mr="1rem"
									w="80%"
									>
									<Heading
										fontSize={isDronodat? "24":"24px"}
										color="whitesmoke"
										fontWeight="normal"
										mt="0"
										lineHeight="2rem"
										p="0"
									>
										{`${mission?.name} ${
											isDronodat
												? `(${client})`
												: ''
										}`}
									</Heading>
										
									{isAuthorized([Roles.ADMIN, Roles.CLIENT_ADMIN]) && (
										<Icon
											cursor="pointer"
											color={DarkGreen.iconColor}

											onClick={() => {
												navigate(
													PAGES.MISSIONS +
														'/' +
														id +
														'/edit' +
														(isDronodat ? `?client=${client}` : ''),
												);
											}}
											w="2rem"
											h="2rem"
											ml="80%"
											mt="1rem"
											as={FaEdit}
										/>
									)}
									<Text color={DarkGreen.layersBoxBorderTextColor} fontWeight="normal" fontSize="18px">
										{t('projects.Id')}
									</Text>
									<Text
										cursor="pointer"
										onClick={() => {
											navigate(
												PAGES.PROJECTS +
													'/' +
													mission?.project +
													(isDronodat ? `?client=${client}` : ''),
											);
										}}
										color={DarkGreen.layersBoxBorderTextColorMuted}
										fontSize="16px"
									>
										{mission?.project}
									</Text>
									<Text color={DarkGreen.layersBoxBorderTextColor} fontWeight="normal" fontSize="18px">
										{t('missions.MissionType')}
									</Text>
									<Text color={DarkGreen.layersBoxBorderTextColorMuted} fontSize="16px">
										{t(`missions.${MissionTypes[mission?.type]}`)}
									</Text>
									<Text color={DarkGreen.layersBoxBorderTextColor} fontWeight="normal" fontSize="18px">
										{t('Assignees')}
									</Text>
									<HStack spacing={0} flexWrap="wrap" gap="0.5rem">
										{mission?.assignees?.map((assignee, index) => {
											// eslint-disable-next-line no-lone-blocks
											{
												return (
													assignee.name && (
														<Tag
															key={index}
															bg="transparent"
															border="1px solid"
															borderColor={DarkGreen.layersBoxBorderTextColorMuted}
															color={DarkGreen.ButtonHighlightColor}
															p="0.4rem "
															fontSize="16px"
														>
															{assignee.name}
														</Tag>
													)
												);
											}
										})}
									</HStack>
									<Text color="white" fontWeight="normal" fontSize="18px">
										{t('projects.PunchIn')}
									</Text>
									<Text color={DarkGreen.layersBoxBorderTextColorMuted} fontSize="16px">
										{mission?.started_on ? getDate(mission.started_on) : '-'}
									</Text>
									<Text color="white" fontWeight="normal" fontSize="18px">
										{t('projects.PunchOut')}
									</Text>
									<Text color={DarkGreen.layersBoxBorderTextColorMuted} fontSize="16px">
										{mission?.finished_on ? getDate(mission.finished_on) : '-'}
									</Text>
									<Text color="white" fontWeight="normal" fontSize="18px" overflowX={"auto"}>
										{t('Owners')}
									</Text>
									<HStack spacing={0} flexWrap="wrap" gap="0.5rem"

														minW={"100%"}
														>
										{mission?.owners?.map((owner, index) => {
											// eslint-disable-next-line no-lone-blocks
											{
												return (
													owner && (
														<Tag
														bg="transparent"
														minW={"200px"}
														border="1px solid"
														borderColor={DarkGreen.layersBoxBorderTextColorMuted}
														color={DarkGreen.ButtonHighlightColor}
														p="0.4rem "
														fontSize="16px"
														>
															{owner}
														</Tag>
													)
												);
											}
										})}
									</HStack>
									<Text color="white" fontWeight="normal" fontSize="18px">
										{t('Status')}
									</Text>
									<Text
										color={
											mission?.status ? statusColors[mission.status] : '#2E4765'
										}
										fontSize="16px"
									>
										{t(Status[mission?.status])}
									</Text>
									<Button // Send Email
										w="50%"
										background={DarkGreen.ButtonBackgroundColor}
										border ="1px solid"
										borderColor={DarkGreen.ButtonBorderColor}
										_hover={{
											background: DarkGreen.ButtonHighlightColor,
											color:"white"
										}}
										borderRadius={'0.25rem'}
										color="white"
										minW={'120px'}
										onClick={onOpen}
									>
										{t('Send Email')}
									</Button>
									<Box ml={0} mt={1}>
										{mission?.is_notification_sent &&
										mission?.is_reminder_sent ? (
											<Flex flexDir={'row'}>
												<Icon as={BsCheckAll} w={6} h={6} color={DarkGreen.iconColor}/>
												<Text ml={1}>
													Notification and Reminder emails have been sent
												</Text>
											</Flex>
										) : mission?.is_reminder_sent ? (
											mission?.is_notification_sent && (
												<Flex flexDir={'row'}>
													<Icon as={BsCheck} w={6} h={6} color={DarkGreen.iconColor} />
													<Text ml={1}>
														Reminder email will be sent in{' '}
														{remainingDays > 0 ? remainingDays : 0} day/s
													</Text>
												</Flex>
											)
										) : (
											<Text>
												Notification email will be sent in{' '}
												{remainingDays > 0 ? remainingDays : 0} day/s
											</Text>
										)}
									</Box>
								</SimpleGrid>

								<Box //Map
									// mt="1.5rem !important"
									w="100%" 
									h="60vh"
									order={{xl:"2", base:"1"}}

									// mx="auto !important"  
									// mr ="3rem"
									// mt="1rem"
									boxShadow="0px 0px 10px #000000">
										{mission?.assignees && state.user && (
											<Map
												isDronodat={isDronodat}
												projectId={mission?.project}
												missionId={id}
												isAssigned={
													mission?.assignees.find((a) => a.id === state.user.id)
														? true
														: false
												}
											/>
										)}
								</Box>

						{/* </Flex> */}
					</HStack>
				</Box>
			)}
		</>
	);
}

export default MissionDetails;
