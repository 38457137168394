import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Checkbox,
	HStack,
	Icon,
	Input,
	Radio,
	RadioGroup,
	Stack,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { useEffect, useRef, useState } from 'react';
// @ts-ignore
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useFetch } from '../../shared/hooks';
import { API } from '../../shared/routes';
import { generateQueryString, getClientAPIUrl } from '../../shared/helpers';
import {
	MapPriorityColor,
	Roles,
	DarkGreen,
	DefaultFeaturesColors,
} from '../../shared/enums';
import {
	averageGeolocation,
	getBounds,
	getBoundsZoomLevel,
} from '../../models/map';
import { useTranslation } from 'react-i18next';
import { BsPlus,BsArrowsFullscreen,BsFullscreenExit } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { Layer, polygonDataCalc, showFeatureInfo } from './Elements';
import { useSearchParams } from 'react-router-dom';
import { useStore } from '../../store';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import Potree from './PotreeDynamicData';
import proj4 from 'proj4';
import { renderToString } from 'react-dom/server';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;


// mapboxgl.id = "mapbox-satellite-v9";

function Maps() {
	const { t } = useTranslation();
	const { isAuthorized } = useStore();
	const [searchParams] = useSearchParams();
	const mapContainer = useRef(null);
	const map = useRef(null);
	const projectId = searchParams.get('project');
	const client = searchParams.get('client');
	const isDronodat =
		!isAuthorized([Roles.CLIENT_EMPLOYEE, Roles.CLIENT_ADMIN]) && client;

	const [tempSelectedProjects, setTempSelectedProjects] = useState([]);
	const [selectedProjects, setSelectedProjects] = useState([]);
	const [refresh, setRefresh] = useState(0);
	const [showProjects, setShowProjects] = useState(false);
	const [projectSearch, setProjectSearch] = useState('');
	const [geoData, setGeoData] = useState({});
	const [rezoom, setRezoom] = useState(true);
	const zoomTolerance = 0.01
	const [loading, setLoading] = useState(false);
	const [areaMeasure, setAreaMeasure] = useState(0);
	const [lineMeasure, setLineMeasure] = useState(0);
	const [properties, setProperties] = useState({});
	const { isOpen, onOpen, onClose } = useDisclosure();

	
	const [threeDbackrColor, setthreeDbackrColor] = useState('transparent');
	const [twoDbackrColor, settwoDbackrColor] = useState(DarkGreen.ButtonHighlightColor);
	const [TwoDBorderWidth,set2DBorderWidth] = useState('3px');
	const [THREEDBorderWidth,set3DBorderWidth] = useState('0px');
	const [TwoDMapWindowsize, setTwoDMapWindowsize]= useState('100%');
	const [ThreeDMapWindowsize, setThreeDMapWindowsize]= useState('1%');
	const [ThreeDMapPosition, setThreeDMapPosition]= useState('absolute');
	const [ThreeDMapWindowheight, setThreeDMapWindowheight]= useState('1%');
	const [CESIUMVIEWER,setCESIUMVIEWER]= useState(undefined);
	const [Cesium,setCesium]= useState(undefined);
	const [PotreeViewer , setpotreeViewerobj] = useState(undefined);

	const [DATAPROJ , setDataProjection] = useState(undefined);
	const [MAPPROJ , setMapProjection] = useState(undefined);

	// const [fullScreenIcon,setfullScreenIcon] = useState({"ableFullScreen":true, "url":"/full-screen-display-button-1.svg"});

	const [fullScreenIcon,setfullScreenIcon] = useState({"isFullScreen":false, "url": "/full-screen-display-button-1.svg" });


	const DATAPROJRef = useRef(null);
		DATAPROJRef.current = DATAPROJ;
	
	const MAPPROJRef = useRef(null);
		MAPPROJRef.current = MAPPROJ;

	const CESIUMVIEWERRef = useRef(null);
	CESIUMVIEWERRef.current = CESIUMVIEWER;

	const CesiumRef = useRef(null);
	CesiumRef.current = Cesium;

	const PotreeViewerRef = useRef(null);
		PotreeViewerRef.current = PotreeViewer;

	const [rightSwitcherMenuHeight, setrightSwitcherMenuHeight ]= useState('11rem')
	

	const { data: projects }: any = useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.PROJECTS,
			{
				limit: 99999,
			},
		),
		!isAuthorized([Roles.CLIENT_EMPLOYEE, Roles.CLIENT_ADMIN]) && !client,
	);

	useEffect(() => {
		if (!projects || !projectId) return;
		const temp = projects.results.find(
			(project) => project.id === Number(projectId),
		);
		setSelectedProjects([temp]);
		setTempSelectedProjects([temp.id]);
	}, [projectId, projects]);

	useEffect(() => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/dronodat/cl905vm02005815pr4jhim0rz',
			center: [0, 0],
			zoom: 1,
		});

		map.current.boxZoom.disable();
		map.current.touchZoomRotate.disableRotation();
		map.current.dragRotate.disable();
		map.current.on('load', () => {
			const canvas = map.current.getCanvasContainer();

			// Variable to hold the starting xy coordinates
			// when `mousedown` occured.
			let start;

			let ctrlClicked = false;

			// Set `true` to dispatch the event before other functions
			// call it. This is necessary for disabling the default map
			// dragging behaviour.
			canvas.addEventListener('mousedown', mouseDown, true);

			// Return the xy coordinates of the mouse position
			function mousePos(e) {
				const rect = canvas.getBoundingClientRect();
				return new mapboxgl.Point(
					e.clientX - rect.left - canvas.clientLeft,
					e.clientY - rect.top - canvas.clientTop,
				);
			}

			function mouseDown(e) {
				// Continue the rest of the function if the shiftkey is pressed.
				if (!(e.shiftKey && e.button === 0)) return;
				ctrlClicked = e.altKey;
				// Disable default drag zooming when the shift key is held down.

				// Call functions for the following events
				document.addEventListener('mouseup', onMouseUp);
				document.addEventListener('keydown', onKeyDown);

				// Capture the first xy coordinates
				start = mousePos(e);
			}

			function onMouseUp(e) {
				// Capture xy coordinates
				finish([start, mousePos(e)]);
			}

			function onKeyDown(e) {
				// If the ESC key is pressed
				if (e.keyCode === 27) finish('');
			}

			function finish(bbox) {
				// Remove the event listeners
				document.removeEventListener('keydown', onKeyDown);
				document.removeEventListener('mouseup', onMouseUp);
			
				// Define the layers to query
				const layersToQuery = [
					'LineString',
					'Point',
					'Polygon',
					'Polygon - None',
					'Polygon - 01 - Immediate',
					'Polygon - 1M - 1 Month',
					'Polygon - 3M - 3 Months',
					'LineString - None',
					'LineString - 01 - Immediate',
					'LineString - 1M - 1 Month',
					'LineString - 3M - 3 Months'
				];
			
				// Query the features for all layers in the list
				const allFeatures = layersToQuery.reduce((acc, layer) => {
					const features = map.current.queryRenderedFeatures(bbox, { layers: [layer] });
					const featureIDs = features.map((feature) => feature.properties.DBID);
					acc[layer] = featureIDs;
					return acc;
				}, {});
			
				// Loop through the layers and apply the filters
				Object.keys(allFeatures).forEach((layer) => {
					const filterName = `highlighted-${layer.replace(' ', '-')}`;
					const currentFilter = map.current.getFilter(filterName);
					const newFilter = currentFilter.filter((feature) => {
						return !allFeatures[layer].includes(feature);
					});
					map.current.setFilter(filterName, newFilter);
				});
			}
		});

		map.current.on('moveend', () => {
			//console.log(TwoDMapWindowsize)
			//console.log(CesiumRef.current ,CESIUMVIEWERRef.current)
			if (CESIUMVIEWERRef.current !== undefined && CesiumRef.current !== undefined && PotreeViewerRef.current !== undefined && MAPPROJRef.current !== undefined && DATAPROJRef.current !== undefined){

					let MapboxBBOX = map.current.getBounds()
					const center = map.current.getCenter();
					//console.log(MapboxBBOX)
					//console.log(map.current.getFreeCameraOptions());

					//let freecameraOptions = map.current.getFreeCameraOptions()
					//const nullIsland = new mapboxgl.MercatorCoordinate(freecameraOptions._position.x, freecameraOptions._position.y, freecameraOptions._position.z)
					//console.log(nullIsland.toLngLat())
					//console.log(nullIsland.toAltitude())
		
		
					//var positionSW = CesiumRef.current.Cartesian3.fromDegrees(MapboxBBOX._sw.lng, MapboxBBOX._sw.lat);
					//var positionNE = CesiumRef.current.Cartesian3.fromDegrees(MapboxBBOX._ne.lng, MapboxBBOX._ne.lat);

					//console.log(PotreeViewerRef.current,MapboxBBOX._sw.lng, MapboxBBOX._sw.lat,MapboxBBOX._ne.lng, MapboxBBOX._sw.lat)

					//console.log(DATAPROJRef.current,MAPPROJRef.current)
					
					let converterSW = proj4(MAPPROJRef.current,DATAPROJRef.current,[MapboxBBOX._sw.lng, MapboxBBOX._sw.lat]);
					let converterNE = proj4(MAPPROJRef.current,DATAPROJRef.current,[MapboxBBOX._ne.lng, MapboxBBOX._ne.lat]);
					let centerMap = proj4(MAPPROJRef.current,DATAPROJRef.current,[center.lng, center.lat])
					//console.log(converterSW[0]-converterNE[0], converterSW[1]-converterNE[1])
					/* PotreeViewerRef.current.scene.view.setView(
						[converterSW[0], converterSW[1],Math.abs(converterSW[0]-converterNE[0])],
						[converterNE[0], converterNE[1], Math.abs(converterSW[0]-converterNE[0])],
					); */
					//PotreeViewerRef.current.scene.view.lookAt((converterSW[0] + converterNE[0]) / 2,  (converterSW[1] + converterNE[1]) / 2, Math.abs(converterSW[0]-converterNE[0])/8);
					PotreeViewerRef.current.scene.view.position.set(centerMap[0], centerMap[1], Math.abs(converterSW[0]-converterNE[0]) * 0.7);
					PotreeViewerRef.current.scene.view.pitch = -CesiumRef.current.Math.PI_OVER_TWO;
					PotreeViewerRef.current.scene.view.yaw = 0;
					//PotreeViewerRef.current.scene.view.position.set(converterSW[0], converterSW[1], 509.311);
					//PotreeViewerRef.current.scene.view.lookAt(converterSW[0], converterSW[1], 10.009);

					//scene.view.position.set(177401.6606567141, 5766797.580573853, 509.311);
					//scene.view.lookAt(177401.6606567141, 5766797.580573853, 10.009);
					//scene.view.lookAt(177401.6606567141, 5766797.580573853, 10.009);
					/* PotreeViewerRef.current.scene.view.setView(
						[converterSW[0], converterSW[1], 1000],
						[converterNE[0], converterNE[1], 1000],
					);  */

					/*PotreeViewerRef.current.scene.view.setView(
						[3874650.8775919434, 713635.582721942, 4999001.52923034],
						[ 3732287.8837476787, 822634.4596051947, 5089143.590583862 ],
					); */

					
					/*

					CESIUMVIEWERRef.current.camera.setView({
						destination : CesiumRef.current.Rectangle.fromDegrees(MapboxBBOX._sw.lng, MapboxBBOX._sw.lat, MapboxBBOX._ne.lng, MapboxBBOX._ne.lat)
					}) 

					*/

					//let MercatorUnit = new mapboxgl.MercatorCoordinate(0.5317550878296325,0.3275284472693259,0.01175606683308062)
					//console.log(MercatorUnit.toLngLat())
					//console.log(MercatorUnit.toAltitude())
			}
		

		});


		const draw = new MapboxDraw({
			displayControlsDefault: false,
			controls: {
				polygon: true,
				line_string: true,
				trash: true,
			},
		});
		map.current.addControl(
			new mapboxgl.GeolocateControl({
				positionOptions: {
					enableHighAccuracy: true,
				},
				// When active the map will receive updates to the device's location as it changes.
				trackUserLocation: true,
				// Draw an arrow next to the location dot to indicate which direction the device is heading.
				showUserHeading: true,
			}),
			'bottom-right',
		);
		map.current.addControl(
			new MapboxGeocoder({
				accessToken: mapboxgl.accessToken,
				mapboxgl,
			}),
			'top-left',
		);

		// let TopLeftContainer = Array.from(document.getElementsByClassName('mapboxgl-ctrl-top-left') as HTMLCollectionOf<HTMLElement>)
		// 	TopLeftContainer[0].style.right = 'initial';
		// 	TopLeftContainer[0].style.left = '5rem';
		const geocoder = new MapboxGeocoder({
			accessToken: mapboxgl.accessToken,
			mapboxgl,
		})
		// document.getElementById('geocoder').appendChild(geocoder.onAdd(map.current));

		
		map.current.addControl(draw, 'bottom-right');
		map.current.on('click', (e) => {
			onClose();
			showFeatureInfo(e, map, setProperties, onOpen);
			polygonDataCalc(e, map, setAreaMeasure, setLineMeasure);
		});

		map.current.loadImage('/assets/images/power.png', (error, image) => {
			if (error) return;
			map.current.addImage('power', image);
		});
	});

	useEffect(() => {
		if (!map.current) return; // wait for map to initialize
		const Collections = {
			'Polygon - None': {
				type: 'FeatureCollection',
				features: [],
			},
			'Polygon - 01 - Immediate': {
				type: 'FeatureCollection',
				features: [],
			},
			'Polygon - 1M - 1 Month': {
				type: 'FeatureCollection',
				features: [],
			},
			'Polygon - 3M - 3 Months': {
				type: 'FeatureCollection',
				features: [],
			},
			'LineString - None': {
				type: 'FeatureCollection',
				features: [],
			},
			'LineString - 01 - Immediate': {
				type: 'FeatureCollection',
				features: [],
			},
			'LineString - 1M - 1 Month': {
				type: 'FeatureCollection',
				features: [],
			},
			'LineString - 3M - 3 Months': {
				type: 'FeatureCollection',
				features: [],
			},
			'LineString': {
				type: 'FeatureCollection',
				features: [],
			},
			'Point': {
				type: 'FeatureCollection',
				features: [],
			},
			'Polygon': {
				type: 'FeatureCollection',
				features: [],
			},
		};

		let data = [].concat.apply([], Object.values(geoData));
		const temp = [];
		data.forEach((feature) => {
			if (feature.geometry.type === 'GeometryCollection') {
				data = data.filter(
					(f) => f.properties.DBID !== feature.properties.DBID,
				);
				feature.geometry.geometries.forEach((geom) => {
					temp.push({ ...feature, geometry: geom });
				});
			}
		});

		data = [...data, ...temp];
		data?.forEach((feature) => {
			feature.properties.DBID = feature.properties.id;
			if (feature.geometry.type === 'Point') {
				Collections['Point'].features.push(feature);
			} else if (feature.geometry.type === 'LineString') {
				if (feature.properties.PRIORITY === '01 - Immediate') {
					Collections['LineString - 01 - Immediate'].features.push(feature);
				} else if (feature.properties.PRIORITY === 'None') {
					Collections['LineString - None'].features.push(feature);
				} else if (feature.properties.PRIORITY === '1M - 1 Month') {
					Collections['LineString - 1M - 1 Month'].features.push(feature);
				} else if (feature.properties.PRIORITY === '3M - 3 Months') {
					Collections['LineString - 3M - 3 Months'].features.push(feature);
				} else {
					Collections['LineString'].features.push(feature);
				}
			} else if (feature.geometry.type === 'Polygon') {
				if (feature.properties.PRIORITY === '01 - Immediate') {
					Collections['Polygon - 01 - Immediate'].features.push(feature);
				} else if (feature.properties.PRIORITY === 'None') {
					Collections['Polygon - None'].features.push(feature);
				} else if (feature.properties.PRIORITY === '1M - 1 Month') {
					Collections['Polygon - 1M - 1 Month'].features.push(feature);
				} else if (feature.properties.PRIORITY === '3M - 3 Months') {
					Collections['Polygon - 3M - 3 Months'].features.push(feature);
				} else {
					Collections['Polygon'].features.push(feature);
				}
			}
		});

		Object.keys(Collections).forEach((key) => {
			if (map.current.getLayer(key)) {
				map.current.removeLayer(key).removeSource(key);
			// 	map.current
			// 		.removeLayer('highlighted-' + key)
			// 		.removeSource('highlighted-' + key);
			}

			if (data.length === 0) return;

			if (key === 'Point') {
				map.current.addLayer({
					id: key,
					type: 'symbol',
					source: {
						type: 'geojson',
						data: Collections[key],
						tolerance: zoomTolerance
					},
					layout: {
						'icon-image': 'power',
						'icon-size': 0.3,
						'icon-anchor': 'center',
						'icon-offset': [0, -4],
					},
				});
				// map.current.addLayer({
				// // 	id: 'highlighted-Point',
				// // 	type: 'symbol',
				// // 	source: {
				// // 		type: 'geojson',
				// // 		data: Collections[key],
				// // 		tolerance: zoomTolerance
				// // 	},
				// // 	layout: {
				// // 		'icon-image': 'power',
				// // 		'icon-size': 0.3,
				// // 		'icon-anchor': 'center',
				// // 		'icon-offset': [0, -4],
				// // 	},
				// // 	filter: ['in', 'DBID', ''],
				// });
			} else if (key === 'LineString') {
				map.current.addLayer({
					id: key,
					type: 'line',
					source: {
						type: 'geojson',
						data: Collections[key],
						tolerance: zoomTolerance
					},
					layout: {
						'line-join': 'round',
						'line-cap': 'round',
					},
					paint: {
						'line-color': DefaultFeaturesColors.LineString,
						'line-width': 2,
					},
				});
				// map.current.addLayer({
				// 	id: 'highlighted-LineString',
				// 	type: 'line',
				// 	source: {
				// 		type: 'geojson',
				// 		data: Collections[key],
				// 		tolerance: zoomTolerance
				// 	},
				// 	layout: {
				// 		'line-join': 'round',
				// 		'line-cap': 'round',
				// 	},
				// 	paint: {
				// 		'line-color': 'purple',
				// 		'line-width': 2,
				// 	},
				// 	filter: ['in', 'DBID', ''],
				// });
			} else if (key === 'Polygon') {
				map.current.addLayer({
					id: key,
					type: 'fill',
					source: {
						type: 'geojson',
						data: Collections[key],
						tolerance: zoomTolerance
					},
					layout: {},
					paint: {
						'fill-color': DefaultFeaturesColors.Polygon,
						'fill-opacity': 1,
					},
				});
				// map.current.addLayer({
				// 	id: 'highlighted-Polygon',
				// 	type: 'fill',
				// 	source: {
				// 		type: 'geojson',
				// 		data: Collections[key],
				// 		tolerance: zoomTolerance
				// 	},
				// 	layout: {},
				// 	paint: {
				// 		'fill-color': 'pink',
				// 		'fill-opacity': 1,
				// 	},

				// 	filter: ['in', 'DBID', ''],
				// });
			} else if (key.includes('Polygon')) {
				map.current.addLayer({
					id: key,
					type: 'fill',
					source: {
						type: 'geojson',
						data: Collections[key],
						tolerance: zoomTolerance
					},
					layout: {},
					paint: {
						'fill-color': MapPriorityColor[key.replace('Polygon - ', '')],
						'fill-opacity': 1,
					},
				});
				// map.current.addLayer({
				// 	id: 'highlighted-' + key,
				// 	type: 'fill',
				// 	source: {
				// 		type: 'geojson',
				// 		data: Collections[key],
				// 		tolerance: zoomTolerance
				// 	},
				// 	layout: {},
				// 	paint: {
				// 		'fill-color': 'pink',
				// 		'fill-opacity': 1,
				// 	},

				// 	filter: ['in', 'DBID', ''],
				// });
			} else {
				map.current.addLayer({
					id: key,
					type: 'line',
					source: {
						type: 'geojson',
						data: Collections[key],
						tolerance: zoomTolerance
					},
					layout: {
						'line-join': 'round',
						'line-cap': 'round',
					},
					paint: {
						'line-color': MapPriorityColor[key.replace('LineString - ', '')],
						'line-width': 2,
					},
				});
				// map.current.addLayer({
				// 	id: 'highlighted-' + key,
				// 	type: 'line',
				// 	source: {
				// 		type: 'geojson',
				// 		data: Collections[key],
				// 		tolerance: zoomTolerance
				// 	},
				// 	layout: {
				// 		'line-join': 'round',
				// 		'line-cap': 'round',
				// 	},
				// 	paint: {
				// 		'line-color': 'purple',
				// 		'line-width': 2,
				// 	},
				// 	filter: ['in', 'DBID', ''],
				// });
			}
		});
	}, [geoData, refresh]);

	useEffect(() => {
		if (!rezoom) return;
		let data = [].concat.apply([], Object.values(geoData));
		const temp = [];
		data.forEach((feature) => {
			if (feature.geometry.type === 'GeometryCollection') {
				data = data.filter(
					(f) => f.properties.DBID !== feature.properties.DBID,
				);
				feature.geometry.geometries.forEach((geom) => {
					temp.push({ ...feature, geometry: geom });
				});
			}
		});

		data = [...data, ...temp];
		if (data.length === 0) {
			map.current.flyTo({
				center: { lat: 50.99775297810647, lng: 10.134234798566924 },
				zoom: 3,
			});
			return;
		}

		const initalCenter = averageGeolocation(
			data.map((feature) => {
				if (feature.geometry.type === 'Point')
					return {
						lat: feature.geometry.coordinates[1],
						lng: feature.geometry.coordinates[0],
					};
				if (feature.geometry.type === 'LineString') {
					return averageGeolocation(
						feature.geometry.coordinates.map((coord) => {
							return { lat: coord[1], lng: coord[0] };
						}),
					);
				} else {
					return averageGeolocation(
						feature.geometry.coordinates[0].map((coord) => {
							return { lat: coord[1], lng: coord[0] };
						}),
					);
				}
			}),
		);

		const bounds = getBounds(
			data
				.map((feature) => {
					if (feature.geometry.type === 'Point')
						return {
							lat: feature.geometry.coordinates[1],
							lng: feature.geometry.coordinates[0],
						};
					if (feature.geometry.type === 'LineString') {
						return feature.geometry.coordinates.map((coord) => {
							return { lat: coord[1], lng: coord[0] };
						});
					} else {
						return feature.geometry.coordinates[0].map((coord) => {
							return { lat: coord[1], lng: coord[0] };
						});
					}
				})
				.flat(),
		);
		const { clientHeight, clientWidth } = mapContainer.current;
		const newZoom = getBoundsZoomLevel(bounds, {
			width: clientWidth,
			height: clientHeight,
		});

		map.current.flyTo({
			center: initalCenter,
			zoom: newZoom,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geoData]);


	
	//Manage 2D / 3D button clicks
	const ButtonClickAtion = (Dimension) => {
		let MapboxwidthValue = '';
		let PotreewidthValue = '';
		
		if (Dimension == '2D'){
				//Change the background
					if(TwoDBorderWidth == '0px'){
						set2DBorderWidth('3px')
						
						settwoDbackrColor(DarkGreen.ButtonHighlightColor)
						//console.log(ThreeDMapWindowsize)
						if(ThreeDMapWindowsize == '0%'){
							setTwoDMapWindowsize('100%')
								MapboxwidthValue = '100%'
						}else {
							setTwoDMapWindowsize('50%')
								MapboxwidthValue = '50%'
							setThreeDMapWindowsize('50%')
							setThreeDMapWindowheight('100%')
								PotreewidthValue = '50%'
						}
						
					}else{
						set2DBorderWidth('0px')
						settwoDbackrColor('transparent')

						setTwoDMapWindowsize('0%')
							MapboxwidthValue = '0%'
						setThreeDMapWindowsize('100%')
						setThreeDMapWindowheight('100%')
							PotreewidthValue = '100%'
					}
		}else {
			
				//Change the background
					if(THREEDBorderWidth == '0px'){
						set3DBorderWidth('3px')
						setthreeDbackrColor(DarkGreen.ButtonHighlightColor)


						if(TwoDMapWindowsize == '0%'){
							setThreeDMapWindowsize('100%')
							setThreeDMapWindowheight('100%')
								PotreewidthValue = '100%'
						}else {
							setThreeDMapWindowsize('50%')
							setThreeDMapWindowheight('100%')
								PotreewidthValue = '50%'
							setTwoDMapWindowsize('50%')
								MapboxwidthValue = '50%'
						}

						setThreeDMapPosition('relative')

					}else{
						set3DBorderWidth('0px')
						setthreeDbackrColor('transparent')

						setThreeDMapWindowsize('1%')
						setThreeDMapWindowheight('1%')
							setThreeDMapPosition('absolute')
							PotreewidthValue = '0%'

						setTwoDMapWindowsize('100%')
							MapboxwidthValue = '100%'
					}

		}

		//If the user hide both map we hide them both
			if (PotreewidthValue == '0%' && MapboxwidthValue == '0%'){
				set2DBorderWidth('0px')
				set3DBorderWidth('0px')
				setthreeDbackrColor('transparent')
				settwoDbackrColor('transparent')

				setTwoDMapWindowsize('0%')
				setThreeDMapWindowsize('1%')
				setThreeDMapWindowheight('1%')
			}
		//Resize the Map
			//var mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0];
			var mapDiv = mapContainer.current;
			mapDiv.style.width = MapboxwidthValue;
            //mapCanvas.style.width = MapboxwidthValue;

			if(map.current !== null){
				map.current.resize();
			}

	}

	//Add a listener to disable 2D 3D switcher if only one is displayed

			useEffect(() => {
					
					let twoDbutton = document.getElementById("twoDswitch")
					let threeDbutton = document.getElementById("threeDswitch")

					if(TwoDMapWindowsize =='100%') {
						
						twoDbutton.style.pointerEvents = 'none'

					}else if (ThreeDMapWindowsize == '100%'){
						
						threeDbutton.style.pointerEvents = 'none'
						
					} else {
						twoDbutton.style.pointerEvents = 'auto'
						threeDbutton.style.pointerEvents = 'auto'
					}
			}, [ThreeDMapWindowsize,TwoDMapWindowsize])

	const CesiumViewerObj = (CesiumViewer) => {
		setCESIUMVIEWER(CesiumViewer)
	}
	const CesiumObj = (cesium) => {
		setCesium(cesium)
	}

	const potreeViewerobj = (potreeViewerobj) => {
		setpotreeViewerobj(potreeViewerobj)
	}

	const DataProjection  = (Projection) => {
		setDataProjection(Projection)
	}
	const MapProjection = (Projection) => {
		setMapProjection(Projection)
	}

	const fullscreen = () => {
		let MapContainer = document.getElementById('HallMapContainer');
		let header = document.getElementById('header')
		let sidebar = document.getElementById('sidebar')

		let mapBoxContainer = document.getElementById('mapContainer');
		


		let ableFullScreen = {"isFullScreen":true , "url":"/full-screen-display-button-1.svg" }
		let disableFullScreen = {"isFullScreen":false,"url":"/full-screen-button-7.svg"}
		
		
		if(fullScreenIcon.isFullScreen == true){
			//let optPointSizing = document.getElementById('optPointSizing');
		
			//MapContainer.appendChild(document.querySelector('#optPointSizing-menu'));
			
			
			MapContainer.style.position= 'absolute';
			MapContainer.style.width= '100vw';
			MapContainer.style.height= '100vh';
			MapContainer.style.zIndex= '2';
			MapContainer.style.borderLeft= '1px solid';
			MapContainer.style.borderColor= DarkGreen.layersBoxBorderColor;
			MapContainer.style.background= DarkGreen.layersBoxColor;


			MapContainer.scrollIntoView();

			header.style.display = 'none'

			sidebar.style.display = 'none'


			//define Mapbox / Cesium depending on previous style before the full screen
				if(TwoDMapWindowsize == '50%') {
					mapBoxContainer.style.width= '50vw';
    				mapBoxContainer.style.height= '100vh';
					setThreeDMapWindowsize('50%');
					setThreeDMapWindowheight('100%');
					set2DBorderWidth('3px')

					

				} else if (TwoDMapWindowsize == '100%'){
					mapBoxContainer.style.width= '100vw';
    				mapBoxContainer.style.height= '100vh';

					setThreeDMapWindowsize('1%');
					setThreeDMapWindowheight('1%');

					set2DBorderWidth('3px')
				} else {
					// 0%
					mapBoxContainer.style.width= '0%';
    				mapBoxContainer.style.height= '100vh';

					setThreeDMapWindowsize('100%');
					setThreeDMapWindowheight('100%');

					set2DBorderWidth('0px')
				}
			
			setfullScreenIcon(disableFullScreen)
			map.current.resize();

			setrightSwitcherMenuHeight('5rem')

			let TopLeftContainer = Array.from(document.getElementsByClassName('mapboxgl-ctrl-top-left') as HTMLCollectionOf<HTMLElement>)

			TopLeftContainer[0].style.top = '2rem';

			let potree_menu_toggle = Array.from(document.getElementsByClassName('potree_menu_toggle') as HTMLCollectionOf<HTMLElement>)
			potree_menu_toggle[0].style.margin = '8px';
		}else {
			//If it's a project page we move the right menu switcher
			
			setrightSwitcherMenuHeight('8.3rem')

			let potree_menu_toggle = Array.from(document.getElementsByClassName('potree_menu_toggle') as HTMLCollectionOf<HTMLElement>)
			potree_menu_toggle[0].style.margin = '97px';

			let TopLeftContainer = Array.from(document.getElementsByClassName('mapboxgl-ctrl-top-left') as HTMLCollectionOf<HTMLElement>)
			TopLeftContainer[0].style.top = '5rem';
			
			
			setfullScreenIcon(ableFullScreen)

			MapContainer.style.position= 'unset';
			MapContainer.style.width= '100%';
			MapContainer.style.height= '100%';
			//MapContainer.style.left= '-144px';
			//MapContainer.style.top= '-231px';
			
			header.style.display = 'flex'

			
			sidebar.style.display = 'unset'

			
            //mapCanvas.style.width = MapboxwidthValue;


			
			//define Mapbox / Cesium depending on previous style before the full screen
			if(TwoDMapWindowsize == '50%') {
				mapBoxContainer.style.width= '50%';
				mapBoxContainer.style.height= '100%';
				setThreeDMapWindowsize('50%');
				setThreeDMapWindowheight('100%');
				set2DBorderWidth('3px')

				document.getElementById("twoDswitch").click();
			setTimeout(() => {
				document.getElementById("twoDswitch").click();
			  }, 1000)
			  

			} else if (TwoDMapWindowsize == '100%'){
				mapBoxContainer.style.width= '100%';
				mapBoxContainer.style.height= '100%';

				setThreeDMapWindowsize('1%');
				setThreeDMapWindowheight('1%');

				set2DBorderWidth('3px')
			} else {
				// 0%
				mapBoxContainer.style.width= '0%';
				mapBoxContainer.style.height= '100%';

				setThreeDMapWindowsize('100%');
				setThreeDMapWindowheight('100%');

				set2DBorderWidth('0px')
			}

			map.current.resize();
			//document.getElementById("mapBoxContainer").click();
		}
		
	}
	
	const FireButtonClickAtion = (Dimension) => {
		
		ButtonClickAtion(Dimension)
	}

	useEffect(() => {

		return () => {
			// componentwillunmount in functional component.
			// Anything in here is fired on component unmount.
			let header = document.getElementById('header')
			let sidebar = document.getElementById('sidebar')

			header.style.display = 'flex'

	
			sidebar.style.display = 'unset'
			
		}
	}, [])
	  


	//Resize the page if needed
	// useEffect(() => {

	// 	if(map.current !== null){
	// 		//console.log(map.current)
	// 		map.current.resize();
	// 		document.getElementById("threeDswitch").click();

	// 		setTimeout(() => {
	// 			document.getElementById("threeDswitch").click();

	// 			if(Array.from(document.getElementsByClassName('potree_menu_toggle')).length > 0){
	// 				let potree_menu_toggle = Array.from(document.getElementsByClassName('potree_menu_toggle') as HTMLCollectionOf<HTMLElement>)
	// 				potree_menu_toggle[0].style.margin = '97px';
	// 			}

	// 		}, 1000)
	// 	}

	// },[map])

	const setMaterial = (material) => { // pointcloud styler
		setTimeout(function () {
			if (material === "rgb") {
				PotreeViewer.scene.scenePointCloud.children.forEach((child) => {
					if (child.pcoGeometry) {
					child.material._activeAttributeName = "rgba";
					}
				});
			}
			if (material === "classification") {
				PotreeViewer.setClassifications({
					20:      { visible: true, name: 'trees' , color: [0, 0, 0, 255] },
					2:       { visible: true, name: 'Ground' , color: [150, 150, 150, 255] },
					3:       { visible: true, name: 'Low Vegetation' , color: [253, 212, 1, 255] },
					4:       { visible: true, name: 'Mid Vegetation' , color: [	 40,100, 0,255] },
					5:       { visible: true, name: 'High Vegetation' , color: [40, 0, 0, 255] },
					11:      { visible: true, name: 'Wires' , color: [200, 20, 200, 255] },
					21:      { visible: true, name: 'Poles' , color: [2,2, 2, 255] },
					DEFAULT: { visible: true, name: 'other' , color: [100, 100,100, 255] },
				});
				// const scheme = {};
				// for(let i = 0; i < 32; i++){
				// 	scheme[i] = { visible: true, name: `random:_${i}`, color: [Math.random(), Math.random(), Math.random(), 1.0] };
				// }
				// PotreeViewer.setClassifications(scheme);

				PotreeViewer.scene.scenePointCloud.children.forEach((child) => {
					if (child.pcoGeometry) {
					child.material._activeAttributeName = "classification";
					}
				});
			}

			

		}, 50);
	}

	  const mystyles = {
		position: ThreeDMapPosition,
	 } as React.CSSProperties;

	 //default  potree position button switcher
		useEffect(() => {
			//console.log(document.getElementsByClassName('potree_menu_toggle'))
			if(Array.from(document.getElementsByClassName('potree_menu_toggle')).length > 0){
				let potree_menu_toggle = Array.from(document.getElementsByClassName('potree_menu_toggle') as HTMLCollectionOf<HTMLElement>)
				potree_menu_toggle[0].style.margin = '97px';
			}
				
			
		},[Array.from(document.getElementsByClassName('potree_menu_toggle')).length])

	return (
		<>
		<div id="HallMapContainer" style={{width: '100%',height: '100%'}}>
	

		{/* <Box
					cursor="pointer"
					
					overflow="hidden"
					h={ThreeDMapWindowheight}
					w={ThreeDMapWindowsize}
					float="left" 
					
					style={mystyles}
				>

						<Potree selectedProjects={selectedProjects} projectId={null} FireButtonClickAtion={FireButtonClickAtion} DataProjection={DataProjection} MapProjection={MapProjection} CesiumViewerObj={CesiumViewerObj} CesiumObj={CesiumObj} potreeViewerobj={potreeViewerobj} />
					
						
					</Box>  */}

			
					<Box
						pos="relative"
						overflow="hidden"
						h="100%"
						w={TwoDMapWindowsize}
						ref={mapContainer}
						className="map"
						id="mapContainer"
						float="left"
						zIndex="1"
					>
				{loading && (
					<>
					
						<Player
							src="/assets/loading.json"
							autoplay
							loop
							style={{
								position: 'absolute',
								left: '50%',
								top: '50%',
								transform: 'translate(-50%,-50%)',
								zIndex: '2',
								height: "200px",
								width: "200px",
							}}
						/>
						<Box
							pos="absolute"
							zIndex={1}
							w="100%"
							h="100%"
							bg="rgba(0,0,0,0.5)"
						></Box>
					</>
				)}
				{(areaMeasure > 0 || lineMeasure > 0) && (
					<Box
						pos="absolute"
						bottom="2rem"
						right="4rem"
						bg="whitesmoke"//{DarkGreen.layersBoxColor}
						border= "1px solid"
						borderColor={DarkGreen.layersBoxBorderColor}
						borderRadius="0.25rem"
						p="1rem"
						minW="40px"
						minH="40px"
						zIndex={99}
					>
						<VStack alignItems="flex-start" spacing={0}>
							{areaMeasure && (
								<Text color="black">
									<b>Area: </b>
									{areaMeasure} ㎡
								</Text>
							)}
							{lineMeasure && (
								<Text color="black">
									<b>Line: </b>
									{lineMeasure.toFixed(2)} km
								</Text>
							)}
						</VStack>
					</Box>
				)}
				{showProjects && (
					//Show Projects Box
					<Box
						pos="absolute"
						top="8.1875rem"
						right="245px"
						bg={DarkGreen.layersBoxColor}
						border="1px solid"
						borderColor={DarkGreen.layersBoxBorderColor}
						borderRadius="0.25rem"
						p="1rem"
						w="200px"
						h="200px"
						zIndex={99}
						data-aos="zoom-in"
					>
						<Text fontSize="lg" fontWeight="semibold" color={DarkGreen.layersBoxBorderTextColor}>
							{t('map.SelectLayers')}
						</Text>
						<HStack my="0.5rem">
							<Input
								color="black"
								value={projectSearch}
								onChange={(e) => {
									setProjectSearch(e.target.value);
								}}
								size="sm"
								placeholder={t('map.ProjectName')}
							/>
							<Icon w="1rem" h="1rem" color="gray.500" as={FiSearch} />
						</HStack>

						<Stack
							h="40%"
							overflowY="auto"
							spacing="0.25rem"
							direction="column"
						>

							{projects?.results
								?.filter((project) => {
									return project.name
										.toLowerCase()
										.includes(projectSearch.toLowerCase());
								})
								.map((project) => (
									<Checkbox
										iconColor="gray"
										borderColor="gray"
										key={project.id}
										value={project.id}
										onChange={(e) => {
											const newSelectedProjects = [...tempSelectedProjects];
											if (e.target.checked) {
												newSelectedProjects.push(project.id);
											} else {
												newSelectedProjects.splice(
													newSelectedProjects.indexOf(project.id),
													1,
												);
											}
											setTempSelectedProjects(newSelectedProjects);
										}}
										isChecked={tempSelectedProjects.includes(project.id)}
									>
									
									<Text color = {DarkGreen.layersBoxBorderTextColorMuted}> 
										{project.name}
									</Text>
									</Checkbox>

								))}
						</Stack>
						<Button
							mt="0.5rem"
							w="full"
							mx="0 auto"
							_hover={{
								bg: 'gray.800',
							}}
							size="xs"
							bg="gray"
							onClick={() => {
								
								setSelectedProjects(
									projects.results.filter((project) => {
										return tempSelectedProjects.includes(project.id);
									}),
								);
								setShowProjects(false);
							}}
						>
							{t('Done')}
						</Button>
					</Box>
				)}
				
				{isOpen && (
					//Properties Box
					<Box
						pos="absolute"
						top="8.1875rem"
						left="21.875rem"
						bg={DarkGreen.propertiesBoxColor}
						border="1px solid"
						borderColor={DarkGreen.propertiesBoxBorderColor}
						borderRadius="0.25rem"
						p="1rem"
						w="213px"
						maxH="300px"
						overflow="auto"
						zIndex={99}
						dir="rtl"
					>
						<HStack dir="ltr" justify="space-between">
							<Accordion defaultIndex={[0]} allowToggle w="full">
								<AccordionItem borderColor="transparent">
									<HStack justifyContent="space-between">
										<Text fontSize="lg" fontWeight="semibold" color = {DarkGreen.layersBoxBorderTextColor}>
											{t('Properties')}
										</Text>
										<AccordionButton w="auto" p="0">
											<AccordionIcon  color={DarkGreen.iconColor} />
										</AccordionButton>
									</HStack>
									<AccordionPanel p={'0.5rem 0.5rem 0.5rem 0'}>
										{Object.keys(properties).map((key, index) => (
											<Text
												color = {DarkGreen.layersBoxBorderTextColor}
												textAlign={'left'}
												key={index}
												mt="0.25rem"
											>
												<b>{key}:</b> {properties[key]}
											</Text>
										))}
									</AccordionPanel>
								</AccordionItem>
							</Accordion>
						</HStack>
					</Box>
				)}
			</Box>

			<Box // Potree Box
				cursor="pointer"

				overflow="hidden"
				h={ThreeDMapWindowheight}
				w={ThreeDMapWindowsize}
				float="left" 

				style={mystyles}
			>
				<HStack position={'absolute'} bottom="1rem" zIndex={9999} right="40%" >
					<Button 
						fontSize={"14px"}
						w="120px"
						h="30px"
						bg={DarkGreen.layersBoxColor} 
						color={DarkGreen.ButtonHighlightColor} 
						onClick={() => setMaterial("classification")}> {t("Classification")} 
					</Button>
					<Button 
						fontSize={"14px"}
						w="50px"
						h="30px"
						bg={DarkGreen.layersBoxColor} 
						color={DarkGreen.ButtonHighlightColor} 
						onClick={() => setMaterial("rgb")} > {t("RGB")} 
					</Button>
				</HStack>
				<Potree selectedProjects={selectedProjects} projectId={null} FireButtonClickAtion={FireButtonClickAtion} DataProjection={DataProjection} MapProjection={MapProjection} CesiumViewerObj={CesiumViewerObj} CesiumObj={CesiumObj} potreeViewerobj={potreeViewerobj} />

			</Box> 




			<Box
					cursor="pointer"
					pos="relative"
					overflow="hidden"
					
					w={"fit-content"}
					style={{zIndex: 1,top: '-33px',right: '-49%' }}
  		
				>
					
		

		</Box> 
		{/* <div id="geocoder" style={{position: 'absolute',top: '8.3rem',right: '-1rem',zIndex: 2}} className="geocoder"></div> */}
		<Box 
				pos="absolute" 
				top={rightSwitcherMenuHeight}
				right="1rem"
				bg={DarkGreen.layersBoxColor}
				border="1px"
				borderColor={DarkGreen.layersBoxBorderColor}
				borderRadius="0.25rem"
				p="1rem"
				w="213px"
				maxH="300px"
				overflow="auto"
				zIndex={1}
			>
				
				<HStack //layerbox Buttons

				>

					<Button 
						id="twoDswitch" 
						background = {twoDbackrColor}
						borderColor={DarkGreen.layersBoxBorderColor}

						_hover={{
							bg : DarkGreen.ButtonHighlightColor
						}}
						onClick={() => ButtonClickAtion('2D')} 
						style={{width: '20px',height: '30px',borderWidth: '1px',marginLeft: '26px',marginRight: '7px',marginBottom:'10px',}}>2D</Button>
					<Button  
						id="threeDswitch"
						onClick={() => ButtonClickAtion('3D')} 
						style={{width: '20px',height:'30px',marginBottom:'10px',background:threeDbackrColor, borderWidth: '1px', borderColor:DarkGreen.layersBoxBorderColor}}>3D</Button>
					<Button 
						bg="rgba(255,255,255,0.9)"//{ DarkGreen.ButtonHighlightColor }//
						style={{marginLeft: '6px',padding: "2px",borderRadius: '7px 7px 7px 7px',width: '1px',height:'30px',borderColor: DarkGreen.layersBoxBorderColor ,borderWidth: "3px",marginBottom:'10px',}} 
						_hover={{
							bg : DarkGreen.ButtonHighlightColor
						}}
						onClick={() => fullscreen()}
						>

						 <img width="20px" id= "FullScreenIcon"  src= {fullScreenIcon.url} />
						 </Button>
		
				</HStack>


				<HStack justify="space-between">
					
					
				<Accordion defaultIndex={[0]} allowToggle w="full">
							<AccordionItem borderColor="transparent">
								<HStack justifyContent="space-between">
									<Text fontSize="lg" fontWeight="semibold" color={DarkGreen.layersBoxBorderTextColor}>
										{t('2D Layers')}
									</Text>
									<HStack spacing={0} >
										
										<AccordionButton p="0">
											<AccordionIcon color={DarkGreen.iconColor} />
										</AccordionButton>
										<Icon
											onClick={() => {
												setShowProjects(!showProjects);
											}}
											
											title={t('map.ShowHideProjects')}
											cursor="pointer"
											_hover={{
												bg: 'blackAlpha.50',
											}}
											w="1.5rem"
											h="1.5rem"
											as={BsPlus}
											color={DarkGreen.iconColor}
										/>
									</HStack>
								</HStack>
								<AccordionPanel p={'0.5rem 0.5rem 0.5rem 0'} color={DarkGreen.layersBoxBorderTextColorMuted} >
									{selectedProjects.map((selectedProject) => (
										<Layer
											isDronodat={isDronodat}
											onChange={(newData) => {
												const projectId = Object.keys(newData)[0];
												const tempGeoData = { ...geoData };
												delete tempGeoData[projectId];
												if (
													[].concat(
														...Object.values({ ...tempGeoData, ...newData }),
													).length >=
													[].concat(...Object.values(geoData)).length
												)
													setRezoom(true);
												else setRezoom(false);
												setGeoData({
													...tempGeoData,
													...newData,
												});
											}}
											key={selectedProject.id}
											project={selectedProject}
											setLoading={setLoading}
										/>
									))}
									<Accordion
										mt="0.5rem"
										defaultIndex={[0]}
										allowToggle
										w="full"
									>
										<AccordionItem borderColor="transparent">
											<AccordionButton gap="0.5rem" p="0">
												<Text fontSize="sm" color={DarkGreen.layersBoxBorderTextColor}>
													{t('map.MapStyle')}
												</Text>
												<AccordionIcon color={DarkGreen.iconColor} />
											</AccordionButton>
											<AccordionPanel py="0.5rem">
												<RadioGroup
													flexDir="column"
													display="flex"
													gap="0.5rem"
													defaultValue="mapbox://styles/mapbox/dark-v10"
													color="muted"
													onChange={(v) => {
														map.current.setStyle(v);
														setTimeout(() => {
															map.current.loadImage(
																'/assets/images/power.png',
																(error, image) => {
																	if (error) return;
																	map.current.addImage('power', image);
																	setRefresh(refresh + 1);
																},
															);
														}, 500);
													}}
												>
													<Radio
														size="sm"
														colorScheme="gray"
														value="mapbox://styles/mapbox/dark-v10"
													>
														<Text color={DarkGreen.layersBoxBorderTextColor}>
															{t('map.Dark')}
														</Text>
													</Radio>
													<Radio
														size="sm"
														colorScheme="gray"
														value="mapbox://styles/mapbox/satellite-v9"
													>
														<Text color={DarkGreen.layersBoxBorderTextColor}>
															{t('map.Satellite')}
														</Text>
													</Radio>
												</RadioGroup>
											</AccordionPanel>
										</AccordionItem>
									</Accordion>
								</AccordionPanel>
							</AccordionItem>
							<AccordionItem borderColor="transparent">
							
								<HStack justifyContent="space-between">
									<Text fontSize="lg" fontWeight="semibold" color={DarkGreen.layersBoxBorderTextColor}>
										{t('3D Layers')}
									</Text>
									<HStack spacing={0}>
										<AccordionButton p="0">
											<AccordionIcon color={DarkGreen.iconColor} />
										</AccordionButton>
									</HStack>
								</HStack>
								<AccordionPanel style={{position: 'relative',top: '0px', left: '-29px'}} p={'0.5rem 0.5rem 0.5rem 0'}>
								
										<div id="scene_objects"></div>

								</AccordionPanel>

							</AccordionItem>
						</Accordion>
				</HStack>
			</Box>
			</div>
		</>
	);
}

export default Maps;
