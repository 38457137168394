import {
	Container,
	Divider,
	HStack,
	VStack,
	Box,
	Image,
	Icon,
	UnorderedList,
	useMediaQuery,
	useOutsideClick,
} from '@chakra-ui/react';
import { bg, logo,logo2 } from '../../assets/images';
import { MdAccountCircle, MdGroups } from 'react-icons/md';
import { FaProjectDiagram, FaMapMarked } from 'react-icons/fa';
import Link from './Link';
import { PAGES } from '../../shared/routes';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store';
import { Roles } from '../../shared/enums';
import useAnimateNumber from 'use-animate-number';
import { DarkGreen } from '../../shared/enums';
import { useLocation } from 'react-router-dom';
import { HiOutlineMenuAlt1 } from 'react-icons/hi';
function Sidebar({ isOpen, onClose ,onOpen}) {
	const { isAuthorized } = useStore();
	const [notDesktop] = useMediaQuery('(max-width: 62em)');
	const ref = useRef();
	const { t } = useTranslation();
	const [value, setValue] = useAnimateNumber(4, {
		duration: 600,
		enterance: true,
		direct: false,
		disabled: false,
		decimals: 5,
	});
	const [value2, setValue2] = useAnimateNumber(0, {
		duration: 600,
		enterance: true,
		direct: false,
		disabled: false,
		decimals: 5,
	});
	const location = useLocation();
	const isHomePage = location.pathname === '/';
	// console.log(isHomePage);

	useOutsideClick({
		ref: ref,
		enabled: notDesktop,
		handler: () => onClose(),
	});

	useEffect(() => {
		if(!isOpen){
			setValue(5, true);
			setValue2(0, true);
		}else {
			setValue(17.5, true);
			setValue2(17.5, true);
		}
		// console.log(window['MapboxGL'])
		if(window['MapboxGL'] !== null && window['MapboxGL'] !== undefined && document.getElementById("threeDswitch") !== null){
			window['MapboxGL'].resize();

				// document.getElementById("threeDswitch").click();
				// setTimeout(() => {
				// 	document.getElementById("threeDswitch").click();
				// }, 100)
		}
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return (
		<Container
			ref={ref}
			// width={{lg: `${value}rem`, base :"3rem"}}
			w={{lg: isOpen? `${value}rem`:`${value}rem`, base:isOpen? `${value}rem`:"4rem"} }
			// bg = {)
			bg={isHomePage ? {lg: DarkGreen.sidebarColorResponsive,base: DarkGreen.sidebarColorResponsive} : {lg: DarkGreen.sidebarColor,base: DarkGreen.sidebarColorResponsive} }
			zIndex={isOpen ? '999' : '888'}
			h="100vh"
			m="0"
			px="0"
			position="fixed"
			transition="width 0.3s ease-in-out"
			left={notDesktop ? (isOpen ? '0' : `-${value2}rem`) : '0'}
			id="sidebar" 
		>			
			<Box position="absolute" top={"3rem"}>
			<HStack ml={{lg:"1.6rem",base:"1rem"}} mt={"1.5rem"}>
			<Image
				src={logo2}
				h="4.5rem"
				cursor="pointer"
			/>
				<Image 
					src={logo}
					h="4.5rem"
					cursor="pointer"
					mt="-4.5rem"
					left="100%"
					opacity={isOpen ? "1":"0"}
					transition={isOpen ? "opacity 0.9s ease-in-out":"none"}
					// transitionDelay={isOpen ? "0.3s":"0"}
				/>
			</HStack>

			<Icon
				onClick={isOpen ? onClose : onOpen}
				cursor="pointer"
				as={HiOutlineMenuAlt1}
				h={isOpen ? "2rem" : "5rem"} 
				w={isOpen ? "2rem" : "5rem"} 
				mt={isOpen ? "3rem" : "1rem"} 
				color={isOpen ? "white" : "transparent"} 
				_hover={{
					bg: isOpen ? "transparent" : DarkGreen.ButtonHighlightColor,
					color: isOpen ?  DarkGreen.iconColor : "transparent"
				}}
				marginLeft={isOpen ? "18.5rem" : "0rem"}
				opacity={notDesktop ? 0 : isOpen ? 1 : 1}
				// transition='opacity 1s ease-in-out'
				style={{ transitionProperty: isOpen ? 'opacity' : 'none' }}
				position="absolute"
				top="0"
				left="0"
			/>
			{/* {isOpen && ( */}
			{/* )} */}
			</Box>

			


			<UnorderedList mt='10rem' ml="0" >
				<Link icon={FaMapMarked} label={t('Maps')} to={PAGES.MAPS} />
				<Link icon={MdAccountCircle} label={t('Account')} to={PAGES.ACCOUNT} />
				{/* <Divider w="75%" /> */}
				{isAuthorized([Roles.ADMIN]) && (
					<Link icon={MdGroups} label={t('Clients')} to={PAGES.CLIENTS} />
				)}
				{isAuthorized([Roles.ADMIN, Roles.CLIENT_ADMIN]) && (
					<Link icon={MdGroups} label={t('Employees')} to={PAGES.EMPLOYEES} />
				)}
				{isAuthorized([Roles.CLIENT_ADMIN, Roles.CLIENT_EMPLOYEE]) && (
					<Link
						icon={FaProjectDiagram}
						label={t('Projects')}
						to={PAGES.PROJECTS}
					/>
				)}
			</UnorderedList>
		</Container>
	);
}

export default Sidebar;
