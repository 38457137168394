import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Checkbox,
	HStack,
	Stack,
	Circle,
	Text,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { generateQueryString, getClientAPIUrl } from '../../shared/helpers';
import { useFetch } from '../../shared/hooks';
import { API } from '../../shared/routes';
import { MapLayers, MapPriorityColor, MAP_COLORS,DarkGreen } from '../../shared/enums';
import { useSearchParams } from 'react-router-dom';
import * as turf from '@turf/turf';
import api from '../../shared/api';

function ChangeMenuText({ key }) {
	return (
	  <Text whiteSpace="nowrap" fontSize="xs" color={DarkGreen.layersBoxBorderTextColorMuted}>
		{key.includes("01 - Immediate") && t("0-2m")}
		{key.includes("1M - 1 Month") && t("2-4m")}
		{key.includes("3M - 3 Months") && t("4- 6m")}
		{key.includes("None") && "None"}
	  </Text>
	);
  }
  
export const Layer = ({
	project,
	setLoading = (cond: boolean) => {},
	onChange,
	isDronodat,
	is_assigned = '',
	mission = '',
	customRefresh = false,
}) => {
	const [searchParams] = useSearchParams();
	const [checkedItems, setCheckedItems] = useState([]);
	const [polygonChecked, setPolygonChecked] = useState({});
	const [geoData, setGeoData] = useState([]);
	const [refresh, setRefresh] = useState(1);
	const client = searchParams.get('client');

	const { data: reports }: any = useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.REPORTS,
			{
				limit: 999,
				type: '1',
				project: project.id,
			},
		),
		customRefresh,
		({ data }: any) => {
			let oneUnProcessed = false;
			setCheckedItems(
				data?.results?.map((report: any) => {
					if (!report.processed && !oneUnProcessed) {
						oneUnProcessed = true;
						setTimeout(() => {
							setRefresh(refresh + 1);
						}, 1000 * 60);
					}
					return report.id.toString();
				}),
			);
			const keys = Object.keys(MapPriorityColor);
			const reportsKeys = data?.results?.map((report) => {
				return {
					[report.id]: {
						[keys[0]]: true,
						[keys[1]]: true,
						[keys[2]]: true,
						[keys[3]]: true,
					},
				};
			});
			const tempPolygonChecked = {};
			reportsKeys.forEach((report) => {
				Object.assign(tempPolygonChecked, report);
			});

			setPolygonChecked(tempPolygonChecked);
		},
		[refresh, customRefresh],
	);

	const { loading }: any = useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.MAPS_2D,
			{
				limit: 999999,
				project: project.id,
				mission,
				is_assigned: is_assigned, // FILTER OUT  Lines already assigned to a mission (edit mission also)
			},
		),
		reports ? reports.results.length === 0 : true,
		async ({ data: data2D }: any) => {
			const { data: data3D }: any = await api.get(
				generateQueryString(
					(isDronodat ? getClientAPIUrl(client) : '') + API.get.MAPS_3D,
					{
						limit: 999999,
						project: project.id,
						mission,
					},
				),
			);
			setGeoData([
				...data2D.results.features.map((d) => {
					return { ...d, '2d': true };
				}),
				...data3D.results.features.map((d) => {
					return { ...d, '2d': false };
				}),
			]);
			setLoading(false);
		},
	);

	useEffect(() => {
		if (loading) setLoading(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	useEffect(() => {
		const filteredData =
			geoData.length > 0
				? geoData.filter((feature) => {
						if (
							feature.geometry.type === 'Polygon' &&
							feature?.properties?.PRIORITY
						) {
							return polygonChecked[feature.properties.report]?.[
								feature.properties.PRIORITY
							];
						} else if (
							feature.geometry.type === 'LineString' &&
							feature?.properties?.PRIORITY
						) {
							return polygonChecked[feature.properties.report]?.[
								feature.properties.PRIORITY
							];
						} else
							return checkedItems.includes(
								feature.properties.report.toString(),
							);
				  })
				: [];
		onChange({
			[project.id]: filteredData,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkedItems, geoData, polygonChecked]);

	const allChecked = () => {
		let check = true;
		Object.values(polygonChecked).forEach((value) => {
			!Object.values(value).every(Boolean) && (check = false);
		});

		return (
			checkedItems.length === reports?.results?.length &&
			reports?.results?.length > 0 &&
			check
		);
	};

	const isIndeterminate = () => {
		if (!reports) return false;
		let check = false;
		Object.values(polygonChecked).forEach((value) => {
			Object.values(value).includes(true) && (check = true);
		});
		return !allChecked() && (checkedItems.some(Boolean) || check);
	};
	const isDisabled = reports?.results?.length === 0;
	const checkBoxPolygonColors = Object.keys(MAP_COLORS);

	return (
		<Accordion defaultIndex={isDisabled ? [] : [0]} allowToggle w="full" >
			<AccordionItem borderColor="transparent">
				<AccordionButton gap="0.5rem" p="0">
					<Checkbox
						isDisabled={isDisabled || project.name.length === 0}
						colorScheme="gray"
						isChecked={allChecked()}
						isIndeterminate={isIndeterminate()}
						onChange={(e) => {
							if (e.target.checked) {
								setCheckedItems(
									reports?.results?.map((report) => report.id.toString()),
								);
								const keys = Object.keys(MapPriorityColor);
								const reportsKeys = reports?.results?.map((report) => {
									return {
										[report.id]: {
											[keys[0]]: true,
											[keys[1]]: true,
											[keys[2]]: true,
											[keys[3]]: true,
										},
									};
								});
								const tempPolygonChecked = {};
								reportsKeys.forEach((report) => {
									Object.assign(tempPolygonChecked, report);
								});

								setPolygonChecked({
									...polygonChecked,
									...tempPolygonChecked,
								});
							} else {
								setCheckedItems([]);
								const keys = Object.keys(MapPriorityColor);
								const reportsKeys = reports?.results?.map((report) => {
									return {
										[report.id]: {
											[keys[0]]: false,
											[keys[1]]: false,
											[keys[2]]: false,
											[keys[3]]: false,
										},
									};
								});
								const tempPolygonChecked = {};
								reportsKeys.forEach((report) => {
									Object.assign(tempPolygonChecked, report);
								});

								setPolygonChecked(tempPolygonChecked);
							}
						}}
						color = {DarkGreen.layersBoxBorderTextColor}
					>
					<Text color = {DarkGreen.layersBoxBorderTextColor}>
											{project.name}

					</Text>
					</Checkbox>
					<AccordionIcon color={DarkGreen.iconColor} />
				</AccordionButton>
				<AccordionPanel py="0">
					<Stack mt={1} spacing={1}>
						{reports?.results?.map((report) => {
							return (
								<Accordion
									key={report.id}
									defaultIndex={[0]}
									allowToggle
									w="full"
								>
									<AccordionItem borderColor="transparent">
										<AccordionButton gap="0.5rem" p="0">
											<Checkbox
												key={report.id}
												colorScheme="gray"
												value={report.id}
												isChecked={
													report.details.geom_type !== 3
														? checkedItems.includes(report.id.toString())
														: polygonChecked[report.id]
														? Object.values(polygonChecked[report.id]).every(
																Boolean,
														  )
														: false
												}
												isIndeterminate={
													polygonChecked[report.id]
														? Object.values(polygonChecked[report.id]).some(
																Boolean,
														  ) &&
														  !Object.values(polygonChecked[report.id]).every(
																Boolean,
														  )
														: false
												}
												onChange={(e) => {
													const keys = Object.keys(MapPriorityColor);
													if (e.target.checked) {
														setCheckedItems([...checkedItems, e.target.value]);
														setPolygonChecked({
															...polygonChecked,
															[report.id]: {
																[keys[0]]: true,
																[keys[1]]: true,
																[keys[2]]: true,
																[keys[3]]: true,
															},
														});
													} else {
														setCheckedItems(
															checkedItems.filter(
																(item) => item !== e.target.value,
															),
														);
														setPolygonChecked({
															...polygonChecked,
															[report.id]: {
																[keys[0]]: false,
																[keys[1]]: false,
																[keys[2]]: false,
																[keys[3]]: false,
															},
														});
													}
												}}
											>
												<HStack>
													<Text color={DarkGreen.layersBoxBorderTextColor}>{report.title}</Text>
													{report.details.geom_type === 2 &&
														report.details?.schema?.includes('PRIORITY') && (
															<Box h="3px" bg="#329cd4" w="11px" />
														)}
													{report.details.geom_type === 3 &&
														report.details?.schema?.includes('PRIORITY') && (
															<Box
																h="12px"
																border="solid 2px #329cd4"
																w="16px"
															/>
														)}
												</HStack>
											</Checkbox>
											<AccordionIcon color={DarkGreen.iconColor} />
										</AccordionButton>
										<AccordionPanel py="0">
											<Stack mt={1} spacing={1}>
												{report.details.geom_type === 1 && (
													<HStack>
														<Circle
															size="12px"
															border="2px solid darkGray"
															bg="yellow.400"
														/>
														<Text fontSize="xs" color = {DarkGreen.layersBoxBorderTextColorMuted}>
															{t('map.Point')}
														</Text>
													</HStack>
												)}
												{report.details.geom_type === 2 &&
													!report.details?.schema?.includes('PRIORITY') && (
														<HStack>
															<Box h="3px" bg="#329cd4" w="12px" />
															<Text fontSize="xs" color = {DarkGreen.layersBoxBorderTextColorMuted}>
																{t('map.LineString')}
															</Text>
														</HStack>
													)}
												{report.details.geom_type === 2 &&
													report.details?.schema?.includes('PRIORITY') &&
													Object.keys(MapPriorityColor).map((key, index) => (
														<Checkbox
															key={key}
															colorScheme={checkBoxPolygonColors[index]}
															size="sm"
															value={key}
															isChecked={
																polygonChecked[report.id]
																	? polygonChecked[report.id][key]
																	: false
															}
															onChange={(e) => {
																if (e.target.checked) {
																	setPolygonChecked({
																		...polygonChecked,
																		[report.id]: {
																			...polygonChecked[report.id],
																			[key]: true,
																		},
																	});
																} else {
																	setPolygonChecked({
																		...polygonChecked,
																		[report.id]: {
																			...polygonChecked[report.id],
																			[key]: false,
																		},
																	});
																}
															}}
														>

														{ChangeMenuText({ key })}

														</Checkbox>
													))}
												{report.details.geom_type === 3 &&
													!report.details?.schema?.includes('PRIORITY') && (
														<HStack>
															<Box
																h="12px"
																border="solid 2px #329cd4"
																w="16px"
															/>
															<Text fontSize="xs" color={DarkGreen.layersBoxBorderTextColorMuted}>
																{t('map.Polygon')}
															</Text>
														</HStack>
													)}
												{report.details.geom_type === 3 &&
													report.details?.schema?.includes('PRIORITY') &&
													Object.keys(MapPriorityColor).map((key, index) => (
														<Checkbox
															key={key}
															colorScheme={checkBoxPolygonColors[index]}
															size="sm"
															value={key}
															isChecked={
																polygonChecked[report.id]
																	? polygonChecked[report.id][key]
																	: false
															}
															onChange={(e) => {
																if (e.target.checked) {
																	setPolygonChecked({
																		...polygonChecked,
																		[report.id]: {
																			...polygonChecked[report.id],
																			[key]: true,
																		},
																	});
																} else {
																	setPolygonChecked({
																		...polygonChecked,
																		[report.id]: {
																			...polygonChecked[report.id],
																			[key]: false,
																		},
																	});
																}
															}}
														>
														{ChangeMenuText({ key })}
														</Checkbox>
													))}
											</Stack>
										</AccordionPanel>
									</AccordionItem>
								</Accordion>
							);
						})}
					</Stack>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	);
};

export const drawPolygon = (points, map, type) => {
	map.current.addLayer({
		id: `maine`,
		type: 'fill',
		source: {
			type: 'geojson',
			data: {
				type: 'Feature',
				geometry: {
					type,
					coordinates: points,
				},
				properties: {
					typex: type,
				},
			},
		},
		paint: {
			'fill-color': '#088',
			'fill-opacity': 0.3,
		},

		layout: {},
	});
};

export const polygonDataCalc = (e, map, setAreaMeasure, setLineMeasure) => {
	const bbox = [
		[e.point.x - 5, e.point.y - 5],
		[e.point.x + 5, e.point.y + 5],
	];

	const selectedFeatures = map.current.queryRenderedFeatures(bbox);

	if (selectedFeatures.length > 0) {
		const drawFeature = selectedFeatures.find(
			(feature) =>
				feature?.layer?.id === 'gl-draw-line-inactive.cold' ||
				feature?.layer?.id === 'gl-draw-polygon-fill-inactive.cold',
		);
		// console.log(drawFeature.feature);
		
		if (drawFeature?.properties['meta:type'] === 'Polygon') {
			let area = turf.area(drawFeature);
			let rounded_area = Math.round(area * 100) / 100;
			setAreaMeasure(rounded_area);
			setLineMeasure(0);
		} else if (drawFeature?.properties['meta:type'] === 'LineString') {
			let line = turf.lineDistance(drawFeature);
			setLineMeasure(line);
			setAreaMeasure(0);
		} else {
			setAreaMeasure(0);
			setLineMeasure(0);
		}
	} else {
		setAreaMeasure(0);
		setLineMeasure(0);
	}
};

export const createArea = (e, map, draw, onPointPlacement = (cord) => {}) => {
	if (e.features[0].geometry.type === 'Point') {
		onPointPlacement(e.features[0].geometry.coordinates);
	} else {
		// let data = draw.getAll();
		// const polygonData = data.features[0].geometry.coordinates;
		// drawPolygon(polygonData, map, e.features[0].geometry.type);
	}
};

export const deleteArea = (e, map) => {
	map.current.removeLayer('maine').removeSource('maine');
};

export const updateArea = (e, map, draw) => {
	// let data = draw.getAll();
	map.current.removeLayer('maine').removeSource('maine');
	// const polygonData = data.features[0].geometry.coordinates;
	// drawPolygon(polygonData, map, e.features[0].geometry.type);
};

export const showFeatureInfo = (
	e,
	map,
	setProperties,
	onOpen,
	onOpenPinPoint = () => {},
) => {
	const bbox = [
		[e.point.x - 5, e.point.y - 5],
		[e.point.x + 5, e.point.y + 5],
	];
	const selectedFeatures = map.current.queryRenderedFeatures(bbox);
	if (selectedFeatures.length > 0) {
		if (MapLayers.includes(selectedFeatures[0].layer.id)) {
			// setProperties(selectedFeatures[0].properties);
			console.log(MapLayers)


			const dataTotal = JSON.parse(selectedFeatures[0].properties.surrounding_data);
			const totalArea = dataTotal.area?  dataTotal.area.toFixed(3) : "-"
			
			const dataPerPrior = JSON.parse(selectedFeatures[0].properties.surrounding_data_per_priority);
			const priorities = Object.keys(dataPerPrior);
			const priorityList = [];
			for (let i = 0; i < priorities.length; i++) {
			  const priority = priorities[i];
			  const area = dataPerPrior[priority].area;
			//   const count = dataPerPrior[priority].count;
			  priorityList.push({priority: priority, area: area? area.toFixed(3): "-"});
			}
			const formattedPriorityList = priorityList.map(p => `${p.priority}: ${p.area} ㎥`);
			const formattedPriorityListString = formattedPriorityList.join(", ");
			
			setProperties({ //properties box
			  ID: selectedFeatures[0].properties.DBID,
			  PRIORITY: selectedFeatures[0].properties.PRIORITY,
			  "File-ID":selectedFeatures[0].properties.report,
			  "Total Area": totalArea + "㎥",
			  "Area/Priority":formattedPriorityListString ,
			  LeitungsNr:selectedFeatures[0].properties.M_STR_NAM,
			});
			onOpen();

		} else if (selectedFeatures[0].layer.id === 'Notes') {
			setProperties({
				note: selectedFeatures[0].properties.note,
				pictures: JSON.parse(selectedFeatures[0].properties.pictures),
				mission: selectedFeatures[0].properties.mission,
				id: selectedFeatures[0].properties.id,
			});
			onOpenPinPoint();
		}
	}
};
