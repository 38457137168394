import {
	FormControl,
	FormLabel,
	InputGroup,
	InputLeftElement,
	Icon,
	InputRightElement,
	FormErrorMessage,
	Input,
} from '@chakra-ui/react';
import { useState } from 'react';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { DarkGreen} from '../../../shared/enums';

// interface InputProps {
// 	label: string;
// 	name: string;
// 	type: 'text' | 'password' | 'email' | 'number';
// 	placeholder: string;
// 	icon: any;
// }

function CustomInput({
	label = '',
	type = 'text',
	placeholder = '',
	icon = undefined,
	error = undefined,
	touched = undefined,
	field = undefined,
	isDisabled = false,
	onKeyDown = undefined,
	ref = undefined,
}) {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<FormControl
			isInvalid={error && touched}
			px={{ md: '2rem', base: '1rem' }}
			mb="2rem"
		>
			{label && (
				<FormLabel
					mb="1rem"
					as="legend"
					fontWeight="bold"
					fontSize="18px"
					color="white"
				>
					{label}
				</FormLabel>
			)}

			<InputGroup w="100%" >
				{icon && (
					<InputLeftElement
						pointerEvents="none"
						pl="1rem"
						children={<Icon w="1.5rem" h="1.5rem" color="#73BCB6" as={icon} />}
					/>
				)}
				{type === 'password' && (
					<InputRightElement
						cursor="pointer"
						onClick={() => setShowPassword(!showPassword)}
						zIndex="99"
						children={
							<Icon
								w="1.5rem"
								h="1.5rem"
								color="#73BCB6"
								as={showPassword ? AiFillEyeInvisible : AiFillEye}
							/>
						}
					/>
				)}
				<Input
					onKeyDown={onKeyDown}
					ref={ref}
					bg="transparent"
					pl={icon ? '4rem' : '1rem'}
					color={DarkGreen.inputTextColor}
					borderColor={DarkGreen.inputBorderColor}
					borderRadius="0.25rem"
					{...field}
					id={field.name}
					placeholder={placeholder}
					disabled={isDisabled}
					cursor="pointer"
					type={
						type === 'password' ? (showPassword ? 'text' : 'password') : type
					}
				/>
			</InputGroup>
			<FormErrorMessage>{error}</FormErrorMessage>
		</FormControl>
	);
}

export default CustomInput;
