import {
	Button,
	HStack,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../shared/api';
import { API, PAGES } from '../../shared/routes';
import CustomInput from '../shared/Input';
import Title from '../shared/Title';
import * as Yup from 'yup';
import { useFetch } from '../../shared/hooks';
import { generateQueryString } from '../../shared/helpers';
import { useState } from 'react';
import Dropdown from '../shared/Dropdown';
import { useTranslation } from 'react-i18next';
import ConfirmDelete from '../shared/ConfirmDelete';

function NewUpdateClient() {
	const [countries, setCountries] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { id } = useParams();
	const navigate = useNavigate();
	const isEditMode = id !== 'new' && id !== undefined;
	const toast = useToast();
	const { t } = useTranslation();

	const { data: client, isError }: any = useFetch(
		API.get.CLIENTS + id + '/',
		!isEditMode,
	);

	useFetch(
		generateQueryString(API.get.COUNTRIES, {
			limit: 1000,
		}),
		null,
		({ data }: any) => {
			setCountries(data.results);
		},
	);

	if (isError && isEditMode) navigate(PAGES.NOT_FOUND);

	const initialValues = {
		name: '',
		country: '',
		...(isEditMode &&
			client && {
				...client,
				country: {
					value: client.country,
					label: countries.find((c) => c.code === client.country)?.name,
				},
			}),
	};
	return (
		<>
			<Title
				title={
					isEditMode
						? `${t('clients.edit.Title')} ${id}`
						: t('clients.new.Title')
				}
				px={{ md: '4rem', base: '2rem' }}
				mb="3rem"
				data-aos="fade-down"
			/>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={Yup.object({
					name: Yup.string().required(),
					domain: Yup.string()
						.required()
						.matches(
							/^[a-z]+$/,
							'Only alphabets allowed and lowercase for this field ',
						),
					country: Yup.object().required(),
				})}
				onSubmit={async (values: any, actions) => {
					try {
						values = {
							...values,
							country: values.country.value,
						};
						if (isEditMode) {
							await api.put(`${API.put.CLIENTS}${id}/`, values);
							toast({
								title: t('clients.edit.toast.Title'),
								description: t('clients.edit.toast.Description'),
								status: 'success',
								duration: 3000,
								isClosable: true,
								position: 'bottom-right',
							});
						} else {
							await api.post(`${API.put.CLIENTS}`, values);
							toast({
								title: t('clients.new.toast.Title'),
								description: t('clients.new.toast.Description'),
								status: 'success',
								duration: 3000,
								isClosable: true,
								position: 'bottom-right',
							});
						}
						navigate(PAGES.CLIENTS);
					} catch (e) {
						toast({
							title: t('Error'),
							description: t('TryAgain'),
							status: 'error',
							duration: 3000,
							isClosable: true,
							position: 'bottom-right',
						});
						const errors = e.response.data;
						actions.setErrors({
							name: errors.name?.pop(),
							country: errors.address?.pop(),
						});
					}
					actions.setSubmitting(false);
				}}
			>
				{(props) => (
					<Form data-aos="fade-down">
						<Field name="name">
							{({ field, form }) => (
								<CustomInput
									label={t('Name')}
									type={'text'}
									error={form.errors.name}
									touched={form.touched.name}
									field={field}
								/>
							)}
						</Field>
						<Field name="domain">
							{({ field, form }) => (
								<CustomInput
									label={t('Domain')+' (****.dronodat.com)'}
									type={'text'}
									error={form.errors.domain}
									touched={form.touched.domain}
									field={field}
								/>
							)}
						</Field>
						<Field name="country">
							{({ field, form }) => (
								<Dropdown
									errors={form.errors.country}
									touched={form.touched.country}
									label={t('Country')}
									placeholder={t('SelectCountry')}
									options={countries.map((country) => ({
										label: country.name,
										value: country.code,
									}))}
									onChange={(value) => {
										form.setFieldValue('country', value);
									}}
									values={field.value}
								/>
							)}
						</Field>
						<HStack
							columnGap="10"
							rowGap="5"
							flexWrap="wrap"
							mx={{ md: '6rem', base: '1rem' }}
							pb="2rem"
							spacing={0}
						>
							{isEditMode ? (
								<>
									<Button
										background="transparent linear-gradient(180deg, #00965B 0%, #065688 100%) 0% 0% no-repeat padding-box;"
										_hover={{
											background:
												'transparent linear-gradient(180deg, #065688 0%, #00965B 100%) 0% 0% no-repeat padding-box;',
										}}
										isLoading={props.isSubmitting}
										type="submit"
										borderRadius={'0.25rem'}
										color="white"
										minW={{ lg: '210px', base: '130px' }}
									>
										{t('Update')}
									</Button>
									<Button
										background="transparent"
										borderRadius={'0.25rem'}
										borderWidth="2px"
										borderColor="white"
										color="white"
										minW={{ lg: '210px', base: '130px' }}
										onClick={() => {
											navigate(-1);
										}}
										_hover={{
											color: 'black',
											background: 'white',
										}}
									>
										{t('Cancel')}
									</Button>
									<ConfirmDelete
										onClose={onClose}
										isOpen={isOpen}
										onConfirm={async () => {
											try {
												await api.delete(API.delete.CLIENTS + id + '/');
												toast({
													title: t('clients.delete.toast.Title'),
													description: t('clients.delete.toast.Description'),
													status: 'success',
													duration: 3000,
													isClosable: true,
													position: 'bottom-right',
												});
												navigate(PAGES.CLIENTS);
											} catch {
												toast({
													title: t('Error'),
													description: t('TryAgain'),
													status: 'error',
													duration: 3000,
													isClosable: true,
													position: 'bottom-right',
												});
											}
										}}
									/>
									<Button
										background="transparent"
										borderRadius={'0.25rem'}
										borderWidth="2px"
										borderColor="red"
										color="white"
										_hover={{
											background: 'red',
										}}
										minW={{ lg: '210px', base: '130px' }}
										onClick={onOpen}
									>
										{t('Delete')}
									</Button>
								</>
							) : (
								<>
									<Button
										background="transparent linear-gradient(180deg, #00965B 0%, #065688 100%) 0% 0% no-repeat padding-box;"
										_hover={{
											background:
												'transparent linear-gradient(180deg, #065688 0%, #00965B 100%) 0% 0% no-repeat padding-box;',
										}}
										isLoading={props.isSubmitting}
										type="submit"
										borderRadius={'0.25rem'}
										color="white"
										minW={{ lg: '210px', base: '130px' }}
									>
										{t('Create')}
									</Button>
									<Button
										background="transparent"
										borderRadius={'0.25rem'}
										borderWidth="2px"
										borderColor="white"
										color="white"
										minW={{ lg: '210px', base: '130px' }}
										onClick={() => {
											navigate(-1);
										}}
										_hover={{
											color: 'black',
											background: 'white',
										}}
									>
										{t('Cancel')}
									</Button>
								</>
							)}
						</HStack>
					</Form>
				)}
			</Formik>
		</>
	);
}

export default NewUpdateClient;
