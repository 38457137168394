import { ChakraProvider } from '@chakra-ui/react';
import { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Loader from './components/Loader';
import Routes from './Routes';
import { Store } from './store';
import theme from './theme';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
	if(process.env.REACT_APP_ENVIRONMENT === 'production'){
		console.log = () => {}
		console.error = () => {}
		console.debug = () => {}
		console.warn = () => {}
	}

	useEffect(() => {
		AOS.init({
			duration: 750,
			once: true,
		});
		AOS.refresh();
	}, []);

	return (
		<BrowserRouter>
			<Store>
				<ChakraProvider theme={theme}>
					<Suspense fallback={<Loader />}>
						<Routes />
					</Suspense>
				</ChakraProvider>
			</Store>
		</BrowserRouter>
	);
}

export default App;
