import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import React from 'react';
import Select from 'react-select';
import { DarkGreen } from '../../../shared/enums';
function Dropdown({
	isMulti = false,
	errors = false,
	touched = false,
	label = '',
	placeholder,
	onChange,
	options,
	values=undefined,
}) {
	return (
		<FormControl
			isInvalid={errors && touched}
			px={{ md: '2rem', base: '1rem' }}
			mb="2rem"
		>
			{label && (
				<FormLabel
					mb="1rem"
					as="legend"
					fontWeight="bold"
					fontSize="18px"
					color="white"
				>
					{label}
				</FormLabel>
			)}
			<Select
				onChange={(list) => {
					onChange(list);
				}}
				options={options}
				isMulti={isMulti}
				placeholder={placeholder}
				value={values}
				styles={{
					option: (provided, { isFocused }) => ({
						...provided,
						color: 'black',
						backgroundColor: isFocused ? '#73BCB6' : 'white',
						cursor: 'pointer',
					}),
					control: (provided) => ({
						...provided,
						width: '100%',
						// maxWidth: '43.75rem',
						background: DarkGreen.inputBackgroundColor,
						borderColor: DarkGreen.inputBorderColor,
						boxShadow: 'none',
						borderRadius: '0.25rem',
						minHeight: '48px',
						color: DarkGreen.inputTextColor,
						cursor: 'pointer',

					}),
					container: (provided) => ({
						...provided,
						width: 'full',
						// maxWidth: '43.75rem',
					}),
					dropdownIndicator: (provided) => ({
						...provided,
						color:DarkGreen.inputTextColor,
					}),
					clearIndicator: (provided) => ({
						...provided,
						color:DarkGreen.inputTextColor,
					}),
					indicatorSeparator: () => ({}),
					input: (provided) => ({
						...provided,
						color:DarkGreen.inputTextColor,
					}),
					multiValueRemove: (provided) => ({
						...provided,
					}),
					multiValue: (base, state) => {
						return state.data.label ? base:{ ...base, display: 'none' } ;
					},
					singleValue: (provided) => ({
						...provided,
						color:DarkGreen.inputTextColor,
					}),
				}}
			/>

			<FormErrorMessage>{errors}</FormErrorMessage>
		</FormControl>
	);
}

export default Dropdown;
