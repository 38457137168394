import { useEffect, useRef, useState } from 'react';

//import * as THREE from 'thr';
import { useFetch } from '../../shared/hooks';
import { generateQueryString, getClientAPIUrl } from '../../shared/helpers';
import { API, PAGES } from '../../shared/routes';
import { useNavigate, useSearchParams,useParams } from 'react-router-dom';
import { PaginationOptions, Roles, Status, StatusColors } from '../../shared/enums';
import { useStore } from '../../store';
import * as THREE from './three.module';
import { useFocusEffect } from '@chakra-ui/react';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Checkbox,
	HStack,
	Icon,
	Input,
	Radio,
	RadioGroup,
	Stack,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { BsPlus } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const Potree = ({projectId,FireButtonClickAtion,CesiumViewerObj,CesiumObj,potreeViewerobj,	DataProjection,MapProjection}) => {

	const { t } = useTranslation();
    const potreeContainerDiv = useRef(null); 
	const potreeObj = useRef(null);
	const cesiumContainerDiv = useRef();

	const [searchParams] = useSearchParams();
	const { id } = useParams();
	const client = searchParams.get('client');
	const project = searchParams.get('project');
	const { isAuthorized, state } = useStore();

	const [PointcloudArray,setPointcloudArray] = useState([])
		const PointcloudArrayRef = useRef();
			PointcloudArrayRef.current = PointcloudArray;

	const isDronodat =
	!isAuthorized([Roles.CLIENT_EMPLOYEE, Roles.CLIENT_ADMIN]) && client;

	//const [potreeViewerOBJ, setpotreeViewerOBJ] = useState(undefined);
	//console.log(projectId)
	//Add point cloud layer
	useEffect(async () => {

		let potreeViewer = potreeObj.current;
		const proj4 = window.proj4;
		const Potree = window.Potree;

		DataProjection("+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs +type=crs")
		MapProjection(proj4.defs("WGS84"))
		//console.log(PointcloudArrayRef.current)
		//console.log(PointcloudArray)
		
		if(PointcloudArray.length > 0) {
			const asyncRes = await Promise.all(PointcloudArrayRef.current.map(async (pointCloudObj) => {
							let url = "https://dronospace.ams3.digitaloceanspaces.com/"+pointCloudObj.path+"/metadata.json" // do not forget to put your pointcloud data in the public/pointcloud/ folder
							Potree.loadPointCloud(url).then(e => {
							
				
							let pointcloud = e.pointcloud;
										let material = pointcloud.material;
										e.pointcloud.name = pointCloudObj.filename;
										potreeViewer.scene.addPointCloud(pointcloud);
										material.activeAttributeName = "rgba";
										material.size = 1;
										material.pointSizeType = Potree.PointSizeType.FIXED;
										material.shape = Potree.PointShape.SQUARE;
										
										const bboxWorld = e.pointcloud.getBoundingBoxWorld();
										const pcCenter = new THREE.Vector3();
										bboxWorld.getCenter(pcCenter);
								
							let pointcloudProjection = "+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs +type=crs";
							
							
									let mapProjection = proj4.defs("WGS84");
							
									window.toMap = proj4(pointcloudProjection, mapProjection);
									window.toScene = proj4(mapProjection, pointcloudProjection);
									
									{
										let bb = potreeViewer.getBoundingBox();
							
										let minWGS84 = proj4(pointcloudProjection, mapProjection, bb.min.toArray());
										let maxWGS84 = proj4(pointcloudProjection, mapProjection, bb.max.toArray());
									}
							
									//potreeViewer.scene.view.yaw =Math.PI;
							
									//potreeViewer.fitToScreen();

									potreeViewer.scene.view.setView(
										[594422.0695052966, 5761784.599627486 ,500],
										[ 755794.3447437682, 5915990.72493564, 500],
									);
									potreeViewer.scene.view.lookAt(594422.0695052966, 5761784.599627486, 30.009);
							
							
										
							
							
							
							
							
							
							
							
							
							
								
									
							}, e => console.log("ERROR: ", e));
			}));

			


			//display 3D viewer if data exist
			//FireButtonClickAtion('3D')
	
		} 
					//scene.view.position.set(177401.6606567141, 5766797.580573853, 509.311);
						//scene.view.lookAt(177401.6606567141, 5766797.580573853, 10.009);
						//scene.view.lookAt(177401.6606567141, 5766797.580573853, 10.009);
					
	},[PointcloudArrayRef.current])
	useEffect(() => {
		


        if (typeof window !== 'undefined') {
			//console.log(Object.keys(window))
			if (potreeObj.current) return;
        // initialize Potree viewer
        const Potree = window.Potree;
        //const THREE = window.THREE;
        const TWEEN = window.TWEEN;
		const Cesium = window.Cesium;
		const proj4 = window.proj4;

		//console.log(Potree,TWEEN,window.Cesium,window.cesium,window.CESIUM,window.proj4,window.Proj4)
		
		

        
       
		Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1NjFjZGM4Ny1lZjMxLTQ2ZTMtODI5Mi0yY2Q1ODAzZDZhY2QiLCJpZCI6MzYzODMsImlhdCI6MTYwMzM5MDkyN30.fTP3H3loFHOx4lXAl398OkcdoPwGsva3LZNdv-Wpxig"


		let cesiumViewer = new Cesium.Viewer('cesiumContainer', {
			useDefaultRenderLoop: false,
			animation: false,
			baseLayerPicker : false,
			fullscreenButton: false, 
			geocoder: false,
			homeButton: false,
			infoBox: false,
			sceneModePicker: false,
			selectionIndicator: false,
			timeline: false,
			navigationHelpButton: false,
			imageryProvider :  new Cesium.MapboxStyleImageryProvider({
				username: 'dronodat', styleId: 'cl905vm02005815pr4jhim0rz', accessToken: 'pk.eyJ1IjoiZHJvbm9kYXQiLCJhIjoiY2w0bGphN3V5MHc1YzNjbWhlMzF3MjJteCJ9.wGjwO4B9NxaeFXxqILYShA'}),
			terrainShadows: Cesium.ShadowMode.DISABLED,
			terrainProvider: new Cesium.CesiumTerrainProvider({ url: Cesium.IonResource.fromAssetId(1), })
		});
		cesiumViewer.scene.skyBox.destroy();
		cesiumViewer.scene.skyBox = undefined
		cesiumViewer.scene.sun.destroy();
		cesiumViewer.scene.sun = undefined;
		cesiumViewer.scene.skyAtmosphere.destroy();
		cesiumViewer.scene.skyAtmosphere = undefined;
		cesiumViewer.scene.globe.enableLighting = true

		CesiumViewerObj(cesiumViewer)
		CesiumObj(Cesium)
		
	
		let cp = new Cesium.Cartesian3(4303414.154026048, 552161.235598733, 4660771.704035539);
		cesiumViewer.camera.setView({
			destination : cp,
			orientation: {
				heading : 10, 
				pitch : -Cesium.Math.PI_OVER_TWO * 0.5, 
				roll : 0.0 
			}
		}); 
	
		//const viewerElem = potreeContainerDiv.current

		potreeObj.current = new Potree.Viewer(potreeContainerDiv.current, {
			useDefaultRenderLoop: false
		});
		let potreeViewer = potreeObj.current;
		potreeViewer.setEDLEnabled(true);
		potreeViewer.setFOV(60);
		potreeViewer.setPointBudget(3_000_000);
		potreeViewer.setMinNodeSize(50);
		potreeViewer.loadSettingsFromURL();
		potreeViewer.setBackground(null);
		potreeViewer.useHQ = true;
	
		potreeViewer.loadGUI(() => {
		potreeViewer.setLanguage('en');
		//$("#menu_appearance").next().show();
		//potreeViewer.toggleSidebar();
		//viewer.toggleSidebar();
		});
		//console.log(PointcloudArray)
		potreeViewerobj(potreeViewer)
		

	
		function loop(timestamp){
			requestAnimationFrame(loop);
	
			potreeViewer.update(potreeViewer.clock.getDelta(), timestamp);
	
			potreeViewer.render();
			//console.log(window.toMap)
			if(window.toMap !== undefined){
	
				{
					let camera = potreeViewer.scene.getActiveCamera();
	
					let pPos		= new THREE.Vector3(0, 0, 0).applyMatrix4(camera.matrixWorld);
					let pRight  = new THREE.Vector3(600, 0, 0).applyMatrix4(camera.matrixWorld);
					let pUp		 = new THREE.Vector3(0, 600, 0).applyMatrix4(camera.matrixWorld);
					let pTarget = potreeViewer.scene.view.getPivot();
	
					let toCes = (pos) => {
						let xy = [pos.x, pos.y];
						let height = pos.z;
						let deg = window.toMap.forward(xy);
						let cPos = Cesium.Cartesian3.fromDegrees(...deg, height);
	
						return cPos;
					};
	
					let cPos = toCes(pPos);
					let cUpTarget = toCes(pUp);
					let cTarget = toCes(pTarget);
	
					let cDir = Cesium.Cartesian3.subtract(cTarget, cPos, new Cesium.Cartesian3());
					let cUp = Cesium.Cartesian3.subtract(cUpTarget, cPos, new Cesium.Cartesian3());
	
					cDir = Cesium.Cartesian3.normalize(cDir, new Cesium.Cartesian3());
					cUp = Cesium.Cartesian3.normalize(cUp, new Cesium.Cartesian3());
	
					cesiumViewer.camera.setView({
						destination : cPos,
						orientation : {
							direction : cDir,
							up : cUp
						}
					});
					
				}
	
				let aspect = potreeViewer.scene.getActiveCamera().aspect;
				if(aspect < 1){
					let fovy = Math.PI * (potreeViewer.scene.getActiveCamera().fov / 180);
					cesiumViewer.camera.frustum.fov = fovy;
				}else{
					let fovy = Math.PI * (potreeViewer.scene.getActiveCamera().fov / 180);
					let fovx = Math.atan(Math.tan(0.5 * fovy) * aspect) * 2
					cesiumViewer.camera.frustum.fov = fovx;
				}
				
			}
	
			cesiumViewer.render();
		}
	
		requestAnimationFrame(loop);





    }
	},[window])

	
	useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.POINTSCLOUD,
			{
				limit: 999999,
				
			},
		),
		null,
		( obj ) =>{ 
			
			//Check if the potree is displayed in mission page then we get project id from props :)
				//console.log(projectId,typeof projectId)
				if( typeof projectId == 'number'){
					//It mean we are in the full projects list vue so we return all data for the client
					setPointcloudArray(obj.data.results)
				} else{
							if(projectId.includes('IdFromProject')){
								//console.log(projectId)
									//REduce values
									var newArray = obj.data.results.filter(function (el)
									{
										return el.projectid == parseInt(projectId.split(':')[1])
									}
									);
									setPointcloudArray(newArray)
							} else {
								//console.log(projectId)
										if(id == undefined){
											setPointcloudArray(obj.data.results)
										}else {
											//REduce values
											var newArray = obj.data.results.filter(function (el)
											{
											return el.projectid == id
											}
											);
											setPointcloudArray(newArray)
										}
							}
				}
				

			
			
		
		},
	);

	//DIsplay layer switcher if not displayed by default since we edit the menu in the home page
		useEffect(async () => {

			//Get all 3D layer and store them in a variable

			const getElementByIdAsync = id => new Promise(resolve => {
				const getElement = () => {
				const element = document.getElementById(id);
				if(element) {
					resolve(element);
				} else {
					requestAnimationFrame(getElement);
				}
				};
				getElement();
			});


			


		const divElement = await getElementByIdAsync('potreeLayerswitcher');
				//console.log(divElement.getElementsByTagName('li'))


		if(divElement.getElementsByTagName('li').length > 0){
			Array.from(divElement.getElementsByTagName('li')).map((li) => {
				li.style.display = '';
				
			})
			}        
		//console.log(divElement)
		//if (props.something1 && props.something2) {
			// ..do something with divElement's width
		//}
		
			
			
		},[])

    return (
		<div className="potree_container"  style={{position: "absolute", width: "100%", height: "100%", left: "0px", top: "0px"}} >
		
			<div id="potree_render_area" ref={potreeContainerDiv} style={{backgroundImage: "url('./build/potree/resources/images/background.jpg')"}}>
				<div id="cesiumContainer" style={{position: "absolute", width: "100%", height: "100%", backgroundColor:"green"}}></div>
				
			</div>
			<div id="potree_sidebar_container" ></div>
			
			{/*<div style={{zIndex: "1",position: "relative",left: "40%"}}>
				<h3 id="menu_scene"><span data-i18n="tb.scene_opt"></span></h3>
					<div class="pv-menu-list">

						<div id="scene_export"></div>

						<div class="divider"><span>Objects</span></div>

						<div id="scene_objects"></div>

						<div class="divider"><span>Properties</span></div>

						<div id="scene_object_properties"></div>
					</div>
			</div>*/
			}
			
			
		</div>


	
    )
}

export default Potree;
	


