import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { QueryOptions } from './interfaces';

export const isEmpty = (value: any): boolean =>
	!value ||
	value === 0 ||
	value === '' ||
	(Array.isArray(value) && value.length === 0) ||
	(Object.getPrototypeOf(value) === Object.prototype &&
		Object.keys(value).length === 0);

/**
 * Accepts an object and returns a trimmed version of it with all empty and falsy values removed
 * @param object - Any object potentially containing empty or falsy values
 * @returns Trimmed object with all key-value pairs with empty values removed
 */
export const trimEmptyValues = (object: Object): Object => {
	let trimmedObj = {};

	Object.keys(object).forEach((key) => {
		if (!isEmpty(object[key])) trimmedObj[key] = object[key];
	});

	return trimmedObj;
};
/**
 * Accepts a baseUrl and an object and returns a full URL with the object serialized into query-params.
 * @param baseUrl - The base path of the page
 * @param queryParams - An object containing key-value pairs that need to be passed as query-params
 * @param options - Accepts argument trimEmpty to trim empty and falsy values
 * @returns The full path with the query-params appended to the baseUrl
 */
export const generateQueryString = (
	baseUrl: string,
	queryParams: QueryOptions = {},
	options: { trimEmpty?: boolean; debugMode?: boolean } = {
		trimEmpty: true,
		debugMode: false,
	},
) => {
	const processedQueryParams = options.trimEmpty
		? trimEmptyValues(queryParams)
		: queryParams;

	options.debugMode && console.log({ processedQueryParams });
	return `${baseUrl}?${queryString.stringify(processedQueryParams)}`;
};

export const scrollToTop = (element?: HTMLElement) => {
	(element ?? window).scrollTo({ top: 0, behavior: 'smooth' });
};

export const getCookie = (name: string) => {
	const cookies = new Cookies();
	try {
		return cookies.get(name);
	} catch (error) {
		return '';
	}
};

export const setCookie = (name: string, value: any) => {
	const cookies = new Cookies();
	cookies.set(name, value, {
		path: '/',
		httpOnly: false,
	});
};

export const removeCookie = (name: string, path?: string) => {
	const cookies = new Cookies();
	cookies.remove(name, {
		path: '/',
	});
};

export const removeAllCookies = () => {
	const cookies = new Cookies();
	cookies.remove('refresh_token', { path: '/' });
	cookies.remove('access_token', { path: '/' });
};

export const getDate = (date: string) => {
	return new Date(date).toLocaleDateString(localStorage.getItem('i18nextLng'), {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	});
};

export const getClientAPIUrl = (subdomain) => {
	return `${
		process.env.REACT_APP_API.split('/api/v1')[0]
	}/clients/${subdomain}/api/v1`;
};

export const getRemainingDays = (date) => {
	const futureDate = new Date(date);
	const today = new Date();
	const oneDay = 1000 * 60 * 60 * 24;
	const days = Math.ceil((futureDate.getTime() - today.getTime()) / oneDay);

	return days;
};
