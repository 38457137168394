import { Routes, Route, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from './store';
import { PAGES } from './shared/routes';
import Navigator from './components/Navigator';
import Login from './components/Login';
import Loader from './components/Loader';
import Error from './components/Error';
import ResetPassword from './components/ResetPassword';
import ChangePassword from './components/ChangePassword';
import Account from './components/Account';
import Users from './components/Users';
import Projects from './components/Projects';
import NewUpdateProject from './components/Projects/NewUpdate';
import ProjectDetails from './components/Projects/Details';
import Employees from './components/Employees';
import Clients from './components/Clients';
import NewUpdateClient from './components/Clients/NewUpdate';
import NewUpdateMission from './components/Missions/NewUpdate';
import MissionDetails from './components/Missions/Details';
import Maps from './components/Maps';
import Header from './components/Navigator/Header';
import Sidebar from './components/Navigator/Sidebar';
import Wrapper from './components/shared/Wrapper';
import Powerlines from './components/Powerlines';

export default function () {
	const { state, isOpen, onOpen, onClose } = useStore();
	const { t } = useTranslation();

	return (
		<>
			{state.isAuthLoading ? (
				<Loader />
			) : (
				<Routes>
					<Route
						path={PAGES.HOME}
						element={
							<Navigator>
								<Outlet />
							</Navigator>
						}
					>
						{/* <Route index element={<Dashboard />} /> */}
						<Route path={PAGES.ACCOUNT} element={<Account />} />
						<Route path={PAGES.EMPLOYEES} element={<Employees />} />
						<Route path={PAGES.CLIENTS} element={<Clients />} />
						<Route
							path={PAGES.CLIENTS + '/new'}
							element={<NewUpdateClient />}
						/>
						<Route
							path={PAGES.CLIENTS + '/:id/edit'}
							element={<NewUpdateClient />}
						/>
						<Route path={PAGES.PROJECTS} element={<Projects />} />
						<Route
							path={PAGES.PROJECTS + '/new'}
							element={<NewUpdateProject />}
						/>
						<Route
							path={PAGES.PROJECTS + '/:id'}
							element={<ProjectDetails />}
						/>
						<Route
							path={PAGES.PROJECTS + '/:id/edit'}
							element={<NewUpdateProject />}
						/>
						<Route
							path={PAGES.MISSIONS + '/new'}
							element={<NewUpdateMission />}
						/>
						<Route
							path={PAGES.MISSIONS + '/:id'}
							element={<MissionDetails />}
						/>
						<Route
							path={PAGES.POWERLINES + '/:id'}
							element={<Powerlines />}
						/>
						<Route
							path={PAGES.MISSIONS + '/:id/edit'}
							element={<NewUpdateMission />}
						/>
						<Route path={PAGES.CHANGE_PASSWORD} element={<ChangePassword />} />
						<Route path={PAGES.USERS + '/:id'} element={<Users />} />
					</Route>
					<Route
						index
						element={
							<Wrapper isNavigator showOverlay>
								<Header   isOpen={isOpen} />
								<Sidebar isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
								{/* <Container
										m="0"
										mt="7.1875rem"
										ml={{ lg: '21.875rem', base: 0 }}
										px="0"
										maxW={{ lg: 'calc(100% - 21.875rem)', base: '100%' }}
										w="100%"
									>
										{children}
									</Container> */}
								<Maps />
							</Wrapper>
						}
					/>
					<Route path={PAGES.LOGIN} element={<Login />} />
					<Route path={PAGES.RESET_PASSWORD} element={<ResetPassword />} />
					<Route
						path={PAGES.NOT_AUTHORIZED}
						element={
							<Error
								title={t('notAuthorized.Title')}
								subtitle={t('notAuthorized.Description')}
							/>
						}
					/>
					<Route
						path={PAGES.NOT_FOUND}
						element={
							<Error
								title={t('notFound.Title')}
								subtitle={t('notFound.Description')}
							/>
						}
					/>
					<Route
						path="*"
						element={
							<Error
								title={t('notFound.Title')}
								subtitle={t('notFound.Description')}
							/>
						}
					/>
				</Routes>
			)}
		</>
	);
}
