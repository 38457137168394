import {
	Button,
	useDisclosure,
	useToast,
	Box,
	VStack,
	Link,
	Checkbox,
	FormControl,
	HStack,
	Icon,
	Input,
	InputGroup,
	Flex,
	Switch,
	Text,
	useBreakpointValue,
} from '@chakra-ui/react';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import api from '../../shared/api';
import { API, PAGES } from '../../shared/routes';
import CustomInput from '../shared/Input';
import Title from '../shared/Title';
import * as Yup from 'yup';
import { useFetch } from '../../shared/hooks';
import { generateQueryString, getClientAPIUrl } from '../../shared/helpers';
import { useEffect, useRef, useState } from 'react';
import Dropdown from '../shared/Dropdown';
import 'react-multi-email/style.css';
import {
	DarkGreen,
	MapPriorityColor,
	MissionTypes,
	Roles,
	NewMissionPaginationOptions,
	Status,
} from '../../shared/enums';
import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';
import ConfirmDelete from '../shared/ConfirmDelete';
import Map from '../Maps/Mission';
import CustomTable from '../shared/Table';
import NewMissionPaginator from '../shared/Paginator/NewMission';
import {
	averageGeolocation,
	getBounds,
	getBoundsZoomLevel,
} from '../../models/map';
// import { FiSearch } from 'react-icons/fi';
import { BsCheck, BsCheckAll,BsEye,BsFillCircleFill } from 'react-icons/bs';
import { MdArrowBackIosNew } from 'react-icons/md';

import MultiEmail from '../shared/MultiEmail';
import ConfirmSendEmail from '../shared/ConfirmSendEmail';
import { getRemainingDays } from '../../shared/helpers';

interface Powerline {
	properties: any;
	geometry: any;
}

interface Assignee {
	id: number;
}
function NewUpdateMission() {
	const searchElement = useRef<HTMLInputElement>(null);
	const [refresh, setRefresh] = useState(0);
	const [deleting, setDeleting] = useState(false);
	const [geoData, setGeoData] = useState([]);
	const [sendEmail, setSendEmail] = useState(false);
	const [sendNotificationEmail, setSendNotificationEmail] = useState(false);
	const [map, setMap] = useState(null);
	const [powerlines, setPowerlines] = useState([]);
	const [users, setUsers] = useState([]);
	const [owners, setOwners] = useState([]);
	const [selectedFeatures, setSelectedFeatures] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [updateIsOpen, setUpdateIsOpen] = useState(false);
	const [sort, setSort] = useState(null);
	const [isUpdate, setIsUpdate] = useState(false);
	const { isAuthorized, state } = useStore();
	const [searchParams] = useSearchParams();
	const [pagination, setPagination] = useState(NewMissionPaginationOptions);
	const { id } = useParams();
	const navigate = useNavigate();
	const isEditMode = id !== 'new' && id !== undefined;
	const toast = useToast();
	const { t } = useTranslation();
	const client = searchParams.get('client');
	const project = searchParams.get('project');
	const isDronodat = isAuthorized([Roles.ADMIN]) && client;
	const isLargeScreen = useBreakpointValue({ xl: true });	

	const [remainingDays, setRemainingDays] = useState(0);
	useEffect(() => {
		if (!isAuthorized([Roles.CLIENT_ADMIN, Roles.ADMIN]) && state.user.groups)
			navigate(PAGES.NOT_AUTHORIZED);
		if (isAuthorized([Roles.ADMIN]) && state.user.groups && !client)
			navigate(PAGES.NOT_FOUND);
		if (!project && !isEditMode) navigate(PAGES.NOT_FOUND);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.user]);

	function customSort(name?: string, order?: string) {
		setSort(`${order}${name}`);
	}

	

	const { data: mission, isError }: any = useFetch(
		(isDronodat ? getClientAPIUrl(client) : '') + API.get.MISSIONS + id + '/',
		!isEditMode || !state.user.groups,
		({ data }: any) => {
			setSelectedFeatures([...data.geo_2d_locations, ...data.geo_3d_locations]);
			// console.log(data.geo_2d_locations);
			setOwners(data.owners);
			setSendNotificationEmail(data.send_notification_email);
			const days = getRemainingDays(data.started_on);

			days > 14 ? setRemainingDays(days - 14) : setRemainingDays(days);
		},
	);
	// console.log( mission)
	let { data: powerlinesData, loading: powerlinesLoading }: any = useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.MAPS_POWERLINES,
			{
				project: project || mission?.project,
				limit: pagination.limit,
				offset: pagination.offset,
				order_by: sort,
				is_assigned: isEditMode ? "" : "false"
			},
		),
		!state.user.groups,
		({ data }: any) => {
			setPagination({
				...pagination,
				total: data.count,
			});
		},
		[refresh],
	);

	useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.ALL_USERS,
			{
				limit: 1000,
			},
		),
		null,
		({ data }: any) => {
			setUsers(data.results);
		},
	);
	if (isError && isEditMode) navigate(PAGES.NOT_FOUND);
	const initialValues = {
		name: '',
		project,
		assignees: [],
		type: 1,
		started_on: '',
		finished_on: '',
		...(isEditMode &&
			mission !== null && {
				...mission,
				started_on: mission.started_on ? mission.started_on.split('T')[0] : '',
				finished_on: mission.finished_on
					? mission.finished_on.split('T')[0]
					: '',
				owners: owners ? owners : [],
				status: { value: mission.status, label: t(Status[mission.status]) },
				name: mission.name,
				type: {
					label: t(`missions.${MissionTypes[mission.type]}`),
					value: mission.type,
				},
				assignees: users
					? mission.assignees.map((assignee: Assignee) => {
							return {
								value: assignee.id,
								label: users.find((user) => user.id === assignee.id)?.email,
							};
					  })
					: '',
			}),
	};

	useEffect(() => {
		setPowerlines(
			powerlinesData?.results?.features?.map((powerline: Powerline) => {
				return {
					id: powerline.properties.id,
					length: (Number(powerline.properties.Length)/1000 ).toFixed(3)+ ' km',
					area:
						Number(
							powerline.properties.intersecting_data.area +
								(powerline.properties.surrounding_data.area ?? 0),
						).toFixed(2) + " m²",
					priority: <Icon as={BsFillCircleFill} color={MapPriorityColor[powerline.properties.PRIORITY]}/>  , //{powerline.properties.PRIORITY}
					location: (
						<Link
							onClick={() => {
								const center = averageGeolocation(
									powerline.geometry.coordinates.map((coord: number[]) => {
										return { lat: coord[1], lng: coord[0] };
									}),
								);
								const bounds = getBounds(
									powerline.geometry.coordinates.map((coord: number[]) => {
										return { lat: coord[1], lng: coord[0] };
									}),
								);
								const { clientHeight, clientWidth } =
									document.getElementById('mapContainer');
								const newZoom = getBoundsZoomLevel(bounds, {
									width: clientWidth,
									height: clientHeight,
								});
								map.flyTo({
									center: center,
									zoom: newZoom,
								});
								map.setFilter(
									`goTo-LineString - ${powerline.properties.PRIORITY}`,
									['in', 'DBID', powerline.properties.id],
								);
							}}
							textDecor="underline"
						>	
						<Icon
							as={BsEye}>
						</Icon>
							{/* {t('GoToLocation')} */}
						</Link>
					),
					checkbox: (
						<HStack>
							<Checkbox
								colorScheme="gray"
								// isChecked={false}
								isChecked={selectedFeatures.includes(powerline.properties.id)}
								onChange={(e) => {
									const filterString = `highlighted-LineString - ${powerline.properties.PRIORITY}`;
									const currentFilter = map.getFilter(filterString);
									if (e.target.checked) {
										setSelectedFeatures([...selectedFeatures, powerline.properties.id]);
										map.setFilter(filterString, [...currentFilter, powerline.properties.id]);
									} else {
										setSelectedFeatures(selectedFeatures.filter((id) => id !== powerline.properties.id));
										map.setFilter(
											filterString,
											currentFilter.filter((feature) => feature !== powerline.properties.id),
										);
									}
									}
								}

							/>
							<Icon as={BsFillCircleFill} color={MapPriorityColor[powerline.properties.PRIORITY]}/> 
						</HStack>
					),
				};
			}),
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [powerlinesData, selectedFeatures]);


	// document.getElementById("checkbox").checked = true;
	return (
		<>
		<HStack>
			<Icon //Back icon
				cursor="pointer"
				color={DarkGreen.iconColor}
				onClick={() =>
					navigate(
						`${PAGES.MISSIONS}/${mission.id}${
							isDronodat ? `?client=${client}` : ''
						}`,
							)
						}
						// pt="20px"
						w="3rem"
						h="3rem"
						ml={{lg:"6rem",base:"2rem"}}
						my="0"
						// pt="0.4rem"
						top="0"
						as={MdArrowBackIosNew}
			/>
			<Title
				data-aos="fade-down"
				title={
					isEditMode
						? `${t('missions.edit.Title')} ${id}`
						: t('missions.new.Title')
				}
				px={{ md: '4rem', base: '2rem' }}
			/>
		</HStack>

			<HStack 
				// paddingInlineEnd={{ md: '0', base: '2rem' }}
				data-aos="fade-down"
				flexDir= {{ xl: 'row', base: 'column' }}
				align="flex-start" 
				// gap="0.5rem" 
				pb="4rem" 
				mx={{xl: "6rem", md : "6rem",base:"0rem"}}  
				pr={{xxl: "2rem", md : "6rem",base:"0rem"}}
				// minW={{xxl: "2rem",md:"6rem", base : "0rem"}} //
				>
				<Box //  Map And  Info Box Box
					order= {{xl: "2", base : "0"}} 
					w="100%"
					overflowX="visible"
					h="60vh"
					ml={{xl: "3rem",md:"1rem", base : "0rem"}}
					mt="1rem"
					boxShadow="0px 0px 10px #000000"
					>
					{(project || mission?.project) && (
						<Map
							onSelect={(selected) => setSelectedFeatures([...selected])}
							isDronodat={isDronodat}
							missionId={isEditMode ? '' : id} ///
							projectId={project || mission?.project}
							selectedFeatures={selectedFeatures}
							getMap={(map) => {
								setMap(map);
							}}
							onGeoDataChange={(data) => {
								setGeoData(data);
							}}
						/>
					)}
				</Box>
				

			
				<VStack // Tabel
					order= {{xl: "0", base : "1"}}
					align="flex-start"
					w="100%"
					// minW={{xxl: "400px", base: "400px"}}
					maxW={{xl: "400px",base:"100%"}}
					pl={{xxl: "0rem", base: "1rem"}}
					>
						<VStack //Tabel and Encrochments number 

							>
							<Formik
								initialValues={{}}
								onSubmit={async (e) => {
									if (searchElement.current?.value.length === 0) return;
									setPagination({
										...pagination,
										offset: 0,
										currentPage: 1,
									});
									try {
										const { data: powerline } = await api.get(
											(isDronodat ? getClientAPIUrl(client) : '') +
												API.get.MAPS_POWERLINES +
												searchElement.current?.value +
												'/',
										);
										powerlinesData.results.features = [powerline];
										
										setPowerlines([
											{
												id: powerline.properties.id,
												length: (Number(powerline.properties.Length)/5 )+ ' km',
												area:
													Number(
														powerline.properties.intersecting_data.area +
															(powerline.properties.surrounding_data.area ?? 0),
													).toFixed(2) + '㎡',
												
												priority: powerline.properties.PRIORITY,
												location: (
													<Link
														onClick={() => {
															const center = averageGeolocation(
																powerline.geometry.coordinates.map((coord) => {
																	return { lat: coord[1], lng: coord[0] };
																}),
															);
															const bounds = getBounds(
																powerline.geometry.coordinates.map((coord) => {
																	return { lat: coord[1], lng: coord[0] };
																}),
															);
															const { clientHeight, clientWidth } =
																document.getElementById('mapContainer');
															const newZoom = getBoundsZoomLevel(bounds, {
																width: clientWidth,
																height: clientHeight,
															});
															map.flyTo({
																center: center,
																zoom: newZoom,
															});
															map.setFilter(
																`goTo-LineString - ${powerline.properties.PRIORITY}`,
																['in', 'DBID', powerline.properties.id],
															);
														}}
														textDecor="underline"
													>
													<Icon
														as={BsEye}>
													</Icon>
														{/* {t('GoToLocation')} */}
													</Link>
												),
												checkbox: (
													<Checkbox
														colorScheme="gray"
														// isChecked={false}
														isChecked={selectedFeatures.includes(powerline.properties.id,)}
														onChange={(e) => {
															const filterString = `highlighted-LineString - ${powerline.properties.PRIORITY}`;
															const currentFilter = map.getFilter(filterString);
															if (e.target.checked) {
																setSelectedFeatures([...selectedFeatures, powerline.properties.id]);
																map.setFilter(filterString, [...currentFilter, powerline.properties.id]);
															} else {
																setSelectedFeatures(selectedFeatures.filter((id) => id !== powerline.properties.id));
																map.setFilter(
																	filterString,
																	currentFilter.filter((feature) => feature !== powerline.properties.id),
																);
															}
															}
														}
													/>
												),
											},
										]);
									} catch {
										setPowerlines([]);
									}
								}}
							>
								<Form
									style={{
										display: 'flex',
									}}
								>
									<FormControl 
											display="flex" 
											gap="1rem" 
											alignItems="flex-start" 
											mt="1rem"
										>
										<InputGroup  >
											<Input
												minW={{xl:"420px",base:"300px"}}
												bg="transparent"
												borderColor={DarkGreen.tableBorderColor}
												color="white"
												borderRadius="0.25rem"
												placeholder={t('Search')}
												_placeholder={{
													color: "white"
												}}
												textColor="white"
												type="text"
												size="md"
												onChange={(e) => {
													if (e.target.value.length === 0) {
														setRefresh(refresh + 1);
													}
												}}
												ref={searchElement}
												/>

										</InputGroup>
									</FormControl>
								</Form>

							</Formik>
						</VStack>
					<Box  //tabel
					// minW={{lg: "420px",md: "600px", base: "300px"}}
					w="100%"

						mt="1rem"
						overflowX="auto" 
						h="100%"
						maxH={{lg: "700px", base: "300px"}}
						borderBottom={"1px solid"}
						borderBottomColor= {DarkGreen.tableBorderColor}
						borderBottomRadius={"20px"}
						
						css={{
							'&::-webkit-scrollbar': {
								width: '0px',
								background: 'transparent'
							},
							'&::-webkit-scrollbar-thumb': {
								display: 'none'
							},
							'&::-webkit-scrollbar-track': {
								display: 'none'
							},
							'-ms-overflow-style': 'none', /* IE and Edge */
							'scrollbar-width': 'none' /* Firefox */
							}}
					>
						<CustomTable
							min_width = "100px"
							// minW="100%"
							columns={isLargeScreen? [
								{
									title: t(''),
									accessor: 'checkbox',
									padding:"1rem",
									paddingY:"2rem",
									tdMaxWidth:"60px",
									// sortable:true,

								},
								{
									title: t('ID'),
									accessor: 'id',
									sortable: true,
									variableName: 'string_id',
									padding:"0.5rem",
									paddingY:"0.1rem",
									tdMaxWidth:"50px",

								},
								{
									title: t('Area'),
									accessor: 'area',
									sortable: true,
									variableName: 'area',
									padding:"0.5rem",
									paddingY:"0.1rem",
									tdMaxWidth:"100px",

								},
								{
									title: t('Location'),
									accessor: 'location',
									// padding:"0.8rem",
									tdMaxWidth:"50px",
									paddingY:"0.1rem",


								},
							]:[
								{
									title: t(''),
									accessor: 'checkbox',
									padding:"1rem",
									paddingY:"2rem",
									tdMaxWidth:"60px",
									// sortable:true,

								},

								{
									title: t('ID'),
									accessor: 'id',
									sortable: true,
									variableName: 'string_id',
									padding:"0.5rem",
									paddingY:"0.1rem",
									// tdMaxWidth:"50px",

								},

								// {
								// 	title: t('L'),
								// 	accessor: 'length',
								// 	sortable: false,
								// 	variableName: 'length',
								// 	padding:"0.5rem",
								// 	paddingY:"0.1rem",
								// 	tdMaxWidth:"100px",

								// },
								{
									title: t('Area'),
									accessor: 'area',
									sortable: true,
									variableName: 'area',
									padding:"0.5rem",
									paddingY:"0.1rem",
									// tdMaxWidth:"100px",

								},
								{
									title: t('Location'),
									accessor: 'location',
									// padding:"0.8rem",
									tdMaxWidth:"4rem",
									paddingY:"0.1rem",


								},
							]
						}
							data={powerlines}
							isLoading={powerlinesLoading}
							customSort={customSort}
						/>
					</Box>
					<NewMissionPaginator
						isLoading={powerlinesLoading}
						total={pagination.total}
						onPageChange={(number: number) => {
							setPagination({
								...pagination,
								currentPage: number,
								offset: (number - 1) * pagination.limit,
							});
						}}
						onPerPageChange={(number: number) => {
							setPagination({
								...pagination,
								limit: number,
								offset: 0,
								currentPage: 1,
							});
						}}
						currentPage={pagination.currentPage}
					/>
				</VStack>
								

			</HStack>
				<Box 
					order= {{lg: "3", base : "3"}} 
					minW={"400px"}
					mx={{xl:"6rem",md:"4rem",base:"1rem"}}
					flexDir={{ base: 'column', xl: 'column' }} 
					border="1px solid" 
					borderColor={DarkGreen.tableBorderColor}
					borderRadius="20px"
					mt="2rem"
					>
					<Formik // info box
						enableReinitialize
						initialValues={initialValues}
						validationSchema={Yup.object({
							name: Yup.string().required(),
							project: Yup.number().required(),
							assignees: Yup.array().required().min(1),
							owners: Yup.array().optional(),
							type: Yup.object().required(),
							started_on: Yup.date().optional(),
							finished_on: Yup.date().optional(),
							status: Yup.object().optional(),
						})}
						onSubmit={async (values: any, actions) => {
							try {
								values = {
									...values,
									assignees: values.assignees.map((user) => user.value),
									type: values.type.value,
									send_email: sendEmail,
									send_notification_email: sendNotificationEmail,
									owners: owners ? owners : [],
									status: isEditMode ? values.status.value : 1,
									project: isEditMode ? values.project : project,
									started_on: values.started_on ? values.started_on : null,
									finished_on: values.finished_on ? values.finished_on : null,
									geo_2d_locations: selectedFeatures.filter(
										(feature) => {
											const geo = geoData.find((geo) => geo.properties.id === feature)
											if (geo) {
												return geo["2d"];
											}
											return false;
										}
									),
									geo_3d_locations: selectedFeatures.filter(
										(feature) => {
											const geo = geoData.find((geo) => geo.properties.id === feature)
											if (geo) {
												return !geo["2d"];
											}
											return false;
										}
									),
								};
								if (isEditMode) {
									await api.put(
										`${isDronodat ? getClientAPIUrl(client) : ''}${
											API.put.MISSIONS
										}${id}/`,
										values,
									);
									toast({
										title: t('missions.edit.toast.Title'),
										description: t('missions.edit.toast.Description'),
										status: 'success',
										duration: 3000,
										isClosable: true,
										position: 'bottom-right',
									});
									navigate(
										PAGES.MISSIONS +
											'/' +
											id +
											(isDronodat ? `?client=${client}` : ''),
									);
								} else {
									const res = await api.post(
										`${isDronodat ? getClientAPIUrl(client) : ''}${
											API.post.MISSIONS
										}`,
										values,
									);
									toast({
										title: t('missions.new.toast.Title'),
										description: t('missions.new.toast.Description'),
										status: 'success',
										duration: 3000,
										isClosable: true,
										position: 'bottom-right',
									});
									navigate(
										PAGES.MISSIONS +
											'/' +
											res.data.id +
											(isDronodat ? `?client=${client}` : ''),
									);
								}
							} catch (e) {
								console.log(e);
								const errors = e.response.data;
								
								if (errors.geo_2d_locations) {
									toast({
										title: t('Error'),
										description: errors.geo_2d_locations?.pop(),
										status: 'error',
										duration: 3000,
										isClosable: true,
										position: 'bottom-right',
									});
									actions.setSubmitting(false);

									return;
								}
								if (errors.geo_3d_locations) {
									toast({
										title: t('Error'),
										description: errors.geo_3d_locations?.pop(),
										status: 'error',
										duration: 3000,
										isClosable: true,
										position: 'bottom-right',
									});
									actions.setSubmitting(false);

									return;
								}
								toast({
									title: t('Error'),
									description: t('TryAgain'),
									status: 'error',
									duration: 3000,
									isClosable: true,
									position: 'bottom-right',
								});

								actions.setErrors({
									name: errors.name?.pop(),
									status: errors.status?.pop(),
									assignees: errors.assignees?.pop(),
									type: errors.type?.pop(),
								});
							}
							actions.setSubmitting(false);
						}}
						
					>

						{(props) => (

							<Form  style={{ order: 6 }}	 >
									<VStack 
									maxH={"500px"}
									pb="100px"
									mb="6rem"
									>
										<Text  left={0}>
											{/* {t("Details")} */}
										</Text>
										<Field name="name"  >
											
											{({ field, form }) => (
												<CustomInput
													label={t('Name')}
													type={'text'}
													error={form.errors.name}
													touched={form.touched.name}
													field={field}
												/>
											)}
										</Field>
										<Field name="assignees" width="100%" >
											{({ field, form }) => (
												<Dropdown
													errors={form.errors.assignees}
													touched={form.touched.assignees}
													label={t('Assignees')}
													placeholder={t('SelectPerson')}
													options={users.map((user) => ({
														label: user.email,
														value: user.id,
													}))}
													onChange={(list) => {
														form.setFieldValue('assignees', list);
													}}
													isMulti
													values={field.value}
												/>
											)}
										</Field>

										<Field name="type" width="100%" >
											{({ field, form }) => (
												<Dropdown
													errors={form.errors.type}
													touched={form.touched.type}
													label={t('missions.MissionType')}
													placeholder={t('missions.SelectMissionType')}
													options={[
														{
															label: t('missions.Flight'),
															value: 1,
														},
														{
															label: t('missions.Trimming'),
															value: 2,
														},
													]}
													onChange={(value) => {
														form.setFieldValue('type', value);
													}}
													values={field.value}
												/>
											)}
										</Field>
										<Field name="started_on" width="100%" >
											{({ field, form }) => (
												<CustomInput
													label={t('projects.PunchIn')}
													type={'date'}
													error={form.errors.started_on}
													touched={form.touched.started_on}
													field={field}
												/>
											)}
										</Field>
										<Field name="finished_on" width="100%" >
											{({ field, form }) => (
												<CustomInput
													label={t('projects.PunchOut')}
													type={'date'}
													error={form.errors.finished_on}
													touched={form.touched.finished_on}
													field={field}
												/>
											)}
										</Field>
										{isEditMode && (
											<Field name="status">
												{({ field, form }) => (
													<Dropdown
														errors={form.errors.status}
														touched={form.touched.status}
														label={t('Status')}
														placeholder={''}
														options={Object.keys(Status).map((key) => ({
															value: key,
															label: t(Status[key]),
														}))}
														onChange={(value: any) => {
															form.setFieldValue('status', value);
														}}
														values={field.value}
													/>
												)}
											</Field>
										)}
										<Box flexDir={'row'} width={'100%'} mt={5} >
											<Field name="owners" width="100%"  >
												{({ field, form }) => (
													<MultiEmail
														
														errors={form.errors.assignees}
														touched={form.touched.assignees}
														label={t('Owners')}
														placeholder={t('EnterEmails')}
														options={users.map((user) => ({
															label: user.email,
															value: user.id,
														}))}
														onChange={(list) => {
															form.setFieldValue('assignees', list);
														}}
														values={owners}
														setValues={setOwners}
													/>
												)}
											</Field>
											<Flex
												mt={-4}
												mb={5}
												ml={24}
												flexDir={'row'}
												// width={'85%'}
												justifyItems={'flex-start'}
											>
												<Text mt={1}>Send Email</Text>
												<Switch
													ml={2}
													colorScheme={'teal'}
													size={'lg'}
													isChecked={sendNotificationEmail}
													onChange={() => {
														setSendNotificationEmail(!sendNotificationEmail);
													}}
												/>
												<Box width={'27%'} />
												<Box ml={-10} mt={1}>
													{mission?.is_notification_sent &&
													mission?.is_reminder_sent ? (
														<Flex flexDir={'row'}>
															<Icon
																as={BsCheckAll}
																w={6}
																h={6}
																color={DarkGreen.iconColor}
															/>
															<Text ml={1}>
																Notification and Reminder emails have been sent
															</Text>
														</Flex>
													) : mission?.is_reminder_sent ? (
														mission?.is_notification_sent && (
															<Flex flexDir={'row'}>
																<Icon as={BsCheck} w={6} h={6} color={DarkGreen.iconColor} />
																<Text ml={1}>
																	Reminder email will be sent in{' '}
																	{remainingDays > 0 ? remainingDays : 0} day/s
																</Text>
															</Flex>
														)
													) : (
														<Text minW={"170px"} overflow="visible" ml="4rem" mr="2rem">
															Notification email will be sent in{' '}
															{remainingDays > 0 ? remainingDays : 0} day/s
														</Text>
													)}
												</Box>
											</Flex>
										</Box>


									</VStack>
									<VStack // info box buttons
										pb={"1rem"}
										// pt={{ md: '2rem', base: '5rem' }}

										m={{ md: '2rem !important', base: '1rem' }}
										overflowX="visible"
										order={{ md: 1, base: 2}}
									>
										{isEditMode ? (
											<>
												<HStack 
													justifyContent= "flex-end"
													flexDir={{ lg: 'row', base: 'row' }} 
													// maxW="300px" 
													mt={{ xl: '10rem', base: '13rem' }} 
													overflowX="visible"
													>
													<Button
														background="transparent"
														borderRadius={'0.25rem'}
														borderWidth="2px"
														borderColor={DarkGreen.ButtonHighlightColor}
														my="1rem"
														mx="0.5rem"
														color="white"
														minW={{ xl: '300px', base: '100px' }}
														order="9"
														_hover={{
															color: 'white',
															background: DarkGreen.ButtonHighlightColor,
															borderColor:"white",
														}}

														isLoading={props.isSubmitting}
														onClick={() => {
															owners && owners.length
																? setUpdateIsOpen(true)
																: props.submitForm();
															setIsUpdate(true);
														}}
													>
														{t('Update')}

													</Button>
													<ConfirmSendEmail
														isOpen={updateIsOpen}
														onClose={() => setUpdateIsOpen(false)}
														onConfirm={(send: boolean) => {
															setSendEmail(send);
															isUpdate ? props.submitForm() : onOpen();
															setUpdateIsOpen(false);
														}}
													/>
													<Button
														background="transparent"
														borderRadius={'0.25rem'}
														borderWidth="2px"
														borderColor={DarkGreen.ButtonBorderColor}
														color="white"
														my="2rem"
														mx="2rem"
														minW={{ xl: '300px', base: '100px' }}
														_hover={{
															color: "white",
															// background: DarkGreen.iconColor,
															borderColor:"red",
														}}
														onClick={() => {
															navigate(-1);
														}}
													>
														{t('Cancel')}
													</Button>
													<ConfirmDelete
														onClose={onClose}
														isOpen={isOpen}
														onConfirm={async () => {
															try {
																await api.delete(
																	(isDronodat ? getClientAPIUrl(client) : '') +
																		API.delete.MISSIONS +
																		id +
																		'/',
																	{ data: { send_email: sendEmail } },
																);
																setDeleting(false);
																toast({
																	title: t('missions.delete.toast.Title'),
																	description: t(
																		'missions.delete.toast.Description',
																	),
																	status: 'success',
																	duration: 3000,
																	isClosable: true,
																	position: 'bottom-right',
																});
																navigate(
																	PAGES.PROJECTS +
																		'/' +
																		mission.project +
																		(isDronodat ? `?client=${client}` : ''),
																);
															} catch {
																toast({
																	title: t('Error'),
																	description: t('TryAgain'),
																	status: 'error',
																	duration: 3000,
																	isClosable: true,
																	position: 'bottom-right',
																});
															}
														}}
													/>
													<Button
													background="transparent"
													borderRadius={'0.25rem'}
													borderWidth="2px"
													borderColor={DarkGreen.ButtonBorderColor}
													color="white"
													my="2rem"
													mx="2rem"
													minW={{ xl: '300px', base: '100px' }}
													// w= "300px"
													_hover={{
														color: "white",
														// background: DarkGreen.iconColor,
														borderColor:"red",

													}}
														isLoading={deleting}
														onClick={() => {
															setDeleting(true);
															setIsUpdate(false);
															setUpdateIsOpen(true);
														}}
													>
														{t('Delete')}
													</Button>
												</HStack>
											</>
										) : (
											<>
												<HStack  
												justifyContent= "flex-end"
												flexDir={{ lg: 'row', base: 'row' }} 
												mt={{ lg: '4rem', base: '5rem' }} 
												overflowX="visible">
												<Button
													// mt={{ lg: '1', base: '1rem' }}
													background="transparent"
													borderRadius={'0.25rem'}
													borderWidth="2px"
													borderColor={DarkGreen.ButtonBorderColor}
													color="white"
													minW={{ lg: '300px', base: '100px' }}
													// w= "300px"
													my="1rem"
													mx="2rem"

													onClick={() => {
														navigate(-1);
													}}
													_hover={{
														color: "white",
														// background: DarkGreen.iconColor,
														borderColor:"red",

													}}
													>
													{t('Cancel')}
												</Button>
												<Button
														background= "transparent"//"transparent linear-gradient(180deg, #00965B 0%, #065688 100%) 0% 0% no-repeat padding-box;"
														borderWidth="2px"
														borderColor={DarkGreen.ButtonHighlightColor}
														my="1rem"
														mx="2rem"
														ml="2.5rem"
														_hover={{
															color: 'white',
															background: DarkGreen.ButtonHighlightColor,
															borderColor:"white",

														}}
														isLoading={props.isSubmitting}
														type="submit"
														borderRadius={'0.25rem'}
														color="white"
														w= "300px"
														maxW={{ lg: '300px', base: '100px' }}
													>
														{t('Create')}
													</Button>
												</HStack>	
											</>
										)}
									</VStack>
						
						
							</Form>
						)}
					</Formik>
				</Box>
				<Box minH={"20px"}></Box>

		</>
	);
}

export default NewUpdateMission;
