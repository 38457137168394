import {
	Avatar,
	Box,
	Text,
	Divider,
	Button,
	HStack,
	SimpleGrid,
	Icon,
	useToast,
	FormControl,
	FormErrorMessage,
	Input,
	Center,
	useDisclosure,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { FaEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { API, PAGES } from '../../shared/routes';
import * as Yup from 'yup';
import {  useRef, useState } from 'react';
import { phoneRegExp } from '../../shared/constants';
import { useStore } from '../../store';
import { useFetch } from '../../shared/hooks';
import { DarkGreen } from '../../shared/enums';
import api from '../../shared/api';
import { RiUploadCloudLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

function Account() {
	const inputFile = useRef(null);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [file, setFile] = useState<any>('');
	const [isEditMode, setIsEditMode] = useState(false);
	const navigate = useNavigate();
	const { state } = useStore();
	const toast = useToast();
	const { t } = useTranslation();

	const {
		data: user,
	}: any = useFetch(API.get.USERS + state?.user.id + '/', !state.user.id);
	// if (isError) navigate(PAGES.NOT_FOUND);

	const initialValues = {
		title: '',
		name: '',
		phone_number: '',
		email: '',
		service: '',
		...user,
	};

	return (
		<Box px="4rem" pb="2rem">
			<Input
				type="file"
				ref={inputFile}
				id="file"
				display="none"
				accept=".jpg,.png,.jpeg"
				onChange={async (e) => {
					const selectedFile = e.target.files[0];
					setFile(selectedFile);
					onClose();
				}}
			/>
			<Modal size="xl" onClose={onClose} isOpen={isOpen} isCentered>
				<ModalOverlay />
				<ModalContent
					borderColor={DarkGreen.ButtonBorderColor}
					borderWidth="2px"
					p="4rem"
					bg="rgba(0,0,0,1)"
				>
					<ModalCloseButton />
					<ModalBody textAlign="center">
						<Icon as={RiUploadCloudLine} w="10.8125rem" h="10.8125rem" />
						<Text fontSize="21px" fontWeight={300}>
							{t('upload.Description')}
						</Text>
						<Button
							mt="4rem"
							background="transparent linear-gradient(180deg, #00965B 0%, #065688 100%) 0% 0% no-repeat padding-box;"
							_hover={{
								background:
									'transparent linear-gradient(180deg, #065688 0%, #00965B 100%) 0% 0% no-repeat padding-box;',
							}}
							type="button"
							borderRadius={'0.25rem'}
							color="white"
							w="full"
							onClick={() => {
								inputFile.current.click();
							}}
						>
							{t('upload.Submit')}
						</Button>
					</ModalBody>
				</ModalContent>
			</Modal>
			<HStack>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={Yup.object().shape({
					name: Yup.string().required(t('Required')),
					title: Yup.string().required(t('Required')),
					email: Yup.string().email(t('Invalid email')).required(t('Required')),
					phone_number: Yup.string()
						.matches(phoneRegExp, t('InvalidPhone'))
						.optional(),
					service: Yup.string().optional(),
				})}
				onSubmit={async (values, actions) => {
					try {
						const formData = new FormData();
						formData.append('name', values.name);
						formData.append('title', values.title);
						formData.append('phone_number', values.phone_number);
						formData.append('service', values.service);
						formData.append('email', values.email);
						if (file) formData.append('avatar', file);
						await api.put(API.put.USERS + state.user.id + '/', formData);

						toast({
							title: t('account.toast.Title'),
							description: t('account.toast.Description'),
							status: 'success',
							duration: 5000,
							isClosable: true,
							position: 'bottom-right',
						});
						setIsEditMode(false);
					} catch {
						toast({
							title: t('Error'),
							description: t('TryAgain'),
							status: 'error',
							duration: 5000,
							isClosable: true,
							position: 'bottom-right',
						});
					}

					actions.setSubmitting(false);
				}}
			>
				{(props) => (
					<Form data-aos="fade-down">
						<Avatar
							name={props.values.name}
							w="13.625rem"
							h="13.625rem"
							bg="white"
							size="lg"
							pos="relative"
							src={file ? URL.createObjectURL(file) : user?.avatar}
							ignoreFallback
						>
							{isEditMode && (
								<Center
									w="100%"
									h="100%"
									pos="absolute"
									bg="rgba(0,0,0,0.6)"
									borderRadius={'50%'}
									cursor="pointer"
									onClick={onOpen}
								>
									<Icon w="50%" h="50%" color="white" as={RiUploadCloudLine} />
								</Center>
							)}
						</Avatar>
						{isEditMode ? (
							<Field name="name">
								{({ field, form }) => (
									<FormControl
										isInvalid={form.errors.name && form.touched.name}
										my="2rem"
									>
										<Input
											bg="transparent"
											color={"white"}
											borderColor={DarkGreen.ButtonBorderColor}
											borderRadius="0.25rem"
											{...field}
											id="name"
											placeholder={t('Name')}
											fontSize="1.5rem"
											w="xs"
										/>
										<FormErrorMessage>{form.errors.name}</FormErrorMessage>
									</FormControl>
								)}
							</Field>
						) : (
							<HStack >
							<Text my="2rem" fontSize="1.5rem">
								{props.values.name}
							</Text>
							
								<HStack 
									onClick={() => {
										setIsEditMode(!isEditMode);
									}}
									cursor="pointer"
									marginInline="auto 0"
								>
									<Icon color={DarkGreen.iconColor} w="2rem" h="2rem" ml="1rem" as={FaEdit} />
									{/* <Text>{t('Edit')}</Text> */}
								</HStack>
							</HStack>
							
						)}
						{/* <Divider
							borderColor="black"
							borderWidth="20px"
							// clipPath="polygon(0 0, 100% 0, 100% 100%, 0 56%);"
							mb="1.375rem"
						/> */}
						<SimpleGrid columns={3} mt="2rem">
							<Text marginBlock="auto" fontSize="1.25rem">
								{t('Title')}
							</Text>
							{isEditMode ? (
								<Field name="title">
									{({ field, form }) => (
										<FormControl
											isInvalid={form.errors.title && form.touched.title}
										>
											<Input
												bg="transparent"
												color={"white"}
												borderColor={DarkGreen.ButtonBorderColor}
												borderRadius="0.25rem"
												{...field}
												id="title"
												placeholder={t('Title')}
												fontSize="1.25rem"
												w="xs"
											/>
											<FormErrorMessage>{form.errors.title}</FormErrorMessage>
										</FormControl>
									)}
								</Field>
							) : (
								<Text marginBlock="auto" fontSize="1.25rem">
									{props.values.title}
								</Text>
							)}
							<Box></Box>
						</SimpleGrid>
						<SimpleGrid columns={3} mt="2rem">
							<Text marginBlock="auto" fontSize="1.25rem">
								{t('Phone')}
							</Text>
							{isEditMode ? (
								<Field name="phone_number">
									{({ field, form }) => (
										<FormControl
											isInvalid={
												form.errors.phone_number && form.touched.phone_number
											}
										>
											<Input
												bg="transparent"
												color={"white"}
												borderColor={DarkGreen.ButtonBorderColor}
												borderRadius="0.25rem"
												{...field}
												id="phone_number"
												placeholder={t('Phone')}
												_placeholder={{color:"white"}}
												fontSize="1.25rem"
												w="xs"
											/>
											<FormErrorMessage>
												{form.errors.phone_number}
											</FormErrorMessage>
										</FormControl>
									)}
								</Field>
							) : (
								<Text marginBlock="auto" fontSize="1.25rem">
									{props.values.phone_number}
								</Text>
							)}
							<Box></Box>
						</SimpleGrid>
						<SimpleGrid columns={3} mt="2rem">
							<Text marginBlock="auto" fontSize="1.25rem">
								{t('Email')}
							</Text>
							{isEditMode ? (
								<Field name="email">
									{({ field, form }) => (
										<FormControl
											isInvalid={form.errors.email && form.touched.email}
										>
											<Input
												bg="transparent"
												color={"white"}
												borderColor={DarkGreen.ButtonBorderColor}
												borderRadius="0.25rem"
												{...field}
												id="email"
												placeholder={t('Email')}
												fontSize="1.25rem"
												w="xs"
											/>
											<FormErrorMessage>{form.errors.email}</FormErrorMessage>
										</FormControl>
									)}
								</Field>
							) : (
								<Text marginBlock="auto" fontSize="1.25rem">
									{props.values.email}
								</Text>
							)}
							<Box></Box>
						</SimpleGrid>
						<SimpleGrid columns={3} mt="2rem">
							<Text marginBlock="auto" fontSize="1.25rem">
								{t('Service')}
							</Text>
							{isEditMode ? (
								<Field name="service">
									{({ field, form }) => (
										<FormControl
											isInvalid={form.errors.service && form.touched.service}
										>
											<Input
												bg="transparent"
												color={"white"}
												borderColor={DarkGreen.ButtonBorderColor}
												borderRadius="0.25rem"
												{...field}
												id="service"
												placeholder={t('Service')}
												_placeholder={{color:"white"}}
												fontSize="1.25rem"
												w="xs"
											/>
											<FormErrorMessage>{form.errors.service}</FormErrorMessage>
										</FormControl>
									)}
								</Field>
							) : (
								<Text marginBlock="auto" fontSize="1.25rem">
									{props.values.service}
								</Text>
							)}
							
						</SimpleGrid>

						<HStack mt="3rem">
							{isEditMode ? (
								<>
									<Button
										borderRadius="0.25rem"
										bg="transparent"
										color="white"
										background="transparent linear-gradient(180deg, #00965B 0%, #065688 100%) 0% 0% no-repeat padding-box;"
										_hover={{
											background:
												'transparent linear-gradient(180deg, #065688 0%, #00965B 100%) 0% 0% no-repeat padding-box;',
										}}
										isLoading={props.isSubmitting}
										type="submit"
										w="19.375rem"
									>
										{t('Update')}
									</Button>
									<Button
										borderRadius="0.25rem"
										variant="outline"
										bg="transparent"
										color={DarkGreen.iconColor}
										onClick={() => setIsEditMode(false)}
										_hover={{ color: 'black', bg: 'white' }}
										w="19.375rem"
									>
										{t('Cancel')}
									</Button>
								</>
							) : (
								<Button
									borderRadius="0.25rem"
									variant="outline"
									bg="transparent"
									color={DarkGreen.iconColor}
									onClick={() => navigate(PAGES.CHANGE_PASSWORD)}
									_hover={{ color: 'black', bg: 'white' }}
								>
									{t('ChangePassword')}
								</Button>
							)}
						</HStack>
					</Form>
				)}
			</Formik>
			</HStack>
		</Box>
	);
}

export default Account;
