import {
	FormControl,
	FormLabel,
	FormErrorMessage,
	Button,
	useToast,
	Stack,
	Checkbox,
	Text,
	HStack,
	useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Title from '../shared/Title';
import * as Yup from 'yup';
import { phoneRegExp } from '../../shared/constants';
import { useFetch } from '../../shared/hooks';
import { API, PAGES } from '../../shared/routes';
import api from '../../shared/api';
import CustomInput from '../shared/Input';
import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Roles,DarkGreen } from '../../shared/enums';
import ConfirmDelete from '../shared/ConfirmDelete';
import { getClientAPIUrl } from '../../shared/helpers';

interface GroupsProp {
	data: {
		count: number;
		results: {
			id: number;
			name: string;
			permissions: [];
		}[];
	};
}

function User() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();
	const isEditMode = id !== 'new' && id !== undefined;
	const toast = useToast();
	const { state, isAuthorized } = useStore();
	const [searchParams] = useSearchParams();
	const client = searchParams.get('client');
	const isDronodat = !isAuthorized([Roles.CLIENT_ADMIN]) && client;

	const { data: groups }: GroupsProp = useFetch(
		(isDronodat ? getClientAPIUrl(client) : '') + API.get.GROUPS,
	);
	const { data: user, isError }: any = useFetch(
		(isDronodat ? getClientAPIUrl(client) : '') + API.get.USERS + id + '/',
		!isEditMode,
	);

	if (isError && isEditMode) navigate(PAGES.NOT_FOUND);

	useEffect(() => {
		if (!isAuthorized([Roles.ADMIN, Roles.CLIENT_ADMIN]) && state.user.groups)
			navigate(PAGES.NOT_AUTHORIZED);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.user]);

	const initialValues = {
		title: '',
		name: '',
		phone_number: '',
		email: '',
		service: '',
		password: '',
		groups: [],
		...(isEditMode && user),
	};

	const handleCheckbox = (e, form) => {
		if (e.target.checked) {
			form.setFieldValue('groups', [Number(e.target.value)]);
		} else {
			form.setFieldValue('groups', []);
		}
	};
	// if edit mode, get user data and update initialValues

	return (
		<>
			<Title
				title={isEditMode ? t('users.edit.Title') : t('users.new.Title')}
				px={{ md: '4rem', base: '2rem' }}
				mb="3rem"
				data-aos="fade-down"
			/>
			<HStack ml = {{lg: "4rem", base:"1rem"}} mt = "2rem" scale={"0.8"} >

			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={Yup.object({
					title: Yup.string().optional(),
					name: Yup.string().optional(),
					phone_number: Yup.string()
						.optional()
						.matches(phoneRegExp, t('InvalidPhone')),
					service: Yup.string().optional(),
					groups: Yup.array().required().length(1),
					...(!isEditMode && {
						email: Yup.string().email().required(),
						password: Yup.string()
							.min(8, t('MinPassword'))
							.matches(
								/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
								t('MatchPassword'),
							)
							.required(),
					}),
				})}
				onSubmit={async (values, actions) => {
					try {
						if (isEditMode) {
							const formData = new FormData();
							formData.append('title', values.title);
							formData.append('name', values.name);
							formData.append('phone_number', values.phone_number);
							formData.append('service', values.service);
							values.groups.forEach((g) => {
								formData.append('groups', g);
							});
							await api.put(
								(isDronodat ? getClientAPIUrl(client) : '') +
									API.put.USERS +
									id +
									'/',
								formData,
							);
							toast({
								title: t('users.edit.toast.Title'),
								description: t('users.edit.toast.Description'),
								status: 'success',
								duration: 3000,
								isClosable: true,
								position: 'bottom-right',
							});
						} else {
							await api.post(
								(isDronodat ? getClientAPIUrl(client) : '') +
									API.post.REGISTRATION,
								{ ...values, email: values.email.toLowerCase() },
							);
							toast({
								title: t('users.new.toast.Title'),
								description: t('users.new.toast.Description'),
								status: 'success',
								duration: 3000,
								isClosable: true,
								position: 'bottom-right',
							});
						}
						navigate(
							`${PAGES.EMPLOYEES}${isDronodat ? `?client=${client}` : ''}`,
						);
					} catch (e) {
						toast({
							title: t('Error'),
							description: t('TryAgain'),
							status: 'error',
							duration: 3000,
							isClosable: true,
							position: 'bottom-right',
						});
						const errors = e.response.data;
						actions.setErrors({
							title: errors.title?.pop(),
							name: errors.name?.pop(),
							phone_number: errors.phone_number?.pop(),
							email: errors.email?.pop(),
							service: errors.service?.pop(),
							groups: errors.groups?.pop(),
							password: errors.password?.pop(),
						});
					}
					actions.setSubmitting(false);
				}}
			>
				{(props) => (
					<Form data-aos="fade-down">
						<Field name="title">
							{({ field, form }) => (
								<CustomInput
									label={t('Title')}
									type={'text'}
									error={form.errors.title}
									touched={form.touched.title}
									field={field}
								/>
							)}
						</Field>
						<Field name="name">
							{({ field, form }) => (
								<CustomInput
									label={t('Name')}
									type={'text'}
									error={form.errors.name}
									touched={form.touched.name}
									field={field}
								/>
							)}
						</Field>
						<Field name="phone_number">
							{({ field, form }) => (
								<CustomInput
									label={t('Phone')}
									type={'text'}
									error={form.errors.phone_number}
									touched={form.touched.phone_number}
									field={field}
								/>
							)}
						</Field>
						<Field name="email">
							{({ field, form }) => (
								<CustomInput
									label={t('Email')}
									type={'email'}
									error={form.errors.email}
									touched={form.touched.email}
									field={field}
									isDisabled={isEditMode}
								/>
							)}
						</Field>
						<Field name="service">
							{({ field, form }) => (
								<CustomInput
									label={t('Service')}
									type={'text'}
									error={form.errors.service}
									touched={form.touched.service}
									field={field}
								/>
							)}
						</Field>
						{!isEditMode && (
							<Field name="password">
								{({ field, form }) => (
									<CustomInput
										label={t('Password')}
										type={'password'}
										error={form.errors.password}
										touched={form.touched.password}
										field={field}
									/>
								)}
							</Field>
						)}
						<Field name="groups">
							{({ field, form }) => (
								<FormControl
									isInvalid={form.errors.groups && form.touched.groups}
									px={{ md: '6rem', base: '1rem' }}
									mb="2rem"
								>
									<FormLabel
										mb="1rem"
										as="legend"
										fontWeight="bold"
										fontSize="18px"
										color="white"
									>
										{t('Groups')}
									</FormLabel>
									<Stack spacing={10} direction="row">
										{groups?.results
											?.filter((group) => !group.name.includes('client-'))
											.map((group) => (
												<Checkbox
													key={group.id}
													onChange={(e) => {
														handleCheckbox(e, form);
													}}
													id={group.name}
													value={group.id}
													iconColor="#73BCB6"
													borderColor="#73BCB6"
													size="lg"
													isChecked={form.values.groups?.includes(group.id)}
												>
													<Text>{t(`roles.${group.name}`)}</Text>
												</Checkbox>
											))}
									</Stack>
									<FormErrorMessage>{form.errors.groups}</FormErrorMessage>
								</FormControl>
							)}
						</Field>
						<HStack
							columnGap="10"
							rowGap="5"
							flexWrap="wrap"
							mx={{ md: '6rem', base: '1rem' }}
							pb="2rem"
							spacing={0}
						>
							{isEditMode ? (
								<>
									<Button
										background="transparent"
										border="1px solid"
										borderColor={DarkGreen.ButtonBorderColor}
										_hover={{
											bg:DarkGreen.iconColor,
										}}
										isLoading={props.isSubmitting}
										type="submit"
										borderRadius={'0.25rem'}
										color="white"
										minW={{ lg: '210px', base: '130px' }}
									>
										{t('Update')}
									</Button>
									<Button
										background="transparent"
										border="1px solid"
										borderColor={DarkGreen.ButtonBorderColor}
										_hover={{
											borderColor:"red",
										}}
										minW={{ lg: '210px', base: '130px' }}
										onClick={() => {
											navigate(-1);
										}}
									>
										{t('Cancel')}
									</Button>
									<ConfirmDelete
										onClose={onClose}
										isOpen={isOpen}
										onConfirm={async () => {
											try {
												await api.delete(
													(isDronodat ? getClientAPIUrl(client) : '') +
														API.delete.USERS +
														id +
														'/',
												);
												toast({
													title: t('users.delete.toast.Title'),
													description: t('users.delete.toast.Description'),
													status: 'success',
													duration: 3000,
													isClosable: true,
													position: 'bottom-right',
												});
												navigate(
													`${PAGES.EMPLOYEES}${
														isDronodat ? `?client=${client}` : ''
													}`,
												);
											} catch {
												toast({
													title: t('Error'),
													description: t('TryAgain'),
													status: 'error',
													duration: 3000,
													isClosable: true,
													position: 'bottom-right',
												});
											}
										}}
									/>
									<Button
										background="transparent"
										borderRadius={'0.25rem'}
										borderWidth="2px"
										borderColor={DarkGreen.ButtonBorderColor}
										color="white"
										minW={{ lg: '210px', base: '130px' }}
										_hover={{
											borderColor: 'red',
											color:"red",
										}}
										onClick={onOpen}
									>
										{t('Delete')}
									</Button>
								</>
							) : (
								<>
									<Button
										background="transparent"
										border="1px solid"
										borderColor={DarkGreen.ButtonBorderColor}
										_hover={{
											background:
												DarkGreen.iconColor,
										}}
										isLoading={props.isSubmitting}
										type="submit"
										borderRadius={'0.25rem'}
										color="white"
										minW={{ lg: '210px', base: '130px' }}
									>
										{t('Submit')}
									</Button>
									<Button
										background="transparent"
										borderRadius={'0.25rem'}
										borderWidth="2px"
										borderColor={DarkGreen.ButtonBorderColor}
										color="white"
										minW={{ lg: '210px', base: '130px' }}
										_hover={{
											color: 'white',
											borderColor: 'red',
											background: 'transparent',
										}}
										onClick={() => {
											navigate(-1);
										}}
									>
										{t('Cancel')}
									</Button>
								</>
							)}
						</HStack>
					</Form>
				)}

			</Formik>
		</HStack>
		</>
	);
}

export default User;
