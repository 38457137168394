import { Text, TextProps } from '@chakra-ui/react';
interface SubtitleProps extends TextProps {
	subtitle: string;
}

function Subtitle(props: SubtitleProps) {
	const { subtitle, ...rest } = props;
	return (
		<Text {...rest} color="white" fontSize="18px" fontWeight="300" mb="3rem">
			{subtitle}
		</Text>
	);
}

export default Subtitle;
