import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { API, PAGES } from '../../shared/routes';
import { useFetch } from '../../shared/hooks';
import { generateQueryString, getClientAPIUrl } from '../../shared/helpers';
import { useEffect, useRef, useState } from 'react';
import { PaginationOptions, Roles, DarkGreen } from '../../shared/enums';
import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';
import Map from '../Maps/Project';
import CustomTable from '../shared/Table';
import Paginator from '../shared/Paginator';
import {
	averageGeolocation,
	getBounds,
	getBoundsZoomLevel,
} from '../../models/map';
import {
	Box,
	Center,
	FormControl,
	Heading,
	HStack,
	Icon,
	Input,
	InputGroup,
	Link,
} from '@chakra-ui/react';
import { MdArrowBackIosNew } from 'react-icons/md';
import { Formik, Form } from 'formik';
import { FiSearch } from 'react-icons/fi';
import api from '../../shared/api';

interface Powerline {
	properties: any;
	geometry: any;
}

function Powerlines() {
	const searchElement = useRef<HTMLInputElement>(null);
	const [map, setMap] = useState(null);
	const [refresh, setRefresh] = useState(0);
	const [powerlines, setPowerlines] = useState([]);
	const { isAuthorized, state } = useStore();
	const [searchParams] = useSearchParams();
	const [pagination, setPagination] = useState(PaginationOptions);
	const [sort, setSort] = useState(null);
	const { id } = useParams();
	const client = searchParams.get('client');
	const navigate = useNavigate();
	const { t } = useTranslation();
	const isDronodat = isAuthorized([Roles.ADMIN]) && client;

	useEffect(() => {
		if (!isAuthorized([Roles.CLIENT_ADMIN, Roles.ADMIN]) && state.user.groups)
			navigate(PAGES.NOT_AUTHORIZED);
		if (isAuthorized([Roles.ADMIN]) && state.user.groups && !client)
			navigate(PAGES.NOT_FOUND);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.user]);

	function customSort(name?: string, order?: string) {
		setSort(`${order}${name}`);
	}

	const { data: powerlinesData, loading: powerlinesLoading }: any = useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.MAPS_POWERLINES,
			{
				project: id,
				limit: pagination.limit,
				offset: pagination.offset,
				order_by: sort ? sort : '',
			},
		),
		!state.user.groups,
		({ data }: any) => {
			setPagination({
				...pagination,
				total: data.count,
			});
		},
		[refresh],
	);

	useEffect(() => {
		setPowerlines(
			powerlinesData?.results?.features?.map((powerline: Powerline) => {
				return {
					id: powerline.properties.id,
					area: Number(
						Number(
							powerline.properties.intersecting_data.area +
								(powerline.properties.surrounding_data.area ?? 0),
						).toFixed(2) + " m²",
					),
					priority: powerline.properties.PRIORITY,
					location: (
						<Link
							onClick={() => {
								const center = averageGeolocation(
									powerline.geometry.coordinates.map((coord: number[]) => {
										return { lat: coord[1], lng: coord[0] };
									}),
								);
								const bounds = getBounds(
									powerline.geometry.coordinates.map((coord: number[]) => {
										return { lat: coord[1], lng: coord[0] };
									}),
								);
								const { clientHeight, clientWidth } =
									document.getElementById('mapContainer');
								const newZoom = getBoundsZoomLevel(bounds, {
									width: clientWidth,
									height: clientHeight,
								});
								map.flyTo({
									center: center,
									zoom: newZoom,
								});
								map.setFilter(
									`goTo-LineString - ${powerline.properties.PRIORITY}`,
									['in', 'DBID', powerline.properties.id],
								);
							}}
							textDecor="underline"
						>
							{t('GoToLocation')}
						</Link>
					),
				};
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [powerlinesData]);

	return (
		<Box data-aos="fade-down" mx={{ md: '6rem', base: '1rem' }} pb="2rem">
			<HStack>
				<Icon
					cursor="pointer"
					onClick={() =>
						navigate(
							PAGES.PROJECTS +
								'/' +
								id +
								(isDronodat ? `?client=${client}` : ''),
						)
					}
					w="2rem"
					h="2rem"
					as={MdArrowBackIosNew}
				/>
				<Heading fontSize="2.6875rem" color="whitesmoke" fontWeight="normal">
					{t('Powerlines')} {/* {id} */}
				</Heading>
			</HStack>
			<Box
				// maxW="45rem"
				mt="1.5rem !important"
				w="full"
				h="50vh"
			>
				<Map
					isDronodat={isDronodat}
					projectId={id}
					getMap={(map) => {
						setMap(map);
					}}
				/>
			</Box>
			<HStack mt="2rem">
				<Formik
					initialValues={{}}
					onSubmit={async (e) => {
						if (searchElement.current?.value.length === 0) return;
						setPagination({
							...pagination,
							offset: 0,
							currentPage: 1,
						});
						try {
							const { data: powerline } = await api.get(
								(isDronodat ? getClientAPIUrl(client) : '') +
									API.get.MAPS_POWERLINES +
									searchElement.current?.value +
									'/',
							);
							setPowerlines([
								{
									id: powerline.properties.id,
									area: Number(
										Number(
											powerline.properties.intersecting_data.area +
												(powerline.properties.surrounding_data.area ?? 0),
										).toFixed(2),
									),
									priority: powerline.properties.PRIORITY,
									location: (
										<Link
											onClick={() => {
												const center = averageGeolocation(
													powerline.geometry.coordinates.map((coord) => {
														return { lat: coord[1], lng: coord[0] };
													}),
												);
												const bounds = getBounds(
													powerline.geometry.coordinates.map((coord) => {
														return { lat: coord[1], lng: coord[0] };
													}),
												);
												const { clientHeight, clientWidth } =
													document.getElementById('mapContainer');
												const newZoom = getBoundsZoomLevel(bounds, {
													width: clientWidth,
													height: clientHeight,
												});
												map.flyTo({
													center: center,
													zoom: newZoom,
												});
												map.setFilter(
													`goTo-LineString - ${powerline.properties.PRIORITY}`,
													['in', 'DBID', powerline.properties.id],
												);
											}}
											textDecor="underline"
										>
											{t('GoToLocation')}
										</Link>
									),
								},
							]);
						} catch {
							setPowerlines([]);
						}
					}}
				>
					<Form
						style={{
							width: '100%',
							display: 'flex',
						}}
					>
						<FormControl display="flex" gap="1rem" alignItems="center">
							<InputGroup w="auto" maxW="20rem">
								<HStack
																ml="0"
																_hover={{
																	"& > button > div": {
																		bg: DarkGreen.iconColor
																	}
																}}>
								<Input
									bg="transparent"
									borderColor={DarkGreen.tableBorderColor}
									color="white"
									borderRadius="0.25rem"
									textColor="white"
									placeholder={t('Search')}
									_placeholder={{
										color : "white"
									}}
									type="text"
									size="md"
									onChange={(e) => {
										if (e.target.value.length === 0) {
											setRefresh(refresh + 1);
										}
									}}
									ref={searchElement}
								/>
							<Center
										h="0.6rem"
										w="4rem"
										pos="relative"
										mt="0.2rem"
										ml="-5rem"
										padding={"1rem"}
										cursor="pointer"
										as="button"
										type="submit"
									>
										<Box
											clipPath="polygon(10% 0, 100% 0, 90% 100%, 0 100%);"
											h="0.6rem"
											w="4rem"
											pos="absolute"
											bg={DarkGreen.tableBorderColor}
										></Box>
										<Icon
											pos="absolute"
											w="2rem"
											h="2rem"
											color="white"
											
											as={FiSearch}
										/>
									</Center> 
								</HStack>
							</InputGroup>

						</FormControl>
					</Form>
				</Formik>
			</HStack>
			<Box my="1rem" w="100%" overflowX="auto" maxH="36rem">
				<CustomTable 
					minW="100%"
					columns={[
						{
							title: t('StringID'),
							accessor: 'id',
							sortable: true,
							variableName: 'string_id',
						},
						{
							title: t('Area'),
							accessor: 'area',
							sortable: true,
							variableName: 'area',
						},
						{
							title: t('Priority'),
							accessor: 'priority',
							sortable: true,
							variableName: 'priority',
						},
						{
							title: t('Location'),
							accessor: 'location',
						},
					]}
					data={powerlines}
					isLoading={powerlinesLoading}
					customSort={customSort}
				/>
			</Box>
			<Paginator
				isLoading={powerlinesLoading}
				total={pagination.total}
				onPageChange={(number) => {
					setPagination({
						...pagination,
						currentPage: number,
						offset: (number - 1) * pagination.limit,
					});
				}}
				onPerPageChange={(number) => {
					setPagination({
						...pagination,
						limit: number,
						offset: 0,
						currentPage: 1,
					});
				}}
				currentPage={pagination.currentPage}
			/>
		</Box>
	);
}

export default Powerlines;
