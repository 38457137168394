import CustomTable from '../shared/Table';
import Title from '../shared/Title';
import {
	Box,
	Button,
	Center,
	FormControl,
	HStack,
	Icon,
	Input,
	InputGroup,
	Stack,
	useDisclosure,
	Text,
	useBreakpointValue,
	VStack,
	Select as ChakraSelect,
	Divider,
} from '@chakra-ui/react';
import Paginator from '../shared/Paginator';
import { FiSearch } from 'react-icons/fi';
import { MdOutlineAdd } from 'react-icons/md';
import Select from '../shared/Select';
import { Option } from '../shared/Select/Option';
import { useState, useRef, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API, PAGES } from '../../shared/routes';
import { useFetch } from '../../shared/hooks';
import { generateQueryString, getClientAPIUrl } from '../../shared/helpers';
import { PaginationOptions, Roles, Status, StatusColors } from '../../shared/enums';
import { useStore } from '../../store';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Map from '../Maps/Project';
import Chart from 'react-apexcharts';
import { DarkGreen } from '../../shared/enums';
// import ConfirmDelete from '../shared/ConfirmDelete';
import { BiRightArrowAlt } from 'react-icons/bi';
import { BsFillCircleFill } from 'react-icons/bs';



function Projects() {
	const searchElement = useRef<HTMLInputElement>(null);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedProject, setSelectedProject] = useState(0);
	const [status, setStatus] = useState('');
	const [search, setSearch] = useState('');
	const [projects, setProjects] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [pagination, setPagination] = useState(PaginationOptions);
	const flexDirection = useBreakpointValue({ base: 'column', lg: 'row' });
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { isAuthorized, state } = useStore();
	const statusColors = ['#2E4765', '#CF304A', '#F2C018', '#03A66D'];
	const client = searchParams.get('client');
	const isLargeScreen = useBreakpointValue({ base: false, xl: true });	
	const isMidScreen = useBreakpointValue({ base: false, md:true });	


	const isDronodat =
		!isAuthorized([Roles.CLIENT_EMPLOYEE, Roles.CLIENT_ADMIN]) && client;

	useEffect(() => {
		if (
			!isAuthorized([Roles.CLIENT_EMPLOYEE, Roles.CLIENT_ADMIN]) &&
			state.user.groups &&
			!client
		)
			navigate(PAGES.NOT_AUTHORIZED);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.user]);


	
	const { loading } = useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.PROJECTS,
			{
				search,
				status: status === '-' ? '' : status,
				limit: pagination.limit,
				offset: pagination.offset,
			},
		),
		null,
		({ data }: any) => {
			setPagination({
				...pagination,
				total: data.count,
			});
			setSelectedProject(data.results[0].id);
			setProjects(
				data.results.map((project) => ({
					...project,
					created_on: <Text title={new Date(project.created_on).toISOString().split('T')[0]}>{new Date(project.created_on).toISOString().split('T')[0]}</Text>,
					address: <Text title={project.address}>{project.address.slice(0, 20)}</Text>,
					contact_person: project.contact_person?.name,
					name: 
						<Text
							cursor="pointer"
							onClick={() => {
								setSelectedProject(project.id);
							}}
						>
						{project.name}</Text>,
					id: (
						<HStack w= "100%" mx="0" my={0} justifyContent="center">
							<Icon title = {Status[project.status]} as={BsFillCircleFill} color={statusColors[project.status]}
								cursor="pointer"
								onClick={() => {
									setSelectedProject(project.id);
								}}
							/> 
							<Text
								cursor="pointer"
								onClick={() => {
									setSelectedProject(project.id);
								}}
							>
								{project.id}
							</Text>
						</HStack>
					),
					
					status: (
						""
						// isLargeScreen?
						// 	<Text color={statusColors[project.status]}>
								
						// 		{t(Status[project.status])}
						// 	</Text>
						// 	:
							// <Icon title = {Status[project.status]} as={BsFillCircleFill} color={statusColors[project.status]}/>
					),
					actions: (
						<Icon
						as={BiRightArrowAlt}
						cursor="pointer"
							borderRadius="0.25rem"
							// bg={DarkGreen.ButtonBackgroundColor}
							borderColor={DarkGreen.tableBorderColor} //"rgba(0, 196, 106, 0.2)"//{DarkGreen.iconColor}
							color={DarkGreen.iconColor}// "rba(255,255,255,1)" //{DarkGreen.tableHighlightedColor}
							// size="sm"
							w= "1.5rem"
							h= "1.5rem"
							title={t('Details')}
							_hover={{
								color: DarkGreen.iconColor,
								background: 'white',
							}}
							onClick={() => {
								navigate(
									`${PAGES.PROJECTS}/${project.id}${
										isDronodat ? `?client=${client}` : ''
									}`,
								);
							}}
						>
						</Icon>
					),
				})),
			);
		},
	);
	useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.PROJECTS,
			{
				limit: 999999,
			},
		),
		null,
		({ data }: any) => {
			setChartData( //Charts
				data.results
					.filter((project) => project.created_on && project.finished_on)
					.map((project) => {
						return {
							// label: project.name,
							x: project.name,
							y: [
								new Date(project.created_on).getTime(),
								new Date(project.finished_on).getTime(),
							],
							fillColor: statusColors[project.status],
							dataLabels:project.name,

							
						};
					}),
			);
		},
	);

	return (
		<>
			<Title
				title={t('Projects')}
				px={{ md: '4rem', base: '2rem' }}
				mb="3rem"
				data-aos="fade-down"
			/>
			<Stack
				justify="space-between"
				flexDir="column"
				mx={{ md: '6rem', base: '1rem' }}
				spacing="0"
				gap="1rem"
				mb="2rem"
				data-aos="fade-down"
				justify-content="flex-start"

			>
				<VStack>
					<Box
						h="40vh"
						minH="25rem"
						w={{ '2xl': '20%', 'base': '100%' }}
						minW="100%"
						// bg="gray"
					>
						<Map isDronodat={isDronodat} projectId={selectedProject} />
					</Box>


					<HStack
					// align={"flex-end"}
					mx={{ md: '6rem', base: '1rem' }}
					w="100%"
					flexDir={{ xl: "column", lg:"column", base:"column"}}
					// display="flex"
					// justify-content="flex-start"
					>
					<VStack 
						// overflowX="auto" 
						ml="20px"
						mt="1.5rem"
						w="100%"
						maxW="90vw"
					>
						<HStack
							justify="space-between"
							w="100%"
							flexDir={{ lg: 'row', base: 'column' }}
							rowGap="1rem"
							columnGap="2rem"
						>
							<Formik
								initialValues={{}}
								onSubmit={(e) => {
									setPagination({
										...pagination,
										offset: 0,
										currentPage: 1,
									});
									setSearch(searchElement.current?.value);
								}}
							>
								<Form
									style={{
										width: '100%',
										display: 'flex',
										gap: '1rem',
										flexDirection: flexDirection === 'row' ? 'row' : 'column',
									}}
								>
									<FormControl display="flex" gap="1rem"   alignItems="center">
										<HStack
										_hover={{
											"& > button > div": {
												bg: DarkGreen.iconColor
											}
										}}> 
										<InputGroup w="auto" maxW="20rem">
										<Input
												w="420px"
												bg="transparent"
												borderColor={DarkGreen.tableBorderColor}
												color="white"
												borderRadius="0.25rem"
												placeholder={t('Search')}
												_placeholder={{
													color: "white"
												}}
												textColor="white"
												type="text"
												size="md"
												onChange={(e) => {
													if (e.target.value.length === 0) {
														setSearch('');
													}
												}}
												ref={searchElement}
											/>
										</InputGroup>

									 <Center
										h="0.6rem"
										w="4rem"
										pos="relative"
										mt="0.2rem"
										ml="-5rem"
										padding={"1rem"}
										cursor="pointer"
										as="button"
										type="submit"
									>
										<Box
											clipPath="polygon(10% 0, 100% 0, 90% 100%, 0 100%);"
											h="0.6rem"
											w="4rem"
											pos="absolute"
											bg={DarkGreen.tableBorderColor}
										></Box>
										<Icon
											pos="absolute"
											w="2rem"
											h="2rem"
											color="white"
											
											as={FiSearch}
										/>
									</Center> 
									 </HStack>
									</FormControl>
									<HStack
										w="100%"
										justify={{ lg: 'flex-end', base: 'flex-start' }}
										gap="1rem"
										spacing={0}
									>
										<Select
											bg="transparent"
											borderColor={DarkGreen.tableBorderColor}
											color="white"
											borderRadius="0.25rem"
											textColor="white"
											size="md"
											name="Status"
											value={status}
											onChange={setStatus}
											placeholder={t('FilterByStatus')}
											whiteSpace="nowrap"
											w="14rem"
											order="0"
										>
											<Option value="-">{t('All')}</Option>
											<Option value="1">{t('Pending')}</Option>
											<Option value="2">{t('InProgress')}</Option>
											<Option value="3">{t('Done')}</Option>
										</Select>
										{isAuthorized([Roles.CLIENT_ADMIN, Roles.ADMIN]) && (
											<Icon
												onClick={() => {
													navigate(
														PAGES.NEW_PROJECT +
															(isDronodat ? `?client=${client}` : ''),
													);
												}}
												color={DarkGreen.iconColor}

												as={MdOutlineAdd}
												cursor="pointer"
												w="48px"
												h="48px"
												title={t('projects.New')}
												
											/>
										)}
									</HStack>
								</Form>
							</Formik>
						</HStack>

						<Box 
							w="100%" 
							overflowX="auto" 
							maxH="40rem"
							// maxW="200px"
							minW={isLargeScreen? "950px": isMidScreen? "350px": "350px"}
						>
							<CustomTable
								// minW="100%"
								min_width="none"		
								columns={isLargeScreen ? [
									{
									  title: t('ID'),
									  accessor: 'id',
									  sortable: true,
									  tdMaxWidth:"100px"

									},
									{
									  title: t('Name'),
									  accessor: 'name',
									  sortable: true,
									//   tdMaxWidth:"150px"

									},
									{
									  title: t('Address'),
									  accessor: 'address',
									//   tdMaxWidth:"200px"

									},
									{
									  title: t('ContactPerson'),
									  accessor: 'contact_person',
									//   tdMaxWidth:"60px"
									},
									{
									  title: t('Date'),
									  accessor: 'created_on',
									  sortable: true,
									},
									{
									  title: '',
									  accessor: 'actions',
									},
								  ] : isMidScreen? [
									{
										title: t('ID'),
										accessor: 'id',
										sortable: true,
									  },
									  {
										title: t('Name'),
										accessor: 'name',
										sortable: true,
									  },
									  {
										title: t('Date'),
										accessor: 'created_on',
										sortable: true,
									  },

									  {
										title: '',
										accessor: 'actions',
									  },
								  ]:[
									{
										title: t('ID'),
										accessor: 'id',
										sortable: true,
										tdMaxWidth:"50px"

									  },
									  {
										title: t('Name'),
										accessor: 'name',
										sortable: true,
										tdMaxWidth:"70px"
									  },
									  {
										title: t('Date'),
										accessor: 'created_on',
										sortable: true,
										tdMaxWidth:"90px"

									  },
									  {
										title: '',
										accessor: 'actions',
										tdMaxWidth:"90px"

									  },
								  ]
							}		
								data={projects}
								isLoading={loading}
							/>
						</Box>

						<Paginator
							isLoading={loading}
							total={pagination.total}
							onPageChange={(number) => {
								setPagination({
									...pagination,
									currentPage: number,
									offset: (number - 1) * pagination.limit,
								});
							}}
							onPerPageChange={(number) => {
								setPagination({
									...pagination,
									limit: number,
									offset: 0,
									currentPage: 1,
								});
							}}
							currentPage={pagination.currentPage}
						/>
					</VStack>
					{/* <Divider
						borderColor="black"
						borderWidth="20px"
						clipPath="polygon(0 0, 100% 0, 100% 100%, 0 56%);"
						mb="1.375rem"
						mt="2rem !important"
					/> */}
					<VStack w="100%"
					// pl={{lg:"4rem",base:"0rem"}}
					>
					<HStack w="100%" justify={{lg:"space-between",base:"center"}} mt="2rem" >
						<Text fontSize="22px" fontWeight="600">
							{t('Overview')}
						</Text>
						{/* <ChakraSelect variant="unstyled" w="fit-content">
							<option style={{ color: 'black' }}>Last 7 days</option>
							<option style={{ color: 'black' }}>Last 30 days</option>
							<option style={{ color: 'black' }}>Last 90 days</option>
						</ChakraSelect> */}
					</HStack>
					<Box mb="2rem !important" w="100%" minW={"300px"}>
						<Chart
						
							options={{
								
								chart: {
									toolbar: {
										show: false,
									},
									zoom: {
										enabled: false,
									},
								},

								plotOptions: {
									bar: {
									  distributed: true,
									  horizontal: true,
									  // distributed: true,
									  borderRadius: 5, //[5, 5],
									  rangeBarOverlap: false,
									  barHeight: '35px',
									//   dataLabels: {
									// 	position: 'bottom'
									//   },
									}
								  },
								  dataLabels: {
									enabled: true,
									// textAnchor: 'start',
									style: {
									  colors: ['#fff'],
									  fontWeight: 'normal',
									  fontSize:"14px",
									},

									formatter: function (val, opt) {
									  return opt.w.globals.labels[opt.dataPointIndex] 
									},
									offsetX: 0,
									dropShadow: {
									  enabled: true
									}
								  },
								  
								  stroke: {
									width: 0,
									colors: ['#fff']
								  },

								  tooltip: {
									theme: 'dark',
									x: {
									},
									y: {
									  title: {
										formatter: function () {
										  return ''
										}
									  }
									}
								  },

								xaxis: {
									
									type: 'datetime',
									labels: {
										
										style: {
											colors: 'white',
										},
									},
								},
								yaxis: {
									
									labels: {
										show: false,
										style: {
											colors: 'white',
										},
									},
								},
							}}
							grid={{
								row: {
									colors: ['#f3f4f5', '#fff'],
									opacity: 0.5,
								},
							}}
							series={[
								{
									data: chartData,
								},
							]}
							type="rangeBar"
							width="100%"
							height="300px"
						/>
					</Box>
					</VStack>
					</HStack>
					
				</VStack>
			</Stack>
		</>
	);
}

export default Projects;
