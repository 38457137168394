import { Box, HStack, Icon, useMediaQuery, Image } from '@chakra-ui/react';
import { RiShutDownLine } from 'react-icons/ri';
import LanguageSwitcher from '../LanguageSwitcher';
import { GiHamburgerMenu } from 'react-icons/gi';
import { logo2 } from '../../assets/images';
import { logout } from '../../models/auth';
import { useEffect, useState } from 'react';

function Header({  isOpen  }) {
	const [notDesktop] = useMediaQuery('(max-width: 62em)');
	const [isBodyScrolled, setisBodyScrolled] = useState(false);

	useEffect(() => {
		const container = document.getElementById('container');
		container?.addEventListener('scroll', () => {
			if (container?.scrollTop <= 0) setisBodyScrolled(false);
			else setisBodyScrolled(true);
		});

		return () => {
			container?.removeEventListener('scroll', () => {
				if (container?.scrollTop <= 0) setisBodyScrolled(false);
				else setisBodyScrolled(true);
			});
		}
	}, []);

	return (
		<Box
			w="full"
			pos="fixed"
			right={0}
			h="4rem"
			background={
				isBodyScrolled ? 'rgba(0, 15, 25, 0.1)' : 'rgba(0, 62, 100, 0)'
			}
			// clipPath="polygon(0 0, 100% 0, 100% 100%, 0 80%);"
			p="1.5rem 1rem"
			display="flex"
			justifyContent="flex-end"
			zIndex={isOpen ? '888' : '999'}
			id="header"
		>

			<HStack
				// transition="all 0.3s ease-in-out"
				opacity={notDesktop && isOpen ? '0' : '1'}
				gap={{ lg: '1.5rem', base: '0.5rem' }}
				align="flex-start"
			>
				<LanguageSwitcher />
				<Icon
					cursor="pointer"
					color="white"
					w="2rem"
					h="2rem"
					as={RiShutDownLine}
					onClick={logout}
				/>
			</HStack>
		</Box>
	);
}

export default Header;
