import { Center, Spinner } from '@chakra-ui/react';
import Wrapper from '../shared/Wrapper';

function Loader() {
	return (
		<Wrapper>
			<Center h='100vh'>
				<Spinner size="xl" />
			</Center>
		</Wrapper>
	);
}

export default Loader;
