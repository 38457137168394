import { Container, Box } from '@chakra-ui/react';
import { electricTower1 } from '../../../assets/images';

interface WrapperProps {
	backgroundImage?: string;
	showOverlay?: boolean;
	isNavigator?: boolean;
	backgroundSize?: string;
	children?: React.ReactNode;
}

function Wrapper({
	backgroundImage = electricTower1,
	showOverlay = false,
	isNavigator = false,
	backgroundSize = 'cover',
	children,
}: WrapperProps) {

	return (
		<Container
			minW="100%"
			p="0 !important"
			h="100vh"
			backgroundImage={backgroundImage}
			// backgroundColor="#051611 0% 0% no-repeat padding-box"
			backgroundPosition="center"
			backgroundRepeat="no-repeat"
			backgroundSize={backgroundSize}
			opacity= "1"
	>
			<Box
				overflowY="auto"
				bg={showOverlay ? 'overlay' : 'transparent'}
				w="100%"
				h="100%"
				display={isNavigator ? 'flex' : 'block'}
				id='container'
				pl={{ base: '4rem', lg: '0' }}
			>
				{children}
			</Box>
		</Container>
	);
}

export default Wrapper;
