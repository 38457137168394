import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	FormControl,
	FormErrorMessage,
	Input,
	useToast,
	Icon,
	InputGroup,
	InputLeftElement,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { resetPassword } from '../../models/auth';
import * as Yup from 'yup';
import { MdEmail } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

function ForgotPassword({ isOpen, onClose }) {
	const toast = useToast();
	const { t } = useTranslation();

	return (
		<Modal size="xl" isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<Formik
				initialValues={{ email: '' }}
				validationSchema={Yup.object().shape({
					email: Yup.string().email(t('InvalidEmail')).required(t('Required')),
				})}
				onSubmit={async (values, actions) => {
					try {
						await resetPassword(values.email);
						onClose();
						toast({
							title: t('forgetPassword.toast.Title'),
							description: t('forgetPassword.toast.Description'),
							status: 'success',
							duration: 5000,
							isClosable: true,
							position: 'bottom-right',
						});
					} catch {
						actions.setErrors({
							email: t('InvalidEmail'),
						});
					}

					actions.setSubmitting(false);
				}}
			>
				{(props) => (
					<Form>
						<ModalContent
							borderColor="#73BCB6"
							borderWidth="2px"
							p="4rem"
							bg="rgba(0,0,0,1)"
						>
							<ModalHeader color="#2F98A6">
								{t('forgetPassword.Title')}
							</ModalHeader>
							<ModalCloseButton color="white" />
							<ModalBody>
								<Field name="email">
									{({ field, form }) => (
										<FormControl
											isInvalid={form.errors.email && form.touched.email}
										>
											<InputGroup>
												<InputLeftElement
													pointerEvents="none"
													pl="1rem"
													children={
														<Icon
															w="1.5rem"
															h="1.5rem"
															color="#73BCB6"
															as={MdEmail}
														/>
													}
												/>
												<Input
													bg="transparent"
													color="#73BCB6"
													borderColor="#73BCB6"
													borderRadius="0.25rem"
													{...field}
													id="email"
													placeholder={t('Email')}
													pl="4rem"
												/>
											</InputGroup>
											<FormErrorMessage>{form.errors.email}</FormErrorMessage>
										</FormControl>
									)}
								</Field>
							</ModalBody>
							<ModalFooter mt="2rem">
								<Button
									background="transparent linear-gradient(180deg, #00965B 0%, #065688 100%) 0% 0% no-repeat padding-box;"
									_hover={{
										background:
											'transparent linear-gradient(180deg, #065688 0%, #00965B 100%) 0% 0% no-repeat padding-box;',
									}}
									isLoading={props.isSubmitting}
									type="submit"
									borderRadius={'0.25rem'}
									color="white"
									w="full"
								>
									{t('forgetPassword.Send')}
								</Button>
							</ModalFooter>
						</ModalContent>
					</Form>
				)}
			</Formik>
		</Modal>
	);
}

export default ForgotPassword;
