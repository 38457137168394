export const PAGES = {
	HOME: '/',
	ACCOUNT: '/account',
	LOGIN: '/login',
	EMPLOYEES: '/employees',
	CLIENTS: '/clients',
	PROJECTS: '/projects',
	MISSIONS: '/missions',
	POWERLINES: '/powerlines',
	MAPS: '/',
	NOT_FOUND: '/not-found',
	NOT_AUTHORIZED: '/not-authorized',
	CHANGE_PASSWORD: '/change-password',
	RESET_PASSWORD: '/reset-password',
	USERS: '/users',
	NEW_PROJECT: '/projects/new',
	NEW_MISSION: '/missions/new',
	NEW_USER: '/users/new',
	NEW_CLIENT: '/clients/new',
};

export const API = {
	get: {
		GROUPS: '/groups/',
		USERS: '/users/',
		ALL_USERS: '/users/search/',
		PROJECTS: '/projects/',
		MISSIONS: '/missions/',
		REPORTS: '/reports/',
		CLIENTS: '/clients/',
		COUNTRIES: '/countries/',
		MAPS_2D: '/map/geodata/2d/',
		MAPS_3D: '/map/geodata/3d/',
		MAPS_POWERLINES: '/map/powerlines/',
		ANNOTATIONS:'/annotations/',
		POINTSCLOUD:'/pointcloud/'
	},
	post: {
		REFRESH: '/token/refresh/',
		VERIFY: '/token/verify/',
		LOGIN: '/login/',
		LOGOUT: '/logout/',
		RESET_PASSWORD: '/password/reset/',
		RESET_PASSWORD_CONFIRM: '/password/reset/confirm/',
		CHANGE_PASSWORD: '/password/change/',
		REGISTRATION: '/registration/',
		PROJECTS: '/projects/',
		REPORTS: '/reports/',
		CLIENTS: '/clients/',
		MISSIONS: '/missions/',
		ANNOTATIONS:'/annotations/',
		GENERATE_REPORT: (id) => `/projects/${id}/generate_report/`
	},
	put: {
		USERS: '/users/',
		PROJECTS: '/projects/',
		CLIENTS: '/clients/',
		MISSIONS: '/missions/',
		ANNOTATIONS:'/annotations/',
	},
	delete: {
		USERS: '/users/',
		PROJECTS: '/projects/',
		REPORTS: '/reports/',
		CLIENTS: '/clients/',
		MISSIONS: '/missions/',
		ANNOTATIONS:'/annotations/',
	},
};
