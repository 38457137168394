import axios from 'axios';
import api from '../shared/api';
import { getCookie, removeAllCookies, setCookie } from '../shared/helpers';
import { API, PAGES } from '../shared/routes';

export const refreshToken = async () => {
	const oldRefreshToken = getCookie('refresh_token');
	const res = await api.post(API.post.REFRESH, { refresh: oldRefreshToken });
	setCookie('access_token', res.data.access);
	// setCookie('refresh_token', res.data.refresh);
};

export const verifyToken = () => {
	const refreshToken = getCookie('refresh_token');
	if (!refreshToken) throw new Error('No refresh token');
	return axios.post(process.env.REACT_APP_API + API.post.VERIFY, {
		token: refreshToken,
	});
};

export const login = async (email: string, password: string) => {
	const res = await api.post(API.post.LOGIN, { email, password });
	setCookie('access_token', res.data.access_token);
	setCookie('refresh_token', res.data.refresh_token);

	return res.data;
};

export const logout = async () => {
	await api.post(API.post.LOGOUT);
	removeAllCookies();
	window.location.href = PAGES.LOGIN;
};

export const resetPassword = async (email: string) => {
	return api.post(API.post.RESET_PASSWORD, { email });
};

export const resetPasswordConfirm = async (
	token: string,
	uid: string,
	password: string,
	passwordConfirm: string,
) => {
	return api.post(API.post.RESET_PASSWORD_CONFIRM, {
		token,
		uid,
		new_password1: password,
		new_password2: passwordConfirm,
	});
};

export const changePassword = async (
	oldPassword,
	password,
	passwordConfirm,
) => {
	return api.post(API.post.CHANGE_PASSWORD, {
		old_password: oldPassword,
		new_password1: password,
		new_password2: passwordConfirm,
	});
};
