import {
	Box,
	VStack,
	Text,
	useDisclosure,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	HStack,
	Radio,
	RadioGroup,
	Stack
} from '@chakra-ui/react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
  } from '@chakra-ui/react'


import { Button, ButtonGroup } from '@chakra-ui/react'

import { useEffect, useRef, useState } from 'react';
// @ts-ignore
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useSearchParams } from 'react-router-dom';
import {
	DefaultFeaturesColors,
	MapPriorityColor,
	DarkGreen,
} from '../../shared/enums';
import {
	averageGeolocation,
	getBounds,
	getBoundsZoomLevel,
} from '../../models/map';
import { Layer, polygonDataCalc, showFeatureInfo } from './Elements';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { t } from 'i18next';
import { Player } from '@lottiefiles/react-lottie-player';
import Potree from './Potree';
import proj4 from 'proj4';
import { useLocation } from 'react-router-dom';
import { log } from 'console';




mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
// mapboxgl.id = "mapbox-satellite-v9";

function Map({
	projectId,
	isDronodat,
	isUploading = false,
	getMap = (map): any => {},
}) {
	
	const { isOpen, onOpen, onClose } = useDisclosure()
  	const [size, setSize] = useState('full')
	const [searchParams] = useSearchParams();
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [lng] = useState(0);
	const [lat] = useState(0);
	const [zoom] = useState(1);
	const [areaMeasure, setAreaMeasure] = useState(0);
	const [lineMeasure, setLineMeasure] = useState(0);
	const client = searchParams.get('client');
	const [geoData, setGeoData] = useState([]);
	const [rezoom, setRezoom] = useState(true);
	const zoomTolerance = 0.01
	const location = useLocation();
	const isProjectDetails = location.pathname.startsWith('/projects/') && !isNaN(parseInt(location.pathname.split('/')[2]));
	// console.log(isProjectDetails);
	// console.log(location.pathname);
	const [properties, setProperties] = useState({});
	const [refresh, setRefresh] = useState(0);
	const [loading, setLoading] = useState(false);
	const [threeDbackrColor, setthreeDbackrColor] = useState('transparent');
	const [twoDbackrColor, settwoDbackrColor] = useState(DarkGreen.ButtonHighlightColor);
	const [TwoDBorderWidth,set2DBorderWidth] = useState('3px');
	const [THREEDBorderWidth,set3DBorderWidth] = useState('0px');
	const [TwoDMapWindowsize, setTwoDMapWindowsize]= useState('100%');
	const [ThreeDMapWindowsize, setThreeDMapWindowsize]= useState('1%');
	const [ThreeDMapPosition, setThreeDMapPosition]= useState('absolute');
	const [ThreeDMapWindowheight, setThreeDMapWindowheight]= useState('1%');
	const [CESIUMVIEWER,setCESIUMVIEWER]= useState(undefined);
	const [Cesium,setCesium]= useState(undefined);
	const [PotreeViewer , setpotreeViewerobj] = useState(undefined);
	const [DATAPROJ , setDataProjection] = useState(undefined);
	const [MAPPROJ , setMapProjection] = useState(undefined);
	const [fullScreenIcon,setfullScreenIcon] = useState({"ableFullScreen":true, "url":"/full-screen-display-button-1.svg"});

	const DATAPROJRef = useRef(null);
		DATAPROJRef.current = DATAPROJ;
	
	const MAPPROJRef = useRef(null);
		MAPPROJRef.current = MAPPROJ;

	const CESIUMVIEWERRef = useRef(null);
	CESIUMVIEWERRef.current = CESIUMVIEWER;

	const CesiumRef = useRef(null);
	CesiumRef.current = Cesium;

	const PotreeViewerRef = useRef(null);
		PotreeViewerRef.current = PotreeViewer;

	const [rightSwitcherMenuHeight, setrightSwitcherMenuHeight ]= useState('1rem')
	
	useEffect(() => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/dronodat/cl905vm02005815pr4jhim0rz',
			center: [lng, lat],
			zoom: zoom,
			doubleClickZoom: false,
			maxZoom: 17
		});
		getMap(map.current);
		map.current.boxZoom.disable();
		map.current.touchZoomRotate.disableRotation();
		map.current.dragRotate.disable();
		
		map.current.on('load', () => {

			const canvas = map.current.getCanvasContainer();
			window['MapboxGL'] = map.current;
			// Variable to hold the starting xy coordinates
			// when `mousedown` occured.
			let start;

			let ctrlClicked = false;

			// Set `true` to dispatch the event before other functions
			// call it. This is necessary for disabling the default map
			// dragging behaviour.
			canvas.addEventListener('mousedown', mouseDown, true);

			// Return the xy coordinates of the mouse position
			function mousePos(e) {
				const rect = canvas.getBoundingClientRect();
				return new mapboxgl.Point(
					e.clientX - rect.left - canvas.clientLeft,
					e.clientY - rect.top - canvas.clientTop,
				);
			}

			function mouseDown(e) {
				// Continue the rest of the function if the shiftkey is pressed.
				if (!(e.shiftKey && e.button === 0)) return;
				ctrlClicked = e.altKey;
				// Disable default drag zooming when the shift key is held down.

				// Call functions for the following events
				document.addEventListener('mouseup', onMouseUp);
				document.addEventListener('keydown', onKeyDown);

				// Capture the first xy coordinates
				start = mousePos(e);
			}

			function onMouseUp(e) {
				// Capture xy coordinates
				
				finish([start, mousePos(e)]);
			}

			function onKeyDown(e) {
				// If the ESC key is pressed
				if (e.keyCode === 27) finish('');
			}

			function finish(bbox) {
				// Remove these events now that finish has been called.
				document.removeEventListener('keydown', onKeyDown);
				document.removeEventListener('mouseup', onMouseUp);
				map.current.dragPan.enable();
			}
		});

			map.current.on('moveend', () => {
				//console.log(TwoDMapWindowsize)
				//console.log(CesiumRef.current ,CESIUMVIEWERRef.current)
				if (CESIUMVIEWERRef.current !== undefined && CesiumRef.current !== undefined && PotreeViewerRef.current !== undefined && MAPPROJRef.current !== undefined && DATAPROJRef.current !== undefined){

						let MapboxBBOX = map.current.getBounds()
						const center = map.current.getCenter();
						let converterSW = proj4(MAPPROJRef.current,DATAPROJRef.current,[MapboxBBOX._sw.lng, MapboxBBOX._sw.lat]);
						let converterNE = proj4(MAPPROJRef.current,DATAPROJRef.current,[MapboxBBOX._ne.lng, MapboxBBOX._ne.lat]);
						let centerMap = proj4(MAPPROJRef.current,DATAPROJRef.current,[center.lng, center.lat])

						PotreeViewerRef.current.scene.view.position.set(centerMap[0], centerMap[1], Math.abs(converterSW[0]-converterNE[0]) * 0.7);
						PotreeViewerRef.current.scene.view.pitch = -CesiumRef.current.Math.PI_OVER_TWO;
						PotreeViewerRef.current.scene.view.yaw = 0;

				}
			

			});

		const draw = new MapboxDraw({
			displayControlsDefault: false,
			controls: {
				polygon: true,
				line_string: true,
				trash: true,
			},
		});
		map.current.on('click', (e) => {
			Object.keys(MapPriorityColor).forEach((key) => {
				map.current.setFilter(`goTo-LineString - ${key}`, ['in', 'DBID']);
			});
			onClose();
			showFeatureInfo(e, map, setProperties, onOpen);
			polygonDataCalc(e, map, setAreaMeasure, setLineMeasure);
		});
		//map.current.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');
		map.current.addControl(
			new mapboxgl.GeolocateControl({
				positionOptions: {
					enableHighAccuracy: true,
				},
				// When active the map will receive updates to the device's location as it changes.
				trackUserLocation: true,
				// Draw an arrow next to the location dot to indicate which direction the device is heading.
				showUserHeading: true,
			}),
			'bottom-right',
		);
		map.current.addControl(
			new MapboxGeocoder({
				accessToken: mapboxgl.accessToken,
				mapboxgl,
			}),
			'top-left',
		);
		map.current.addControl(draw, 'bottom-right');

		map.current.loadImage('/assets/images/power.png', (error, image) => {
			if (error) return;
			map.current.addImage('power', image);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refresh, geoData, map?.current?.style?.stylesheet?.name]);

	useEffect(() => {
		if (!map.current) return; // wait for map to initialize
	
		setTimeout(() => {
						let data = [].concat.apply([], Object.values(geoData));
						const temp = [];
						data.forEach((feature) => {
							if (feature.geometry.type === 'GeometryCollection') {
								data = data.filter(
									(f) => f.properties.DBID !== feature.properties.DBID,
								);
								feature.geometry.geometries.forEach((geom) => {
									temp.push({ ...feature, geometry: geom });
								});
							}
						});

						data = [...data, ...temp];
						const Collections = {
							'Polygon - None': {
								type: 'FeatureCollection',
								features: [],
							},
							'Polygon - 01 - Immediate': {
								type: 'FeatureCollection',
								features: [],
							},
							'Polygon - 1M - 1 Month': {
								type: 'FeatureCollection',
								features: [],
							},
							'Polygon - 3M - 3 Months': {
								type: 'FeatureCollection',
								features: [],
							},
							'LineString - None': {
								type: 'FeatureCollection',
								features: [],
							},
							'LineString - 01 - Immediate': {
								type: 'FeatureCollection',
								features: [],
							},
							'LineString - 1M - 1 Month': {
								type: 'FeatureCollection',
								features: [],
							},
							'LineString - 3M - 3 Months': {
								type: 'FeatureCollection',
								features: [],
							},
							'LineString': {
								type: 'FeatureCollection',
								features: [],
							},
							'Point': {
								type: 'FeatureCollection',
								features: [],
							},
							'Polygon': {
								type: 'FeatureCollection',
								features: [],
							},
						};

						if (data) {
							data?.forEach((feature, index) => {
								feature.properties.DBID = feature.properties.id;
								if (feature.geometry.type === 'Point') {
									Collections['Point'].features.push(feature);
								} else if (feature.geometry.type === 'LineString') {
									if (feature.properties.PRIORITY === '01 - Immediate') {
										Collections['LineString - 01 - Immediate'].features.push(feature);
									} else if (feature.properties.PRIORITY === 'None') {
										Collections['LineString - None'].features.push(feature);
									} else if (feature.properties.PRIORITY === '1M - 1 Month') {
										Collections['LineString - 1M - 1 Month'].features.push(feature);
									} else if (feature.properties.PRIORITY === '3M - 3 Months') {
										Collections['LineString - 3M - 3 Months'].features.push(feature);
									} else {
										Collections['LineString'].features.push(feature);
									}
								} else if (feature.geometry.type === 'Polygon') {
									if (feature.properties.PRIORITY === '01 - Immediate') {
										Collections['Polygon - 01 - Immediate'].features.push(feature);
									} else if (feature.properties.PRIORITY === 'None') {
										Collections['Polygon - None'].features.push(feature);
									} else if (feature.properties.PRIORITY === '1M - 1 Month') {
										Collections['Polygon - 1M - 1 Month'].features.push(feature);
									} else if (feature.properties.PRIORITY === '3M - 3 Months') {
										Collections['Polygon - 3M - 3 Months'].features.push(feature);
									} else {
										Collections['Polygon'].features.push(feature);
									}
								}
							});

							Object.keys(Collections).forEach((key) => {
								if (map.current.getLayer(key)) {
									map.current.removeLayer(key).removeSource(key);
									// map.current
									// 	.removeLayer('highlighted-' + key)
									// 	.removeSource('highlighted-' + key);
									if (key.includes('LineString - ')) {
										map.current.removeLayer('goTo-' + key).removeSource('goTo-' + key);
									}
								}

								if (data.length === 0) return;

								if (key === 'Point') {
									map.current.addLayer({
										id: key,
										type: 'symbol',
										source: {
											type: 'geojson',
											data: Collections[key],
						tolerance: zoomTolerance
										}, // reference the data source
										layout: {
											'icon-image': 'power',
											'icon-size': 0.3,
											'icon-anchor': 'center',
											'icon-offset': [0, -4],
										},
									});
								} else if (key === 'LineString') {
									map.current.addLayer({
										id: key,
										type: 'line',
										source: {
											type: 'geojson',
											data: Collections[key],
						tolerance: zoomTolerance
										},
										layout: {
											'line-join': 'round',
											'line-cap': 'round',
										},
										paint: {
											'line-color': DefaultFeaturesColors.LineString,
											'line-width': 2,
										},
									});
								} else if (key === 'Polygon') {
									map.current.addLayer({
										id: key,
										type: 'fill',
										source: {
											type: 'geojson',
											data: Collections[key],
						tolerance: zoomTolerance
										},
										layout: {},
										paint: {
											'fill-color': DefaultFeaturesColors.Polygon,
											'fill-opacity': 1,
										},
									});
								} else if (key.includes('Polygon')) {
									map.current.addLayer({
										id: key,
										type: 'fill',
										source: {
											type: 'geojson',
											data: Collections[key],
						tolerance: zoomTolerance
										},
										layout: {},
										paint: {
											'fill-color': MapPriorityColor[key.replace('Polygon - ', '')],
											'fill-opacity': 1,
										},
									});
								} else {
									map.current.addLayer({
										id: key,
										type: 'line',
										source: {
											type: 'geojson',
											data: Collections[key],
						tolerance: zoomTolerance
										},
										layout: {
											'line-join': 'round',
											'line-cap': 'round',
										},
										paint: {
											'line-color': MapPriorityColor[key.replace('LineString - ', '')],
											'line-width': 2,
										},
									});
									map.current.addLayer({
										id: 'goTo-' + key,
										type: 'line',
										source: {
											type: 'geojson',
											data: Collections[key],
						tolerance: zoomTolerance
										},
										layout: {
											'line-join': 'round',
											'line-cap': 'round',
										},
										paint: {
											'line-color': 'white',
											'line-width': 2,
										},
										filter: ['in', 'DBID', ''],
									});
								}
							});
						}
	
					}, 2000);
		
		
	
	}, [geoData, map?.current?.style?.stylesheet?.name]);

	useEffect(() => {
		if (!map.current) return; // wait for map to initialize
		if (!rezoom) return;

		let data = [].concat.apply([], Object.values(geoData));
		const temp = [];
		data.forEach((feature) => {
			if (feature.geometry.type === 'GeometryCollection') {
				data = data.filter(
					(f) => f.properties.DBID !== feature.properties.DBID,
				);
				feature.geometry.geometries.forEach((geom) => {
					temp.push({ ...feature, geometry: geom });
				});
			}
		});

		data = [...data, ...temp];

		if (data.length === 0) {
			map.current.flyTo({
				center: { lat: 0, lng: 0 },
				zoom: 1,
			});
			return;
		}
		const initalCenter = averageGeolocation(
			data.map((feature) => {
				if (feature.geometry.type === 'Point')
					return {
						lat: feature.geometry.coordinates[1],
						lng: feature.geometry.coordinates[0],
					};
				if (feature.geometry.type === 'LineString') {
					return averageGeolocation(
						feature.geometry.coordinates.map((coord) => {
							return { lat: coord[1], lng: coord[0] };
						}),
					);
				} else {
					return averageGeolocation(
						feature.geometry.coordinates[0].map((coord) => {
							return { lat: coord[1], lng: coord[0] };
						}),
					);
				}
			}),
		);

		const bounds = getBounds(
			data
				.map((feature) => {
					if (feature.geometry.type === 'Point')
						return {
							lat: feature.geometry.coordinates[1],
							lng: feature.geometry.coordinates[0],
						};
					if (feature.geometry.type === 'LineString') {
						return feature.geometry.coordinates.map((coord) => {
							return { lat: coord[1], lng: coord[0] };
						});
					} else {
						return feature.geometry.coordinates[0].map((coord) => {
							return { lat: coord[1], lng: coord[0] };
						});
					}
				})
				.flat(),
		);
		const { clientHeight, clientWidth } = mapContainer.current;
		const newZoom = getBoundsZoomLevel(bounds, {
			width: clientWidth,
			height: clientHeight,
		});

		map.current.flyTo({
			center: initalCenter,
			zoom: newZoom,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geoData]);

	//Manage 2D / 3D button clicks
	const ButtonClickAtion = (Dimension) => {
		let MapboxwidthValue = '';
		let PotreewidthValue = '';
		
		if (Dimension == '2D'){
				//Change the background
					if(TwoDBorderWidth == '0px'){
						set2DBorderWidth('3px')
						
						settwoDbackrColor(DarkGreen.ButtonHighlightColor)
						//console.log(ThreeDMapWindowsize)
						if(ThreeDMapWindowsize == '0%'){
							setTwoDMapWindowsize('100%')
								MapboxwidthValue = '100%'
						}else {
							setTwoDMapWindowsize('50%')
								MapboxwidthValue = '50%'
							setThreeDMapWindowsize('50%')
							setThreeDMapWindowheight('100%')
								PotreewidthValue = '50%'
						}
						
					}else{
						set2DBorderWidth('0px')
						settwoDbackrColor('transparent')

						setTwoDMapWindowsize('0%')
							MapboxwidthValue = '0%'
						setThreeDMapWindowsize('100%')
						setThreeDMapWindowheight('100%')
							PotreewidthValue = '100%'
					}
		}else {
			
				//Change the background
					if(THREEDBorderWidth == '0px'){
						set3DBorderWidth('3px')
						setthreeDbackrColor(DarkGreen.ButtonHighlightColor)


						if(TwoDMapWindowsize == '0%'){
							setThreeDMapWindowsize('100%')
							setThreeDMapWindowheight('100%')
								PotreewidthValue = '100%'
						}else {
							setThreeDMapWindowsize('50%')
							setThreeDMapWindowheight('100%')
								PotreewidthValue = '50%'
							setTwoDMapWindowsize('50%')
								MapboxwidthValue = '50%'
						}

						setThreeDMapPosition('relative')

					}else{
						set3DBorderWidth('0px')
						setthreeDbackrColor('transparent')

						setThreeDMapWindowsize('1%')
						setThreeDMapWindowheight('1%')
							setThreeDMapPosition('absolute')
							PotreewidthValue = '0%'

						setTwoDMapWindowsize('100%')
							MapboxwidthValue = '100%'
					}

		}

		//If the user hide both map we hide them both
			if (PotreewidthValue == '0%' && MapboxwidthValue == '0%'){
				set2DBorderWidth('0px')
				set3DBorderWidth('0px')
				setthreeDbackrColor('transparent')
				settwoDbackrColor('transparent')

				setTwoDMapWindowsize('0%')
				setThreeDMapWindowsize('1%')
				setThreeDMapWindowheight('1%')
			}
		//Resize the Map
			//var mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0];
			var mapDiv = mapContainer.current;
			mapDiv.style.width = MapboxwidthValue;
            //mapCanvas.style.width = MapboxwidthValue;

			map.current.resize();

	}

	//Add a listener to disable 2D 3D switcher if only one is displayed

			useEffect(() => {
					
					let twoDbutton = document.getElementById("twoDswitch")
					let threeDbutton = document.getElementById("threeDswitch")

					if(TwoDMapWindowsize =='100%') {
						
						twoDbutton.style.pointerEvents = 'none'

					}else if (ThreeDMapWindowsize == '100%'){
						
						threeDbutton.style.pointerEvents = 'none'
						
					} else {
						twoDbutton.style.pointerEvents = 'auto'
						threeDbutton.style.pointerEvents = 'auto'
					}
			}, [ThreeDMapWindowsize,TwoDMapWindowsize])

	const CesiumViewerObj = (CesiumViewer) => {
		setCESIUMVIEWER(CesiumViewer)
	}
	const CesiumObj = (cesium) => {
		setCesium(cesium)
	}

	const potreeViewerobj = (potreeViewerobj) => {
		setpotreeViewerobj(potreeViewerobj)
	}

	const DataProjection  = (Projection) => {
		setDataProjection(Projection)
	}
	const MapProjection = (Projection) => {
		setMapProjection(Projection)
	}
	
	function getElementByXpath(path) {
		return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
	  }
	const fullscreen = () => {
		let MapContainer = document.getElementById('HallMapContainer');
		let header = document.getElementById('header')
		let sidebar = document.getElementById('sidebar')

		let mapBoxContainer = document.getElementById('mapContainer');
		
		let ableFullScreen = {"ableFullScreen":true, "url":"/full-screen-display-button-1.svg"}
		let UnAbleFullScreen = {"ableFullScreen":false, "url":"/full-screen-button-7.svg"}
		
		
		if(fullScreenIcon.ableFullScreen == true){
			//let optPointSizing = document.getElementById('optPointSizing');
		
			//MapContainer.appendChild(document.querySelector('#optPointSizing-menu'));
			
			
			MapContainer.style.position= 'absolute';
			MapContainer.style.width= '100vw';
			MapContainer.style.height= '100vh';
			MapContainer.style.left= '-161px';
			MapContainer.style.top= '-113px';
			MapContainer.style.zIndex= '2';

			// MapContainer.scrollIntoView();

			header.style.display = 'none'

			sidebar.style.display = 'none'

			setTimeout(() => MapContainer.scrollIntoView(), 200);

			//let TopheadMenu = getElementByXpath("/html/body/div[1]/div/div/div[3]") ;
			//console.log(TopheadMenu)
			//TopheadMenu.style.color = 'none'
			
			//document.getElementById('');
			//let from = document.querySelector("#optMaterial-menu");
			//console.log(MapContainer,from)

			//MapContainer.append(from);

			//MapContainer.requestFullscreen({ navigationUI: "show" })
			//setfullScreenIcon(UnAbleFullScreen)

			//Resize the Map
			//var mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0];
			
			//var mapDiv = mapContainer.current;
			//mapDiv.style.width = '50vw';
			//mapDiv.style.height = '100vh';

			//console.log(TwoDMapWindowsize,ThreeDMapWindowsize)
			


			//define Mapbox / Cesium depending on previous style before the full screen
				if(TwoDMapWindowsize == '50%') {
					mapBoxContainer.style.width= '50vw';
    				mapBoxContainer.style.height= '100vh';
					setThreeDMapWindowsize('50%');
					setThreeDMapWindowheight('100%');
					set2DBorderWidth('3px')

					

				} else if (TwoDMapWindowsize == '100%'){
					mapBoxContainer.style.width= '100vw';
    				mapBoxContainer.style.height= '100vh';

					setThreeDMapWindowsize('1%');
					setThreeDMapWindowheight('1%');

					set2DBorderWidth('3px')
				} else {
					// 0%
					mapBoxContainer.style.width= '0%';
    				mapBoxContainer.style.height= '100vh';

					setThreeDMapWindowsize('100%');
					setThreeDMapWindowheight('100%');

					set2DBorderWidth('0px')
				}
			


			setfullScreenIcon(UnAbleFullScreen)
            //mapCanvas.style.width = MapboxwidthValue;


			
			//setTwoDMapWindowsize('50%');
			

			map.current.resize();

			
			//If it's a project page we move the right menu switcher
				let page = window.location.pathname;
				if(page.includes('/projects/')){
					//It's single project vue
						setrightSwitcherMenuHeight('1rem')
					//change the top left
						

				} 

				if(page.includes('/powerlines/')){
					//It's single project vue
						setrightSwitcherMenuHeight('1rem')
				}

		}else {
			//If it's a project page we move the right menu switcher
			
			if(window.location.pathname.includes('/projects/')){
				//It's single project vue
					setrightSwitcherMenuHeight('2.5rem')

					
			}
			//document.exitFullscreen()
			setfullScreenIcon(ableFullScreen)

			MapContainer.style.position= 'unset';
			MapContainer.style.width= '100%';
			MapContainer.style.height= '100%';
			//MapContainer.style.left= '-144px';
			//MapContainer.style.top= '-231px';
			
			header.style.display = 'flex'

			
			sidebar.style.display = 'unset'

			
            //mapCanvas.style.width = MapboxwidthValue;


			
			//define Mapbox / Cesium depending on previous style before the full screen
			if(TwoDMapWindowsize == '50%') {
				mapBoxContainer.style.width= '50%';
				mapBoxContainer.style.height= '100%';
				setThreeDMapWindowsize('50%');
				setThreeDMapWindowheight('100%');
				set2DBorderWidth('3px')

				document.getElementById("twoDswitch").click();
			setTimeout(() => {
				document.getElementById("twoDswitch").click();
			  }, 1000)
			  

			} else if (TwoDMapWindowsize == '100%'){
				mapBoxContainer.style.width= '100%';
				mapBoxContainer.style.height= '100%';

				setThreeDMapWindowsize('1%');
				setThreeDMapWindowheight('1%');

				set2DBorderWidth('3px')
			} else {
				// 0%
				mapBoxContainer.style.width= '0%';
				mapBoxContainer.style.height= '100%';

				setThreeDMapWindowsize('100%');
				setThreeDMapWindowheight('100%');

				set2DBorderWidth('0px')
			}

			//var mapDiv = mapContainer.current;
			//mapDiv.style.width = '50%';
			//mapDiv.style.height = '100%';
			map.current.resize();

			
			
			//If it's a project page we move the right menu switcher
			let page = window.location.pathname;
			if(page == '/projects' || page == '/projects/'){
				//It's the projects page
					setrightSwitcherMenuHeight('1rem')
			}else if(page.includes('/projects/')){
				//It's single project vue
					setrightSwitcherMenuHeight('2.5rem')
			}else if(page.includes('/powerlines/')){
				//It's single project vue
					setrightSwitcherMenuHeight('7rem')
			}			else if(page.includes('/missions/new?')){
				//It's a new mission
				setrightSwitcherMenuHeight('7rem')
			}

			//document.getElementById("mapBoxContainer").click();
			
			
		}
		
	}

	const FireButtonClickAtion = (Dimension) => {
		console.log(Dimension)
		ButtonClickAtion(Dimension)
	}

	const handleSizeClick = (newSize) => {
		setSize(newSize)
		onOpen()
	  }

	  useEffect(() => {
		return () => {
			// componentwillunmount in functional component.
			// Anything in here is fired on component unmount.
			let header = document.getElementById('header')
			let sidebar = document.getElementById('sidebar')

			header.style.display = 'flex'

	
			sidebar.style.display = 'unset'
			
		}
	}, [])
	  

	const setMaterial = (material) => { // pointcloud styler
		setTimeout(function () {
			if (material === "rgb") {
				PotreeViewer.scene.scenePointCloud.children.forEach((child) => {
					if (child.pcoGeometry) {
					child.material._activeAttributeName = "rgba";
					}
				});
			}
			if (material === "classification") {
				PotreeViewer.setClassifications({
					20:      { visible: true, name: 'trees' , color: [0, 0, 0, 255] },
					2:       { visible: true, name: 'Ground' , color: [150, 150, 150, 255] },
					3:       { visible: true, name: 'Low Vegetation' , color: [253, 212, 1, 255] },
					4:       { visible: true, name: 'Mid Vegetation' , color: [	 40,100, 0,255] },
					5:       { visible: true, name: 'High Vegetation' , color: [40, 0, 0, 255] },
					11:      { visible: true, name: 'Wires' , color: [200, 20, 200, 255] },
					21:      { visible: true, name: 'Poles' , color: [2,2, 2, 255] },
					DEFAULT: { visible: true, name: 'other' , color: [100, 100,100, 255] },
				});
				// const scheme = {};
				// for(let i = 0; i < 32; i++){
				// 	scheme[i] = { visible: true, name: `random:_${i}`, color: [Math.random(), Math.random(), Math.random(), 1.0] };
				// }
				// PotreeViewer.setClassifications(scheme);

				PotreeViewer.scene.scenePointCloud.children.forEach((child) => {
					if (child.pcoGeometry) {
					child.material._activeAttributeName = "classification";
					}
				});
			}

			

		}, 50);
	}


	  const mystyles = {
		position: ThreeDMapPosition,
	 } as React.CSSProperties;

	 //Chck the actual page to define right menu switcher position
		useEffect(() => {
			let page = window.location.pathname;
			console.log(page,page.includes('/projects/'),page.includes('/powerlines/'))
			if(page == '/projects' || page == '/projects/'){
				//It's the projects page
					setrightSwitcherMenuHeight('1rem')
			}else if(page.includes('/projects/')){
				//It's single project vue
					setrightSwitcherMenuHeight('2.5rem')
			} else if (page.includes('/powerlines/')){
				//It's single project vue
					setrightSwitcherMenuHeight('7rem')
			}else if(page.includes('/missions/new?')){
				//It's a new mission
				setrightSwitcherMenuHeight('7rem')

		}
			
		},[])
	return (
		<>
		

	<div id="HallMapContainer" style={{width: '100%',height: '100%'}}>


				<Box //potree Box 
												///
					cursor="pointer"
					
					overflow="hidden"
					h={ThreeDMapWindowheight}
					w={ThreeDMapWindowsize}
					float="left" 
					
					style={mystyles}
				>
						<Potree projectId={projectId} FireButtonClickAtion={FireButtonClickAtion} DataProjection={DataProjection} MapProjection={MapProjection} CesiumViewerObj={CesiumViewerObj} CesiumObj={CesiumObj} potreeViewerobj={potreeViewerobj} />
					
					<HStack position={'absolute'} bottom="1rem" zIndex={9999} right="2rem" >
						<Button 
							fontSize={"14px"}
							w="120px"
							h="30px"
							bg={DarkGreen.layersBoxColor} 
							color={DarkGreen.ButtonHighlightColor} 
							onClick={() => setMaterial("classification")}> {t("Classification")} 
						</Button>
						<Button 
							fontSize={"14px"}
							w="50px"
							h="30px"
							bg={DarkGreen.layersBoxColor} 
							color={DarkGreen.ButtonHighlightColor} 
							onClick={() => setMaterial("rgb")} > {t("RGB")} 
						</Button>
					</HStack>
				</Box> 

				<Box
					cursor="pointer"
					pos="relative"
					overflow="hidden"
					h="100%"
					w={TwoDMapWindowsize}
					//w="100%"
					ref={mapContainer}
					id="mapContainer"
					className="project"
					float="left"
					zIndex="1"
				>
			{loading && (
				<>
					<Player
						src="/assets/loading.json"
						autoplay
						loop
						style={{
							position: 'absolute',
							left: '50%',
							top: '50%',
							transform: 'translate(-50%,-50%)',
							zIndex: '2',
							height: "200px",
							width: "200px",
						}}
					/>
					<Box
						pos="absolute"
						zIndex={1}
						w="100%"
						h="100%"
						bg="rgba(0,0,0,0.5)"
					></Box>
				</>
			)}
			{(areaMeasure > 0 || lineMeasure > 0) && (
				<Box
					pos="absolute"
					bottom="2rem"
					right="4rem"
					bg="whiteSmoke"
					borderRadius="0.25rem"
					p="1rem"
					minW="40px"
					minH="40px"
					zIndex={99}
				>
					<VStack alignItems="flex-start" spacing={0}>
						{areaMeasure && (
							<Text color="black">
								<b>Area: </b>
								{areaMeasure} ㎡
							</Text>
						)}
						{lineMeasure && (
							<Text color="black">
								<b>Line: </b>
								{lineMeasure.toFixed(2)} km
							</Text>
						)}
					</VStack>
				</Box>
			)}
			{isOpen && (
				<Box
					pos="absolute"
					top="1rem"
					left="1rem"
					bg={DarkGreen.layersBoxColor}
					borderRadius="0.25rem"
					p="1rem"
					w="213px"
					maxH="300px"
					overflow="auto"
					zIndex={2}
					dir="rtl"
				>
					<HStack dir="ltr" justify="space-between">
						<Accordion defaultIndex={[0]} allowToggle w="full">
							<AccordionItem borderColor="transparent">
								<HStack justifyContent="space-between">
									<Text fontSize="lg" fontWeight="semibold" color="gray.500">
										{t('Properties')}
									</Text>
									<AccordionButton w="auto" p="0">
										<AccordionIcon color="gray.500" />
									</AccordionButton>
								</HStack>
								<AccordionPanel p={'0.5rem 0.5rem 0.5rem 0'}>
									{Object.keys(properties).map((key, index) => (
										<Text
											color="muted"
											textAlign={'left'}
											key={index}
											mt="0.25rem"
										>
											<b>{key}:</b> {properties[key]}
										</Text>
									))}
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
					</HStack>
				</Box>
			)}
			
		</Box>


 

{/* 
			<Box
					cursor="pointer"
					pos="relative"
					overflow="hidden"
					
					w={"fit-content"}
					style={{zIndex: 1,top: '-33px',right: '-49%' }}
  		
				>
					
		<Button style={{padding: "8px",borderRadius: '7px 7px 7px 7px',width: '1px',height:'30px',background:'white',borderColor: "DarkGreen.ButtonHighlightColor",borderWidth: "3px"}} onClick={() => fullscreen()}><img src={fullScreenIcon.url} /></Button>
		

		</Box>  */}

		<Box // layersbox
				pos="absolute" 
				top={isProjectDetails? "6.5rem":rightSwitcherMenuHeight}
				right="1rem"
				bg={DarkGreen.layersBoxColor}
				border = "1px solid"
				borderColor = {DarkGreen.layersBoxBorderColor}
				borderRadius="0.25rem"
				p="1rem"
				w="213px"
				maxH="300px"
				overflow="auto"
				zIndex={1}
			>
			<HStack //layerbox Buttons
			>
				<Button 
					id="twoDswitch" 
					background = {twoDbackrColor}
					borderColor={DarkGreen.layersBoxBorderColor}

					_hover={{
						bg : DarkGreen.ButtonHighlightColor
					}}
					onClick={() => ButtonClickAtion('2D')} 
					style={{width: '20px',height: '30px',borderWidth: '1px',marginLeft: '26px',marginRight: '7px',marginBottom:'10px',}}>2D</Button>
				<Button  
					id="threeDswitch"
					onClick={() => ButtonClickAtion('3D')} 
					style={{width: '20px',height:'30px',marginBottom:'10px',background:threeDbackrColor, borderWidth: '1px', borderColor:DarkGreen.layersBoxBorderColor}}>3D</Button>
				<Button 
					bg="rgba(255,255,255,0.9)"//{ DarkGreen.ButtonHighlightColor }//
					style={{marginLeft: '6px',padding: "2px",borderRadius: '7px 7px 7px 7px',width: '1px',height:'30px',borderColor: DarkGreen.layersBoxBorderColor ,borderWidth: "3px",marginBottom:'10px',}} 
					_hover={{
						bg : DarkGreen.ButtonHighlightColor
					}}
					onClick={() => fullscreen()}
					>

					<img width="20px" id= "FullScreenIcon"  src= {fullScreenIcon.url} />
					</Button>

			</HStack>

				{/* 			
				<Button id="twoDswitch" onClick={() => ButtonClickAtion('2D')} style={{width: '20px',height: '30px',background: twoDbackrColor,borderWidth: '0px',marginLeft: '20px',marginRight: '7px'}}>2D</Button>
					<Button  id="threeDswitch" onClick={() => ButtonClickAtion('3D')} style={{width: '20px',height:'30px',background:threeDbackrColor}}>3D</Button>
					<Button style={{padding: "8px",borderRadius: '7px 7px 7px 7px',width: '1px',height:'30px',background:'white',borderColor: "DarkGreen.ButtonHighlightColor",borderWidth: "3px",marginLeft: "7px"}} 
							onClick={() => fullscreen()}>
							<img src={fullScreenIcon.url} />
					</Button> */}

				<HStack justify="space-between">
					
					<Accordion defaultIndex={[99]} allowToggle w="full">
						<AccordionItem borderColor="transparent">
							<HStack justifyContent="space-between">
								
								<Text fontSize="lg" fontWeight="semibold" color={DarkGreen.layersBoxBorderTextColor}>
									{t('2D Layers')}
								</Text>
								<HStack spacing={0}>
									<AccordionButton p="0">
										<AccordionIcon color={DarkGreen.iconColor} />
									</AccordionButton>
								</HStack>
							</HStack>
							<AccordionPanel p={'0.5rem 0.5rem 0.5rem 0'}>
								<Layer
									isDronodat={isDronodat}
									onChange={(newData) => {
										const projectId = Object.keys(newData)[0];
										const tempGeoData = { ...geoData };
										delete tempGeoData[projectId];
										if (
											[].concat(...Object.values({ ...newData })).length >=
											[].concat(...Object.values(geoData)).length
										)
											setRezoom(true);
										else setRezoom(false);
										setGeoData({
											...newData,
										});
									}}
									project={{
										id: projectId,
										name: '',
									}}
									setLoading={setLoading}
									customRefresh={isUploading}
								/>
								<Accordion mt="0.5rem" defaultIndex={[0]} allowToggle w="full">
									<AccordionItem borderColor="transparent">
										<AccordionButton gap="0.5rem" p="0">
											<Text fontSize="sm" color={DarkGreen.layersBoxBorderTextColor}>
												{t('map.MapStyle')}
											</Text>
											<AccordionIcon color={DarkGreen.iconColor}  />
										</AccordionButton>
										<AccordionPanel py="0.5rem">
											<RadioGroup
												flexDir="column"
												display="flex"
												gap="0.5rem"
												defaultValue="mapbox://styles/mapbox/dark-v10"
												color={DarkGreen.layersBoxBorderTextColorMuted}
												onChange={(v) => {
													map.current.setStyle(v);

													setTimeout(() => {
														map.current.loadImage(
															'/assets/images/power.png',
															(error, image) => {
																if (error) return;
																map.current.addImage('power', image);
																setRefresh(refresh + 1);
															},
														);
													}, 500);
												}}
											>
												<Radio
													size="sm"
													colorScheme="gray"
													value="mapbox://styles/mapbox/dark-v10"
												>
													{t('map.Dark')}
												</Radio>
												<Radio
													size="sm"
													colorScheme="gray"
													value="mapbox://styles/dronodat/cl905vm02005815pr4jhim0rz"
												>
													{t('map.DarkGreen')}
												</Radio>
												<Radio
													size="sm"
													colorScheme="gray"
													value="mapbox://styles/mapbox/satellite-v9"
												>
													{t('map.Satellite')}
												</Radio>
											</RadioGroup>
										</AccordionPanel>
									</AccordionItem>
								</Accordion>
							</AccordionPanel>
							

						</AccordionItem>

						<AccordionItem borderColor="transparent" >
							
							<HStack justifyContent="space-between">
								<Text fontSize="lg" fontWeight="semibold" color={DarkGreen.layersBoxBorderTextColor} >
									{t('3D Layers')}
								</Text>
								<HStack spacing={0}>
									<AccordionButton p="0">
										<AccordionIcon color={DarkGreen.iconColor}  />
									</AccordionButton>
								</HStack>
							</HStack>
							<AccordionPanel style={{position: 'relative',top: '0px', left: '-29px'}}  p={'0.5rem 0.5rem 0.5rem 0'}>
							
									<div id="scene_objects"></div>

							</AccordionPanel>

						</AccordionItem>

					</Accordion>
				</HStack>
			</Box>
		
		
	</div>



	
		

		
		</>

	);
}

export default Map;
