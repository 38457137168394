import { theme } from '@chakra-ui/pro-theme';
import { extendTheme } from '@chakra-ui/react';
import styles from './styles';
import colors from './colors';
import Text from './components/Text';
import Input from './components/Input';

export default extendTheme(
	{
		components: {
			// /components
			Text,
			Input,
		},
		config: {
			initialColorMode: 'light',
			useSystemColorMode: false,
		},
		styles,
		colors,
		fonts: {
			heading: 'Montserrat, sans-serif',
			body: 'Montserrat, sans-serif',
		},
	},
	theme,
);
