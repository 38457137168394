import { useDisclosure } from '@chakra-ui/react';
import jwt_decode from 'jwt-decode';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import {  verifyToken } from './models/auth';
import { Actions } from './shared/enums';
import { getCookie, removeAllCookies, scrollToTop } from './shared/helpers';
import { PAGES } from './shared/routes';

export interface GlobalStateInterface {
	isAuthLoading: boolean;
	isAuthenticated: boolean;
	error: Error | null;
	token: String | null;
	user: any;
}

export const initialGlobalState: GlobalStateInterface = {
	isAuthLoading: true,
	isAuthenticated: false,
	error: null,
	token: null,
	user: {},
};

export const reducer = (
	state: GlobalStateInterface,
	action: { type: string; payload? },
) => {
	switch (action.type) {
		case Actions.TOGGLE_AUTH_LOADING:
			return {
				...state,
				isAuthLoading: !state.isAuthLoading,
			};
		case Actions.TOGGLE_AUTHENTICATED:
			return {
				...state,
				isAuthenticated: !state.isAuthenticated,
			};
		case Actions.SET_AUTH_USER:
			return {
				...state,
				user: action.payload,
			};
		case Actions.SET_ERROR:
			return {
				...state,
				error: action.payload,
			};
		case Actions.SET_TOKEN:
			return {
				...state,
				token: action.payload,
			};
	}
	return state;
};

export const AppContext = createContext({
	state: initialGlobalState,
	dispatch: (value) => {},
	isOpen: false,
	onOpen: () => {},
	onClose: () => {},
	isAuthorized: (roles) => {
		return undefined;
	},
});

export const Store = (props) => {
	const [state, dispatch] = useReducer(reducer, initialGlobalState);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const path = window.location.pathname;

	const isAuthorized = (allowedRoles: any[]) => {
		const subdomain = window.location.host.split('.')[1]
			? window.location.host.split('.')[0]
			: false;

		if (state.isAuthLoading || !state.user.groups) return;
		let canAccess = false;
		state.user.groups.forEach((group) => {
			const groupName =  subdomain !== 'app' ? `client-${group.name}` : group.name;
			//@ts-ignore
			if (allowedRoles.includes(groupName)) canAccess = true;
		});

		return canAccess;
	};

	useEffect(() => {
		(async () => {
			try {
				await verifyToken();
				const tokenPayload: any = jwt_decode(getCookie('refresh_token'));
				dispatch({
					type: Actions.SET_AUTH_USER,
					payload: {
						id: tokenPayload.user_id,
						groups: tokenPayload.groups,
					},
				});
				dispatch({ type: Actions.TOGGLE_AUTHENTICATED });
			} catch {
				removeAllCookies();
				if (!window.location.href.includes(PAGES.LOGIN) && !window.location.href.includes(PAGES.RESET_PASSWORD)) navigate(PAGES.LOGIN);
			} finally {
				dispatch({ type: Actions.TOGGLE_AUTH_LOADING });
			}
		})();
	}, []);

	useEffect(() => {
		const container: HTMLElement = document.getElementById('container');
		scrollToTop(container);
	}, [path]);

	return (
		<AppContext.Provider value={{ state, dispatch, isAuthorized,isOpen, onOpen, onClose }}>
			{<>{props.children}</>}
		</AppContext.Provider>
	);
};

export function useStore() {
	return useContext(AppContext);
}
