export const UserTypes = {
	ADMIN: 'admin',
	NORMAL: 'normal',
};

export const ResponseCodes = {
	SUCCESS: 200,
	CREATED: 201,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	CONFLICT: 409,
	INTERNAL_ERROR: 500,
};

export const Actions = {
	TOGGLE_AUTH_LOADING: 'toggle_auth_loading',
	TOGGLE_AUTHENTICATED: 'toggle_authenticated',
	SET_AUTH_USER: 'set_auth_user',
	SET_ERROR: 'set_error',
	SET_TOKEN: 'set_token',
};

export const PaginationOptions = {
	offset: 0,
	total: 0,
	limit: 5,
	currentPage: 1,
};
export const NewMissionPaginationOptions = {
	offset: 0,
	total: 0,
	limit: 20,
	currentPage: 1,
};

export const Status = {
	1: 'Pending',
	2: 'InProgress',
	3: 'Done',
};

export const DarkGreen = {
	mapboxgraybox:'rgb(10 18 16 / 30%)',

	iconColor: 'rgba(0, 196, 106, 1)',
	backgroundColor: 'rgba(2, 10, 8, 0.97)',//'rgba(255, 255, 255,1)',
	//Buttons:
	ButtonBackgroundColor:'rgba(2, 10, 8, 0.97)',
	ButtonHighlightColor:'rgba(0, 196, 106, 0.5)',
	ButtonBorderColor:'rgb(48 48 48 / 100%)',
	//Input Fields
	inputBackgroundColor:"rgb(10 18 16 / 93%)",
	inputBorderColor:"rgb(48 48 48 / 100%)",
	inputTextColor:"rgb(128 128 128)",
	//Table
	tableHeaderColor: 'transparent',//'rgba(0, 0, 0, 0.3) 0% 0% no-repeat padding-box;' ,
	tableBackgroundColor: 'rgb(32 26 26 / 32%)',// "rgb(48 48 48 / 32%)" ,//"rgb(17 18 18 / 28%)",//'rgba(36, 40, 40, 0.3)' ,
	tableHighlightedColor: 'rgba(0, 196, 106, 0.95)',
	tableBorderColor: "rgb(48 48 48 / 100%)",
	//Sidebar
	sidebarColor: "transparent linear-gradient(180deg, #02b7b700 0%, #032e1996 0%, #0250491f 40%, #01060600 100%) 0% 0% no-repeat padding-box",//'transparent linear-gradient(180deg, #02B7B7 0%, #032E198A 0%, #02504926 84%, #015C5900 100%) 0% 0% no-repeat padding-box' ,
	sidebarColorResponsive: 'rgba(1, 30, 19, 1)',//'rgba(0, 46, 23, 0.6)'
	//Layers Box
	layersBoxColor:"rgb(10 18 16 / 75%)",//"rgba(1, 30, 19, 1)",//"rgb(80 78 78)", //"whitesmoke",//'rgb(37 38 37 / 98%)',
	layersBoxBorderColor: "rgb(48 48 48 / 100%)",
	layersBoxBorderTextColor:"rgb(255 255 255)",//'rgb(174 182 195/ 100%)',
	layersBoxBorderTextColorMuted: 'gray',
	//properties box
	propertiesBoxColor: "rgb(57 62 61)",
	propertiesBoxBorderColor: 'rgb(58 88 75 / 53%)',

	
}


export const StatusColors = {
	1: '#ff0000',
	2: '#0000FF',
	3: '#00FF00',
};

export const MissionTypes = {
	1: 'Flight',
	2: 'Trimming',
};

export const Roles = {
	ADMIN: 'manager',
	EMPLOYEE: 'employee',
	CLIENT_ADMIN: 'client-manager',
	CLIENT_EMPLOYEE: 'client-employee',
};

export const MAP_COLORS = {
	red: '#FA5050', //High
	yellow: '#FDD401', //Mid
	blue: '#396da8', //Low
	green: '#30d130', //None
};

export const MapPriorityColor = {
	'01 - Immediate': MAP_COLORS.red,
	'1M - 1 Month': MAP_COLORS.yellow,
	'3M - 3 Months': MAP_COLORS.blue,
	'None': MAP_COLORS.green,
};

export const MapLayers = [
	"assigned-other",
	'LineString',
	'Point',
	'Polygon',
	'Polygon - 01 - Immediate',
	'Polygon - 1M - 1 Month',
	'Polygon - 3M - 3 Months',
	'Polygon - None',
	'LineString - 01 - Immediate',
	'LineString - 1M - 1 Month',
	'LineString - 3M - 3 Months',
	'LineString - None',
	'highlighted-LineString',
	'highlighted-LineString - 01 - Immediate',
	'highlighted-LineString - 1M - 1 Month',
	'highlighted-LineString - 3M - 3 Months',
	'highlighted-LineString - None',
	'highlighted-Polygon',
	'highlighted-Polygon - 01 - Immediate',
	'highlighted-Polygon - 1M - 1 Month',
	'highlighted-Polygon - 3M - 3 Months',
	'highlighted-Polygon - None',
	'highlighted-Point',
];

export const DefaultFeaturesColors = {
	LineString: '#529cd4',
	Polygon: '#329cd4',
};
