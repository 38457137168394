import { HStack, Text, Button, Icon } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	AiOutlineDoubleLeft,
	AiOutlineDoubleRight,
	AiOutlineLeft,
	AiOutlineRight,
} from 'react-icons/ai';
import Select from '../Select';
import { Option } from '../Select/Option';
import { DarkGreen } from '../../../shared/enums';
import { useLocation } from 'react-router-dom';

function NewMissionPaginator({
	total,
	isLoading,
	onPageChange,
	onPerPageChange,
	currentPage,
}) {

	const [perPage, setPerPage] = useState<any>(20);
	const { t } = useTranslation();

	useEffect(() => {
		onPerPageChange(perPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [perPage]);

	return (
		<HStack
			rowGap="1rem"
			w="full"
			justify="space-between"
			flexDir={{ lg: 'row', base: 'column' }}
		>
			<HStack gap={{ lg: '2rem', base: '0.5rem' }}>
				<Select
					name="PerPage"
					color="white"
					background= {DarkGreen.inputBackgroundColor}
					borderColor={DarkGreen.ButtonBorderColor}
					value={perPage}
					onChange={setPerPage}
					placeholder="Items Per Page"
					whiteSpace="nowrap"
					w="4.25rem"
					size="sm"
				>	
					<Option value={5}>5</Option>
					<Option value={10}>10</Option>
					<Option value={20}>20</Option>
					<Option value={50}>50</Option>
                    <Option value={50}>50</Option>
                    <Option value={99999}>{t("all")}</Option>
				</Select>
				<Text color={DarkGreen.tableBorderColor} >
					{t('Showing')} {currentPage * perPage - perPage + 1} -{' '}
					{currentPage * perPage > total ? total : currentPage * perPage}{' '}
					{t('of')} {total} {t('results')}
				</Text>
			</HStack>
			<HStack  spacing={0} gap=".5rem">
				<Button 
					background="transparent"
					isDisabled={isLoading || currentPage === 1}
					borderRadius={'0.25rem'}
					borderWidth="2px"
					borderColor="rgba(0,0,0,0)"
					color = {DarkGreen.iconColor}
					size="sm"
					px={'0.5rem'}
					onClick={() => {
						onPageChange(1);
					}}
					_hover={{
						color: 'black',
						background: 'white',
					}}
				>
					<Icon color = {DarkGreen.iconColor} as={AiOutlineDoubleLeft} />
				</Button>
				<Button
					background="transparent"
					isDisabled={isLoading || currentPage === 1}
					borderRadius={'0.25rem'}
					borderWidth="2px"
					borderColor="rgba(0,0,0,0)"

					color="white"
					size="sm"
					px={'0.5rem'}
					onClick={() => {
						onPageChange(currentPage - 1);
					}}
					_hover={{
						color: 'black',
						background: 'white',
					}}
				>
					<Icon color = {DarkGreen.iconColor}  as={AiOutlineLeft} />
				</Button>
				<Button
					background="transparent"
					isDisabled={isLoading}
					borderRadius={'0.25rem'}
					borderWidth="2px"
					borderColor={DarkGreen.iconColor}//"white"
					color = {DarkGreen.iconColor}
					size="sm"
					px={'0.5rem'}
					onClick={() => {}}
					_hover={{
						color: 'black',
						background: 'white',
					}}
				>
					{currentPage}
				</Button>
				<Button
					background="transparent"
					isDisabled={
						isLoading ||
						currentPage === Math.ceil(total / perPage) ||
						total === 0
					}
					borderRadius={'0.25rem'}
					borderWidth="2px"
					borderColor="rgba(0,0,0,0)"
					color="white"
					size="sm"
					px={'0.5rem'}
					onClick={() => {
						onPageChange(currentPage + 1);
					}}
					_hover={{
						color: 'black',
						background: 'white',
					}}
				>
					
					<Icon color = {DarkGreen.iconColor}  as={AiOutlineRight} />
				</Button>
				<Button
					background="transparent"
					isDisabled={
						isLoading ||
						currentPage === Math.ceil(total / perPage) ||
						total === 0
					}
					borderRadius={'0.25rem'}
					borderWidth="2px"
					borderColor="rgba(0,0,0,0)"
					color="white"
					size="sm"
					px={'0.5rem'}
					onClick={() => {
						onPageChange(Math.ceil(total / perPage));
					}}
					_hover={{
						color: 'black',
						background: 'white',
					}}
				>
					<Icon color = {DarkGreen.iconColor} as={AiOutlineDoubleRight}  />
				</Button>
			</HStack>
		</HStack>
	);
}

export default NewMissionPaginator;
