import axios, { AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import { useState, useEffect, Dispatch } from 'react';
import { ResponseCodes } from '../shared/enums';
import api from './api';

axiosRetry(axios, {
	// number of retries
	retries: 3,
	// time interval between retries
	retryDelay: (retryCount) => {
		return 1000;
	},
	retryCondition: (error) => {
		return (
			error.response.status !== ResponseCodes.UNAUTHORIZED &&
			error.response.status !== ResponseCodes.FORBIDDEN &&
			error.response.status !== ResponseCodes.CONFLICT
		);
	},
});

/**
 * A hook that automatically fetches data from a specified URL whenever the URL or dependencies change.
 * @param url Full URL for performing the GET call
 * @param preventAPIRequests Functions like a scram button if you want to prevent API calls from being fired before queryParams are generated
 * @param callback A function that fires after data is changed with every successful API call. Accepts `res` and `setData` as args to allow accessing and overriding data passed by the hook
 * @param dependencies If present, effect will only activate if the values in the list change.
 * @returns Returns the stateful API response and a variable to indicate if an API call is in progress
 */
export const useFetch = <T>(
	url: string,
	preventAPIRequests: boolean = false,
	callback: (
		res: AxiosResponse<T>,
		setData: Dispatch<React.SetStateAction<T>>,
	) => void = null,
	dependencies: Array<any> = [],
): { data: T | null; loading: boolean; isError: boolean | null } => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<T | null>(null);
	const [isError, setIsError] = useState<boolean | null>(null);

	useEffect(() => {
		if (!preventAPIRequests) {
			setLoading(true);

			api
				.get(url)
				.then((res: AxiosResponse<T>) => {
					setData(res.data);
					callback && callback(res, setData);
					if (
						res.status === ResponseCodes.SUCCESS ||
						res.status === ResponseCodes.CREATED
					) {
						setIsError(false);
					} else setIsError(true);
				})
				.catch((err) => {
					console.error(
						`Exception occured while trying to fetch ${url}: ${err}`,
					);
					setIsError(true);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [url, preventAPIRequests, ...dependencies]);
	return { data, loading, isError };
};
