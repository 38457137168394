import { As, Icon, ListItem,Text } from '@chakra-ui/react';
import { useResolvedPath, useMatch, useNavigate } from 'react-router-dom';
import { useStore } from '../../store';

interface LinkProps {
	icon: As<any>;
	label: string;
	to: string;
}

function Link({ icon, label, to }: LinkProps) {
	const { isOpen } = useStore();
	let resolved = useResolvedPath(to);
	let match: any = useMatch({ path: resolved.pathname, end: true });
	const navigate = useNavigate();

	return (
		<ListItem
			onClick={() => {
				navigate(to);
			}}
			display="flex"
			alignItems="center"
			gap="1.25rem"
			fontSize="18px"
			pt="1rem"
			pb="1rem"

			pl={{lg: isOpen? "2rem":"1.5rem", base:isOpen? "2rem":"1rem"} }
			// mx={isOpen? "0":"0.6rem"}
			color={match ? 'rgba(0, 196, 106, 1)' : 'white'}
			background={match ? 'rgba(256, 256, 256, 0.15)' : 'transparent'}
			cursor="pointer"
			_hover={
				match ? { background: 'rgba(0, 196, 106, 0.25)' } : { background: 'rgba(0, 196, 106, 0.25)' }
			}
		>
			<Icon
			 w="32px" 
			 h="32px" 
			width={{lg: "32px", base :"32px"}}
			 as={icon} />
			<Text 
			color={match ? 'rgba(0, 196, 106, 1)' : 'white'}			
			opacity={isOpen ? "1" : "0"}
			display={isOpen ? "block" : "none"}
			transition= {isOpen? "opacity 0.2s ease-in-out":"none"}
			 >
			{isOpen ? label : label}
			</Text>
		</ListItem>
	);
}

export default Link;
