import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function ConfirmDelete({ onClose, isOpen, onConfirm }) {
	const { t } = useTranslation();

	return (
		<Modal size="xl" onClose={onClose} isOpen={isOpen} isCentered>
			<ModalOverlay />
			<ModalContent
				borderColor="#73BCB6"
				borderWidth="2px"
				p="2rem 4rem"
				bg="rgba(0,0,0,1)"
			>
				<ModalHeader color="#2F98A6">{t('confrimDelete.Title')}</ModalHeader>
				<ModalCloseButton color="white" />
				<ModalBody>{t('confrimDelete.Description')}</ModalBody>
				<ModalFooter gap="1rem">
					<Button
						background="transparent"
						borderRadius={'0.25rem'}
						borderWidth="2px"
						borderColor="red"
						color="white"
						// minW={{ lg: '210px', base: '130px' }}
						_hover={{
							background: 'red',
						}}
						onClick={onConfirm}
					>
						{t('Confirm')}
					</Button>
					<Button
						background="transparent"
						borderRadius={'0.25rem'}
						borderWidth="2px"
						borderColor="white"
						color="white"
						// minW={{ lg: '210px', base: '130px' }}
						onClick={onClose}
						_hover={{
							color: 'black',
							background: 'white',
						}}
					>
						{t('Cancel')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default ConfirmDelete;
