import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function ConfirmSendEmail({ onClose, isOpen, onConfirm }) {
	const { t } = useTranslation();

	return (
		<Modal size="xl" onClose={onClose} isOpen={isOpen} isCentered>
			<ModalOverlay />
			<ModalContent
				borderColor="#73BCB6"
				borderWidth="2px"
				p="2rem 4rem"
				bg="rgba(0,0,0,1)"
			>
				<ModalHeader color="#2F98A6">{t('E-mail notification')}</ModalHeader>
				<ModalCloseButton color="white" />
				<ModalBody>
					{t('Would you like to send an e-mail notification to owners?')}
				</ModalBody>
				<ModalFooter gap="1rem">
					<Button
						background="transparent"
						borderRadius={'0.25rem'}
						borderWidth="2px"
						color="white"
						onClick={() => onConfirm(true)}
					>
						{t('Send')}
					</Button>
					<Button
						background="transparent"
						borderRadius={'0.25rem'}
						borderWidth="2px"
						borderColor="white"
						color="white"
						onClick={() => onConfirm(false)}
						_hover={{
							color: 'black',
							background: 'white',
						}}
					>
						{t("Don't send")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default ConfirmSendEmail;
