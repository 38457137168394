import {
	Box,
	Image,
	VStack,
	Text,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	HStack,
	useToast,
	Radio,
	RadioGroup,
	Button,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Textarea,
	useDisclosure,
	SimpleGrid,
	Icon,
	Portal,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
// @ts-ignore
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useFetch } from '../../shared/hooks';
import { API } from '../../shared/routes';
import { generateQueryString, getClientAPIUrl } from '../../shared/helpers';
import { useSearchParams } from 'react-router-dom';
import {
	DefaultFeaturesColors,
	MapPriorityColor,
	DarkGreen,
} from '../../shared/enums';
import {
	averageGeolocation,
	getBounds,
	getBoundsZoomLevel,
} from '../../models/map';
import { Layer, polygonDataCalc, showFeatureInfo } from './Elements';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { t } from 'i18next';
import api from '../../shared/api';
import { HiLocationMarker } from 'react-icons/hi';
import { FaEdit, FaSave } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import Zoom from 'react-medium-image-zoom';
import ConfirmDelete from '../shared/ConfirmDelete';
import { Player } from '@lottiefiles/react-lottie-player';
import Potree from './Potree';
import proj4 from 'proj4';
import { useLocation } from 'react-router-dom';
import { log } from 'console';
import { feature } from '@turf/turf';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
// mapboxgl.id = "mapbox-satellite-v9";

function Map({
	missionId = '',
	isDronodat,
	projectId = '',
	onSelect = (value): any => {},
	selectedFeatures = [],
	onGeoDataChange = (value): any => {},
	isAssigned = false,
	getMap = (map): any => {},
}) {
	const draw = new MapboxDraw({
		displayControlsDefault: false,
		controls: {
			point: missionId && (isDronodat || isAssigned) ? true : false,
		},
		styles: [
			{
				id: 'highlight-active-points',
				type: 'circle',
				filter: [
					'all',
					['==', '$type', 'Point'],
					['==', 'meta', 'feature'],
					['==', 'active', 'true'],
				],
				paint: {
					'circle-radius': 0,
					'circle-color': '#000000',
				},
			},
		],
	});

	const inputFile = useRef(null);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isOpenPoint,
		onOpen: onOpenPoint,
		onClose: onClosePoint,
	} = useDisclosure();
	const {
		isOpen: isOpenConfirm,
		onOpen: onOpenConfirm,
		onClose: onCloseConfirm,
	} = useDisclosure();
	const [loading, setLoading] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [pointDesc, setPointDesc] = useState('');
	const [files, setFiles] = useState<any>([]);
	const [cord, setCord] = useState([]);
	// const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const mapContainer = useRef(null);
	const map = useRef(null);
	const [geoData, setGeoData] = useState({});
	const [rezoom, setRezoom] = useState(true);
	const [lng] = useState(0);
	const [lat] = useState(0);
	const [zoom] = useState(1);
	const zoomTolerance = 0.01
	const [areaMeasure, setAreaMeasure] = useState(0);
	const [lineMeasure, setLineMeasure] = useState(0);
	const [selected, setSelected] = useState([]);
	const [refresh, setRefresh] = useState(0);
	const [refresh2, setRefresh2] = useState(0);
	const [properties, setProperties] = useState<any>({});
	const [isPointEdit, setIsPointEdit] = useState(false);
	const [isMapReady, setIsMapReady] = useState(false);

	const [threeDbackrColor, setthreeDbackrColor] = useState('transparent');
	const [twoDbackrColor, settwoDbackrColor] = useState(DarkGreen.ButtonHighlightColor);

	const [TwoDBorderWidth,set2DBorderWidth] = useState('3px');
	const [THREEDBorderWidth,set3DBorderWidth] = useState('0px');

	const [TwoDMapWindowsize, setTwoDMapWindowsize]= useState('100%');

	
	const [ThreeDMapWindowsize, setThreeDMapWindowsize]= useState('1%');
	const [ThreeDMapPosition, setThreeDMapPosition]= useState('absolute');
	const [ThreeDMapWindowheight, setThreeDMapWindowheight]= useState('1%');
	const [CESIUMVIEWER,setCESIUMVIEWER]= useState(undefined);
	const [Cesium,setCesium]= useState(undefined);
	const [PotreeViewer , setpotreeViewerobj] = useState(undefined);

	const [DATAPROJ , setDataProjection] = useState(undefined);
	const [MAPPROJ , setMapProjection] = useState(undefined);

	const [fullScreenIcon,setfullScreenIcon] = useState({"ableFullScreen":true, "url":"/full-screen-display-button-1.svg"});

	const location = useLocation();
	const isMissionDetails = location.pathname.startsWith('/missions/') && !isNaN(parseInt(location.pathname.split('/')[2])) && !location.pathname.includes('/edit');
	const isMissionEdit = location.pathname.includes('/edit');
	const MID =  parseInt(location.pathname.split('/')[2]); // Mission ID
	// console.log(MID);
	


	const DATAPROJRef = useRef(null);
		DATAPROJRef.current = DATAPROJ;
	
	const MAPPROJRef = useRef(null);
		MAPPROJRef.current = MAPPROJ;

	const CESIUMVIEWERRef = useRef(null);
	CESIUMVIEWERRef.current = CESIUMVIEWER;

	const CesiumRef = useRef(null);
	CesiumRef.current = Cesium;

	const PotreeViewerRef = useRef(null);
		PotreeViewerRef.current = PotreeViewer;

	const [rightSwitcherMenuHeight, setrightSwitcherMenuHeight ]= useState('2.5rem')


	const {
		isOpen: isOpen2,
		onOpen: onOpen2,
		onClose: onClose2,
	} = useDisclosure();
	const client = searchParams.get('client');
	const toast = useToast();

	useFetch(
		generateQueryString(
			(isDronodat ? getClientAPIUrl(client) : '') + API.get.ANNOTATIONS,
			{
				mission: missionId,
			},
		),
		!isMapReady || !missionId,
		({ data }: any) => {
			if (map.current.getLayer('Notes')) {
				map.current.removeLayer('Notes').removeSource('Notes');
			}
			map.current.addLayer({
				id: 'Notes',
				type: 'symbol',
				source: {
					type: 'geojson',
					data: {
						type: 'FeatureCollection',
						features: data.results.map((item) => {
							return {
								type: 'Feature',
								properties: {
									...item,
								},
								geometry: {
									type: 'Point',
									coordinates: item.coordinates.coordinates,
								},
							};
						}),
					},
				}, // reference the data source
				layout: {
					'icon-image': 'pin',
					'icon-allow-overlap': false,
					'icon-size': 1.5,
				},
			});
		},
		[refresh2],
	);
	// console.log(selectedFeatures)

	useEffect(() => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/dronodat/cl905vm02005815pr4jhim0rz',
			center: [lng, lat],
			zoom: zoom,
			doubleClickZoom: false,
		});
		getMap(map.current);
		map.current.boxZoom.disable();
		map.current.touchZoomRotate.disableRotation();
		map.current.dragRotate.disable();
		map.current.on('load', () => {
			setIsMapReady(true);

			const canvas = map.current.getCanvasContainer();

			// Variable to hold the starting xy coordinates
			// when `mousedown` occured.
			let start;
			
			let altClicked = false;
			
			// Set `true` to dispatch the event before other functions
			// call it. This is necessary for disabling the default map
			// dragging behaviour.
			canvas.addEventListener('mousedown', mouseDown, true);

			// Return the xy coordinates of the mouse position
			function mousePos(e) {
				const rect = canvas.getBoundingClientRect();
				return new mapboxgl.Point(
					e.clientX - rect.left - canvas.clientLeft,
					e.clientY - rect.top - canvas.clientTop,
				);
			}

			function mouseDown(e) {
				// Continue the rest of the function if the shiftkey is pressed.
				if (!(e.shiftKey && e.button === 0)) return;
				altClicked = e.altKey;
				// Disable default drag zooming when the shift key is held down.

				// Call functions for the following events
				document.addEventListener('mouseup', onMouseUp);
				document.addEventListener('keydown', onKeyDown);

				// Capture the first xy coordinates
				start = mousePos(e);
			}

			function onMouseUp(e) {
				// Capture xy coordinates
				finish([start, mousePos(e)]);
			}

			function onKeyDown(e) {
				// If the ESC key is pressed
				if (e.keyCode === 27) finish('');
			}
			
			function finish(bbox) {
				// Remove these events now that finish has been called.
				document.removeEventListener('keydown', onKeyDown);
				document.removeEventListener('mouseup', onMouseUp);

				const featureTypes = ["LineString"]; // features that should be selected

				const layerNames = [];
				featureTypes.forEach((feature) => {
					layerNames.push(feature);
				  for (const [key, value] of Object.entries(MapPriorityColor)) {
					layerNames.push(`${feature} - ${key}`);    
				  }
				});
				  
				// If bbox exists. use this value as the argument for `queryRenderedFeatures`
				if (altClicked) {

					const getFeatures = (layerName) => {
						return map.current.queryRenderedFeatures(bbox, { layers: [layerName] })
						  .map((feature) => feature.properties.DBID);
					};
					  
					const features = layerNames.map(getFeatures);
					  
					layerNames.forEach((layerName, index) => {
						map.current.setFilter(
						  `highlighted-${layerName}`,
						  map.current
							.getFilter(`highlighted-${layerName}`)
							.filter((feature) => {
							  return !features[index].includes(feature);
							})
						);
					});
				} else if (bbox) {
					const getFeatures = (layerName) => {
						return map.current.queryRenderedFeatures(bbox, { layers: [layerName] })
						.map((feature) => feature.properties.DBID);
						};
						
					const features = layerNames.map(getFeatures);
					const filterNames = layerNames.map(layerName => `highlighted-${layerName}`);
					  filterNames.forEach((filterName, index) => {
						map.current.setFilter(
						  filterName,
						  [
							...map.current.getFilter(filterName),
							...features[index],
						  ]
						);
					  });
					  
				}

				const tempSelected = [];

				layerNames.forEach((layer) => {
				  const filter = map.current.getFilter(`highlighted-${layer}`);
				  if (filter) {
					tempSelected.push(...filter.slice(3));
				  }
				});

				console.log("##"+tempSelected)

				onSelect(tempSelected);
				setSelected(tempSelected);
				map.current.dragPan.enable();
			}

		});


		map.current.on('moveend', () => {
		
			
			//console.log(TwoDMapWindowsize)
			//console.log(CesiumRef.current ,CESIUMVIEWERRef.current)
			if (CESIUMVIEWERRef.current !== undefined && CesiumRef.current !== undefined && PotreeViewerRef.current !== undefined && MAPPROJRef.current !== undefined && DATAPROJRef.current !== undefined){

					let MapboxBBOX = map.current.getBounds()
					const center = map.current.getCenter();

					let converterSW = proj4(MAPPROJRef.current,DATAPROJRef.current,[MapboxBBOX._sw.lng, MapboxBBOX._sw.lat]);
					let converterNE = proj4(MAPPROJRef.current,DATAPROJRef.current,[MapboxBBOX._ne.lng, MapboxBBOX._ne.lat]);
					let centerMap = proj4(MAPPROJRef.current,DATAPROJRef.current,[center.lng, center.lat])

					PotreeViewerRef.current.scene.view.position.set(centerMap[0], centerMap[1], Math.abs(converterSW[0]-converterNE[0]) * 0.7);
					PotreeViewerRef.current.scene.view.pitch = -CesiumRef.current.Math.PI_OVER_TWO;
					PotreeViewerRef.current.scene.view.yaw = 0;
			}
		

		});

		const handleClick = async () => {
			const element = document.querySelector('.mapbox-gl-draw_point');
			try {
				const id = element.getAttribute('data-id');
				const elementCord = element.getAttribute('data-cord').split(',');
				element.classList.add('updated');
				await api.patch(
					(isDronodat ? getClientAPIUrl(client) : '') +
						`${API.put.ANNOTATIONS}${id}/`,
					{
						coordinates: `POINT (${elementCord[0]} ${elementCord[1]})`,
					},
				);
				toast({
					title: t('missions.point.edit.toast.Title'),
					description: t('missions.point.edit.toast.Description'),
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'bottom-right',
				});
			} catch {
				toast({
					title: t('Error'),
					description: t('TryAgain'),
					status: 'error',
					duration: 3000,
					isClosable: true,
					position: 'bottom-right',
				});
			} finally {
				element.classList.remove('edit');
				element.removeAttribute('data-id');
				element.removeAttribute('data-cord');
				setIsPointEdit(false);
				setRefresh2(refresh2 + Date.now());
				setProperties({});
			}
		};
		//map.current.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');
		map.current.addControl(draw, 'bottom-right');
		map.current.on('draw.create', (e) => {
			if (
				document
					.querySelector('.mapbox-gl-draw_point')
					.classList.contains('edit')
			) {
				const element = document.querySelector('.mapbox-gl-draw_point');
				element.setAttribute('data-cord', e.features[0].geometry.coordinates);
			} else {
				onOpen();
				setCord(e.features[0].geometry.coordinates);
			}
		});
		map.current.addControl(
			new mapboxgl.GeolocateControl({
				positionOptions: {
					enableHighAccuracy: true,
				},
				// When active the map will receive updates to the device's location as it changes.
				trackUserLocation: true,
				// Draw an arrow next to the location dot to indicate which direction the device is heading.
				showUserHeading: true,
			}),
			'bottom-right',
		);
		map.current.addControl(
			new MapboxGeocoder({
				accessToken: mapboxgl.accessToken,
				mapboxgl,
			}),
			'top-left',
		);
		map.current.on('click', async (e) => {
			Object.keys(MapPriorityColor).forEach((key) => {
				map.current.setFilter(`goTo-LineString - ${key}`, ['in', 'DBID']);
			});
			onClose2();
			const element = document.querySelector('.mapbox-gl-draw_point');
			if (element?.classList.contains('edit')) { //added ? to fix
				handleClick();
			} else if (element?.classList.contains('updated')) { //added ? to fix
				element?.classList.remove('updated'); //added ? to fix
			} else {
				showFeatureInfo(e, map, setProperties, onOpen2, onOpenPoint);
				polygonDataCalc(e, map, setAreaMeasure, setLineMeasure);
			}
		});

		map.current.loadImage('/assets/images/power.png', (error, image) => {
			if (error) return;
			map.current.addImage('power', image);
		});
		map.current.loadImage('/assets/images/pin.png', (error, image) => {
			if (error) return;
			map.current.addImage('pin', image);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geoData, refresh, map?.current?.style?.stylesheet?.name]);

	useEffect(() => {
		if (!map.current) return; // wait for map to initialize

		let data = [].concat.apply([], Object.values(geoData));
		const temp = [];
		data.forEach((feature) => {
			if (feature.geometry.type === 'GeometryCollection') {
				data = data.filter(
					(f) => f.properties.DBID !== feature.properties.DBID,
				);
				feature.geometry.geometries.forEach((geom) => {
					temp.push({ ...feature, geometry: geom });
				});
			}
		});

		data = [...data, ...temp];
		
		const collectionNames = [
			'assigned-other',
			'LineString',
			'Point',
			'LineString - None',
			'LineString - 01 - Immediate',
			'LineString - 1M - 1 Month',
			'LineString - 3M - 3 Months',
			'Polygon',
			'Polygon - None',
			'Polygon - 01 - Immediate',
			'Polygon - 1M - 1 Month',
			'Polygon - 3M - 3 Months',
		  ];
		  
		  const Collections = {};

		  for (const name of collectionNames) {
			Collections[name] = {
			  type: 'FeatureCollection',
			  features: [],
			};
		  }
		  
		if (data) {
			data?.forEach((feature) => {
				feature.properties.DBID = feature.properties.id;

				if (feature.properties.mission != null && feature.properties.mission != "" && String(feature.properties.mission) != String(MID)) {
					if (isMissionEdit) {
					Collections['assigned-other'].features.push(feature);
					}
				}else if (String(feature.properties.mission) === String(MID)) {
					if (feature.geometry.type === 'LineString') {
						const priority = feature.properties.PRIORITY;
						const collectionKey = `LineString${priority ? ` - ${priority}` : ''}`;
						Collections[collectionKey].features.push(feature);
					} 
				} else if (feature.geometry.type === 'Point') {
					Collections['Point'].features.push(feature);
				} else if (feature.geometry.type === 'LineString') {
					if (feature.geometry.type === 'LineString') {
						const priority = feature.properties.PRIORITY;
						const collectionKey = `LineString${priority ? ` - ${priority}` : ''}`;
						Collections[collectionKey].features.push(feature);
					} 
				} else if (feature.geometry.type === 'Polygon') {
					if (feature.geometry.type === 'Polygon') {
						const priority = feature.properties.PRIORITY;
						const collectionKey = `Polygon${priority ? ` - ${priority}` : ''}`;
						Collections[collectionKey].features.push(feature);
					} 
				}
				
			}
		);

			Object.keys(Collections).forEach((key) => {
				if (map.current.getLayer(key)) {
					map.current?.removeLayer(key).removeSource(key);
					map.current?.removeLayer('highlighted-' + key).removeSource('highlighted-' + key);
					if (key.includes('LineString - ')) {
						map.current?.removeLayer('goTo-' + key).removeSource('goTo-' + key);
					}
				}

				if (data.length === 0) return;
				
				if (key === 'assigned-other') {
					map.current.addLayer({
						id: key,
						type: 'line',
						source: {
							type: 'geojson',
							data: Collections[key],
							tolerance: zoomTolerance
						},
						layout: {
							'line-join': 'round',
							'line-cap': 'round',
						},
						paint: {
							'line-color': "gray",
							'line-width': 2,
						},
					});
				}
				else if (key === 'Point') {
					map.current.addLayer({
						id: key,
						type: 'symbol',
						source: {
							type: 'geojson',
							data: Collections[key],
							tolerance: zoomTolerance
						}, // reference the data source
						layout: {
							'icon-image': 'power',
							'icon-size': 0.3,
							'icon-anchor': 'center',
							'icon-offset': [0, -4],
						},
					});
					map.current.addLayer({
						id: 'highlighted-Point',
						type: 'symbol',
						source: {
							type: 'geojson',
							data: Collections[key],
						},
						layout: {
							'icon-image': 'power',
							'icon-size': 0.3,
							'icon-anchor': 'center',
							'icon-offset': [0, -4],
						},
						filter: ['in', 'DBID', ''],
					});
				} else if (key === 'LineString') {
					map.current.addLayer({
						id: key,
						type: 'line',
						source: {
							type: 'geojson',
							data: Collections[key],
							tolerance: zoomTolerance
						},
						layout: {
							'line-join': 'round',
							'line-cap': 'round',
						},
						paint: {
							'line-color': DefaultFeaturesColors.LineString,
							'line-width': 2,
						},
					});
					map.current.addLayer({
						id: 'highlighted-LineString',
						type: 'line',
						source: {
							type: 'geojson',
							data: Collections[key],
							tolerance: zoomTolerance
						},
						layout: {
							'line-join': 'round',
							'line-cap': 'round',
						},
						paint: {
							'line-color': 'purple',
							'line-width': 2,
						},
						filter: ['in', 'DBID', ''],
					});
				} else if (key === 'Polygon') {
					map.current.addLayer({
						id: key,
						type: 'fill',
						source: {
							type: 'geojson',
							data: Collections[key],
							tolerance: zoomTolerance
						},
						layout: {},
						paint: {
							'fill-color': DefaultFeaturesColors.Polygon,
							'fill-opacity': 1,
						},
					});
					// map.current.addLayer({
					// 	id: 'highlighted-Polygon',
					// 	type: 'fill',
					// 	source: {
					// 		type: 'geojson',
					// 		data: Collections[key],
					// 	},
					// 	layout: {},
					// 	paint: {
					// 		'fill-color': 'pink',
					// 		'fill-opacity': 1,
					// 	},

					// 	filter: ['in', 'DBID', ''],
					// });
				} else if (key.includes('Polygon')) {
					map.current.addLayer({
						id: key,
						type: 'fill',
						source: {
							type: 'geojson',
							data: Collections[key],
							tolerance: zoomTolerance
						},
						layout: {},
						paint: {
							'fill-color': MapPriorityColor[key.replace('Polygon - ', '')],
							'fill-opacity': 1,
						},
					});
					// map.current.addLayer({
					// 	id: 'highlighted-' + key,
					// 	type: 'fill',
					// 	source: {
					// 		type: 'geojson',
					// 		data: Collections[key],
					// 	},
					// 	layout: {},
					// 	paint: {
					// 		'fill-color': 'pink',
					// 		'fill-opacity': 1,
					// 	},

					// 	filter: ['in', 'DBID', ''],
					// });
				} else {
					map.current.addLayer({
						id: key,
						type: 'line',
						source: {
							type: 'geojson',
							data: Collections[key],
							tolerance: zoomTolerance
						},
						layout: {
							'line-join': 'round',
							'line-cap': 'round',
						},
						paint: {
							'line-color': MapPriorityColor[key.replace('LineString - ', '')],
							'line-width': 2,
						},
					});
					map.current.addLayer({
						id: 'goTo-' + key,
						type: 'line',
						source: {
							type: 'geojson',
							data: Collections[key],
							tolerance: zoomTolerance
						},
						layout: {
							'line-join': 'round',
							'line-cap': 'round',
						},
						paint: {
							'line-color': 'white',
							'line-width': 2,
						},
						filter: ['in', 'DBID', ''],
					});
					map.current.addLayer({
						id: 'highlighted-' + key,
						type: 'line',
						source: {
							type: 'geojson',
							data: Collections[key],
							tolerance: zoomTolerance
						},
						layout: {
							'line-join': 'round',
							'line-cap': 'round',
						},
						paint: {
							'line-color': 'purple',
							'line-width': 2,
						},
						filter: ['in', 'DBID', ''],
					});
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geoData, map?.current?.style?.stylesheet?.name]);

	useEffect(() => {
		if (!map.current) return; // wait for map to initialize
		if (!rezoom) return;

		let data = [].concat.apply([], Object.values(geoData));
		const temp = [];
		data.forEach((feature) => {
			if (feature.geometry.type === 'GeometryCollection') {
				data = data.filter(
					(f) => f.properties.DBID !== feature.properties.DBID,
				);
				feature.geometry.geometries.forEach((geom) => {
					temp.push({ ...feature, geometry: geom });
				});
			}
		});

		data = [...data, ...temp];

		if (data.length === 0) {
			map.current.flyTo({
				center: { lat: 0, lng: 0 },
				zoom: 1,
			});
			return;
		}
		const initalCenter = averageGeolocation(
			data.map((feature) => {
				if (feature.geometry.type === 'Point')
					return {
						lat: feature.geometry.coordinates[1],
						lng: feature.geometry.coordinates[0],
					};
				if (feature.geometry.type === 'LineString') {
					return averageGeolocation(
						feature.geometry.coordinates.map((coord) => {
							return { lat: coord[1], lng: coord[0] };
						}),
					);
				} else {
					return averageGeolocation(
						feature.geometry.coordinates[0].map((coord) => {
							return { lat: coord[1], lng: coord[0] };
						}),
					);
				}
			}),
		);

		const bounds = getBounds(
			data
				.map((feature) => {
					if (feature.geometry.type === 'Point')
						return {
							lat: feature.geometry.coordinates[1],
							lng: feature.geometry.coordinates[0],
						};
					if (feature.geometry.type === 'LineString') {
						return feature.geometry.coordinates.map((coord) => {
							return { lat: coord[1], lng: coord[0] };
						});
					} else {
						return feature.geometry.coordinates[0].map((coord) => {
							return { lat: coord[1], lng: coord[0] };
						});
					}
				})
				.flat(),
		);
		const { clientHeight, clientWidth } = mapContainer.current;
		const newZoom = getBoundsZoomLevel(bounds, {
			width: clientWidth,
			height: clientHeight,
		});

		map.current.flyTo({
			center: initalCenter,
			zoom: newZoom,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geoData]);


	//Manage 2D / 3D button clicks
	const ButtonClickAtion = (Dimension) => {
		let MapboxwidthValue = '';
		let PotreewidthValue = '';
		
		if (Dimension == '2D'){
				//Change the background
					if(TwoDBorderWidth == '0px'){
						set2DBorderWidth('3px')
						
						settwoDbackrColor(DarkGreen.ButtonHighlightColor)
						//console.log(ThreeDMapWindowsize)
						if(ThreeDMapWindowsize == '0%'){
							setTwoDMapWindowsize('100%')
								MapboxwidthValue = '100%'
						}else {
							setTwoDMapWindowsize('50%')
								MapboxwidthValue = '50%'
							setThreeDMapWindowsize('50%')
							setThreeDMapWindowheight('100%')
								PotreewidthValue = '50%'
						}
						
					}else{
						set2DBorderWidth('0px')
						settwoDbackrColor('transparent')

						setTwoDMapWindowsize('0%')
							MapboxwidthValue = '0%'
						setThreeDMapWindowsize('100%')
						setThreeDMapWindowheight('100%')
							PotreewidthValue = '100%'
					}
		}else {
			
				//Change the background
					if(THREEDBorderWidth == '0px'){
						set3DBorderWidth('3px')
						setthreeDbackrColor(DarkGreen.ButtonHighlightColor)


						if(TwoDMapWindowsize == '0%'){
							setThreeDMapWindowsize('100%')
							setThreeDMapWindowheight('100%')
								PotreewidthValue = '100%'
						}else {
							setThreeDMapWindowsize('50%')
							setThreeDMapWindowheight('100%')
								PotreewidthValue = '50%'
							setTwoDMapWindowsize('50%')
								MapboxwidthValue = '50%'
						}

						setThreeDMapPosition('relative')

					}else{
						set3DBorderWidth('0px')
						setthreeDbackrColor('transparent')

						setThreeDMapWindowsize('1%')
						setThreeDMapWindowheight('1%')
							setThreeDMapPosition('absolute')
							PotreewidthValue = '0%'

						setTwoDMapWindowsize('100%')
							MapboxwidthValue = '100%'
					}

		}

		//If the user hide both map we hide them both
			if (PotreewidthValue == '0%' && MapboxwidthValue == '0%'){
				set2DBorderWidth('0px')
				set3DBorderWidth('0px')
				setthreeDbackrColor('transparent')
				settwoDbackrColor('transparent')

				setTwoDMapWindowsize('0%')
				setThreeDMapWindowsize('1%')
				setThreeDMapWindowheight('1%')
			}
		//Resize the Map
			//var mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0];
			var mapDiv = mapContainer.current;
			mapDiv.style.width = MapboxwidthValue;
            //mapCanvas.style.width = MapboxwidthValue;

			map.current.resize();

	}

	//Add a listener to disable 2D 3D switcher if only one is displayed

			useEffect(() => {
					
					let twoDbutton = document.getElementById("twoDswitch")
					let threeDbutton = document.getElementById("threeDswitch")

					if(TwoDMapWindowsize =='100%') {
						
						twoDbutton.style.pointerEvents = 'none'

					}else if (ThreeDMapWindowsize == '100%'){
						
						threeDbutton.style.pointerEvents = 'none'
						
					} else {
						twoDbutton.style.pointerEvents = 'auto'
						threeDbutton.style.pointerEvents = 'auto'
					}
			}, [ThreeDMapWindowsize,TwoDMapWindowsize])

	const CesiumViewerObj = (CesiumViewer) => {
		setCESIUMVIEWER(CesiumViewer)
	}
	const CesiumObj = (cesium) => {
		setCesium(cesium)
	}

	const potreeViewerobj = (potreeViewerobj) => {
		setpotreeViewerobj(potreeViewerobj)
	}

	const DataProjection  = (Projection) => {
		setDataProjection(Projection)
	}
	const MapProjection = (Projection) => {
		setMapProjection(Projection)
	}

	const FireButtonClickAtion = (Dimension) => {
		// console.log(Dimension)
		ButtonClickAtion(Dimension)
	}

	const fullscreen = () => {
		let MapContainer = document.getElementById('HallMapContainer');
		let header = document.getElementById('header')
		let sidebar = document.getElementById('sidebar')

		let mapBoxContainer = document.getElementById('mapContainer');
		
		let ableFullScreen = {"ableFullScreen":true, "url":"/full-screen-display-button-1.svg"}
		let UnAbleFullScreen = {"ableFullScreen":false, "url":"/full-screen-button-7.svg"}
		
		
		if(fullScreenIcon.ableFullScreen == true){
			//let optPointSizing = document.getElementById('optPointSizing');
		
			//MapContainer.appendChild(document.querySelector('#optPointSizing-menu'));
			
			
			MapContainer.style.position= 'absolute';
			MapContainer.style.width= '100vw';
			MapContainer.style.height= '100vh';
			MapContainer.style.left= isMissionDetails? "-3rem":'-161px';
			MapContainer.style.top= '-113px';
			MapContainer.style.zIndex= '2';

			let page = window.location.pathname;
			if(page.includes('/missions/new')){
				MapContainer.style.left= '0px';

			// MapContainer.scrollIntoView();
			document.getElementById("SwitcherMenu").style.right = '2rem';
			}
			setTimeout(() => MapContainer.scrollIntoView(), 200);

			header.style.display = 'none'

			sidebar.style.display = 'none'

			//define Mapbox / Cesium depending on previous style before the full screen
				if(TwoDMapWindowsize == '50%') {
					mapBoxContainer.style.width= '50vw';
    				mapBoxContainer.style.height= '100vh';
					setThreeDMapWindowsize('50%');
					setThreeDMapWindowheight('100%');
					set2DBorderWidth('3px')

					

				} else if (TwoDMapWindowsize == '100%'){
					mapBoxContainer.style.width= '100vw';
    				mapBoxContainer.style.height= '100vh';

					setThreeDMapWindowsize('1%');
					setThreeDMapWindowheight('1%');

					set2DBorderWidth('3px')
				} else {
					// 0%
					mapBoxContainer.style.width= '0%';
    				mapBoxContainer.style.height= '100vh';

					setThreeDMapWindowsize('100%');
					setThreeDMapWindowheight('100%');

					set2DBorderWidth('0px')
				}
			
			setfullScreenIcon(UnAbleFullScreen)
            //mapCanvas.style.width = MapboxwidthValue;


			
			//setTwoDMapWindowsize('50%');
			

			map.current.resize();

			
			setrightSwitcherMenuHeight('1rem')

		}else {
			//If it's a project page we move the right menu switcher
			
			setrightSwitcherMenuHeight('2.5rem')
			//document.exitFullscreen()
			setfullScreenIcon(ableFullScreen)

			let page = window.location.pathname;
			if(page.includes('/missions/new')){


				//If it's a new mission we move the right margin 
					// document.getElementById("SwitcherMenu").style.right = '7rem';

			}

			MapContainer.style.position= 'unset';
			MapContainer.style.width= '100%';
			MapContainer.style.height= '100%';
			//MapContainer.style.left= '-144px';
			//MapContainer.style.top= '-231px';
			
			header.style.display = 'flex'

			
			sidebar.style.display = 'unset'

			
            //mapCanvas.style.width = MapboxwidthValue;


			
			//define Mapbox / Cesium depending on previous style before the full screen
			if(TwoDMapWindowsize == '50%') {
				mapBoxContainer.style.width= '50%';
				mapBoxContainer.style.height= '100%';
				setThreeDMapWindowsize('50%');
				setThreeDMapWindowheight('100%');
				set2DBorderWidth('3px')

				document.getElementById("twoDswitch").click();
			setTimeout(() => {
				document.getElementById("twoDswitch").click();
			  }, 1000)
			  

			} else if (TwoDMapWindowsize == '100%'){
				mapBoxContainer.style.width= '100%';
				mapBoxContainer.style.height= '100%';

				setThreeDMapWindowsize('1%');
				setThreeDMapWindowheight('1%');

				set2DBorderWidth('3px')
			} else {
				// 0%
				mapBoxContainer.style.width= '0%';
				mapBoxContainer.style.height= '100%';

				setThreeDMapWindowsize('100%');
				setThreeDMapWindowheight('100%');

				set2DBorderWidth('0px')
			}

			//var mapDiv = mapContainer.current;
			//mapDiv.style.width = '50%';
			//mapDiv.style.height = '100%';
			map.current.resize();


			//document.getElementById("mapBoxContainer").click();
			
			
		}
		
	}

	useEffect(() => {
		return () => {
			// componentwillunmount in functional component.
			// Anything in here is fired on component unmount.
			let header = document.getElementById('header')
			let sidebar = document.getElementById('sidebar')

			header.style.display = 'flex'

	
			sidebar.style.display = 'unset'
			
		}
	}, [])
	  

	const setMaterial = (material) => { // pointcloud styler
		setTimeout(function () {
			if (material === "rgb") {
				PotreeViewer.scene.scenePointCloud.children.forEach((child) => {
					if (child.pcoGeometry) {
					child.material._activeAttributeName = "rgba";
					}
				});
			}
			if (material === "classification") {
				PotreeViewer.setClassifications({
					20:      { visible: true, name: 'trees' , color: [0, 0, 0, 255] },
					2:       { visible: true, name: 'Ground' , color: [150, 150, 150, 255] },
					3:       { visible: true, name: 'Low Vegetation' , color: [253, 212, 1, 255] },
					4:       { visible: true, name: 'Mid Vegetation' , color: [	 40,100, 0,255] },
					5:       { visible: true, name: 'High Vegetation' , color: [40, 0, 0, 255] },
					11:      { visible: true, name: 'Wires' , color: [200, 20, 200, 255] },
					21:      { visible: true, name: 'Poles' , color: [2,2, 2, 255] },
					DEFAULT: { visible: true, name: 'other' , color: [100, 100,100, 255] },
				});
				// const scheme = {};
				// for(let i = 0; i < 32; i++){
				// 	scheme[i] = { visible: true, name: `random:_${i}`, color: [Math.random(), Math.random(), Math.random(), 1.0] };
				// }
				// PotreeViewer.setClassifications(scheme);

				PotreeViewer.scene.scenePointCloud.children.forEach((child) => {
					if (child.pcoGeometry) {
					child.material._activeAttributeName = "classification";
					}
				});
			}

			

		}, 50);
	}

	  const mystyles = {
		position: ThreeDMapPosition,
	 } as React.CSSProperties;

	 //Chck the actual page to define right menu switcher position
		useEffect(() => {
			let page = window.location.pathname;
			//console.log(page,page.includes('/projects/'),page.includes('/powerlines/'))
			if(page == '/projects' || page == '/projects/'){
				//It's the projects page
					setrightSwitcherMenuHeight('1rem')
			}else if(page.includes('/projects/')){
				//It's single project vue
					setrightSwitcherMenuHeight('2.5rem')
			} else if (page.includes('/powerlines/')){
				//It's single project vue
					setrightSwitcherMenuHeight('7rem')
			}else if(page.includes('/missions/new')){
				//It's a new mission
				setrightSwitcherMenuHeight('2.5rem')

				//If it's a new mission we move the right margin 
					// document.getElementById("SwitcherMenu").style.right = '7rem';
			}
			
		},[])

	return (
		<>


			<ConfirmDelete
				onClose={onCloseConfirm}
				isOpen={isOpenConfirm}
				onConfirm={async () => {
					try {
						await api.delete(
							(isDronodat ? getClientAPIUrl(client) : '') +
								`${API.put.ANNOTATIONS}${properties.id}/`,
						);
						toast({
							title: t('missions.point.delete.toast.Title'),
							description: t('missions.point.delete.toast.Description'),
							status: 'success',
							duration: 3000,
							isClosable: true,
							position: 'bottom-right',
						});
						onClosePoint();
						setIsPointEdit(false);
						setRefresh2(refresh2 + 1);
					} catch {
						toast({
							title: t('Error'),
							description: t('TryAgain'),
							status: 'error',
							duration: 3000,
							isClosable: true,
							position: 'bottom-right',
						});
					} finally {
						onCloseConfirm();
					}
				}}
			/>
			<Portal containerRef={mapContainer}>
				<Modal
					size="xl"
					onClose={() => {
						onClose();
						setPointDesc('');
					}}
					isOpen={isOpen}
					isCentered
				>
					<ModalContent
						borderColor="#73BCB6"
						borderWidth="2px"
						p="4rem"
						bg="rgba(0,0,0,1)"
						cursor="initial"
					>
						<ModalCloseButton />
						<ModalBody textAlign="center">
							<Text fontSize="21px" fontWeight={300}>
								{t('PinPoint')}
							</Text>
							<Input
								type="file"
								accept=".jpg,.png,.jpeg"
								ref={inputFile}
								id="file"
								multiple
								bg="transparent"
								h={'auto'}
								pl={'0'}
								mt={'4rem'}
								color="#73BCB6"
								borderColor="#73BCB6"
								borderRadius="0.25rem"
								onChange={async (e) => {
									setFiles(e.target.files);
								}}
							/>
							<Textarea
								mt="1rem"
								bg="transparent"
								color="#73BCB6"
								borderColor="#73BCB6"
								borderRadius="0.25rem"
								placeholder={t('Notes')}
								size="md"
								rows={4}
								onChange={(e) => {
									setPointDesc(e.target.value);
								}}
								value={pointDesc}
							/>
							<Button
								mt="2rem"
								background="transparent linear-gradient(180deg, #00965B 0%, #065688 100%) 0% 0% no-repeat padding-box;"
								_hover={{
									background:
										'transparent linear-gradient(180deg, #065688 0%, #00965B 100%) 0% 0% no-repeat padding-box;',
								}}
								type="button"
								borderRadius={'0.25rem'}
								color="white"
								w="full"
								onClick={async () => {
									// formData.append('file', file);
									try {
										setIsUploading(true);
										const { data } = await api.post(
											(isDronodat ? getClientAPIUrl(client) : '') +
												API.post.ANNOTATIONS,
											{
												mission: missionId,
												coordinates: `POINT (${cord[0]} ${cord[1]})`,
												note: pointDesc,
											},
										);
										await Promise.all(
											Object.values(files).map(async (file: any) => {
												const formData = new FormData();
												formData.append('picture ', file);
												await api.post(
													(isDronodat ? getClientAPIUrl(client) : '') +
														`${API.post.ANNOTATIONS}${data.id}/pictures/`,
													formData,
												);
											}),
										);
										toast({
											title: t('missions.point.new.toast.Title'),
											description: t('missions.point.new.toast.Description'),
											status: 'success',
											duration: 3000,
											isClosable: true,
											position: 'bottom-right',
										});
										onClose();
									} catch (e) {
										toast({
											title: t('Error'),
											description: t('TryAgain'),
											status: 'error',
											duration: 3000,
											isClosable: true,
											position: 'bottom-right',
										});
									} finally {
										setIsUploading(false);
										setFiles([]);
										// draw.deleteAll();
										setRefresh2(refresh2 + 1);
										inputFile.current.value = '';
									}
								}}
								isDisabled={pointDesc === '' ? true : false}
								isLoading={isUploading}
							>
								{t('map.AddPoint')}
							</Button>
						</ModalBody>
					</ModalContent>
				</Modal>

				<Modal
					size="xl"
					onClose={() => {
						onClosePoint();
						// setProperties({});
						// setIsPointEdit(false);
					}}
					isOpen={isOpenPoint}
					isCentered
				>
					<ModalOverlay />
					<ModalContent
						borderColor="#73BCB6"
						borderWidth="2px"
						p="4rem"
						bg="rgba(0,0,0,1)"
						maxH="80vh"
						cursor="initial"
					>
						<ModalCloseButton />
						<Icon
							title={isPointEdit ? t('Save') : t('Edit')}
							cursor="pointer"
							onClick={async () => {
								setIsPointEdit(!isPointEdit);
								if (isPointEdit) {
									try {
										await api.patch(
											(isDronodat ? getClientAPIUrl(client) : '') +
												`${API.put.ANNOTATIONS}${properties.id}/`,
											{
												note: properties.note,
											},
										);
										toast({
											title: t('missions.point.edit.toast.Title'),
											description: t('missions.point.edit.toast.Description'),
											status: 'success',
											duration: 3000,
											isClosable: true,
											position: 'bottom-right',
										});
										const temp = await Promise.all(
											Object.values(files).map(async (file: any) => {
												const formData = new FormData();
												formData.append('picture ', file);
												const { data } = await api.post(
													(isDronodat ? getClientAPIUrl(client) : '') +
														`${API.post.ANNOTATIONS}${properties.id}/pictures/`,
													formData,
												);
												return data;
											}),
										);
										setProperties({
											...properties,
											pictures: [...properties.pictures, ...temp],
										});
										// @ts-ignore
										setIsPointEdit(false);
										setFiles([]);
										setRefresh2(refresh2 + 1);
									} catch {
										toast({
											title: t('Error'),
											description: t('TryAgain'),
											status: 'error',
											duration: 3000,
											isClosable: true,
											position: 'bottom-right',
										});
									}
								}
							}}
							w="1.5rem"
							h="1.5rem"
							as={isPointEdit ? FaSave : FaEdit}
							pos="absolute"
							left="1rem"
							top="1rem"
						/>
						<Icon
							title={t('Delete')}
							cursor="pointer"
							onClick={async () => {
								onOpenConfirm();
							}}
							w="1.5rem"
							h="1.5rem"
							as={AiFillDelete}
							pos="absolute"
							left="3rem"
							top="1rem"
						/>
						<Icon
							cursor="pointer"
							onClick={async () => {
								onClosePoint();
								const element = document.querySelector('.mapbox-gl-draw_point');
								// @ts-ignore
								element.click();
								element.classList.add('edit');
								element.setAttribute('data-id', properties.properties.id);
							}}
							w="1.5rem"
							h="1.5rem"
							as={HiLocationMarker}
							pos="absolute"
							left="5rem"
							top="1rem"
						/>
						<ModalBody overflow="auto" textAlign="center">
							<Text fontSize="21px" fontWeight={300}>
								{t('Note')}
							</Text>
							<Textarea
								mt="1rem"
								bg="transparent"
								color="#73BCB6"
								borderColor="#73BCB6"
								borderRadius="0.25rem"
								placeholder={t('Notes')}
								size="md"
								rows={4}
								readOnly={!isPointEdit}
								resize="none"
								onChange={(e) => {
									setProperties({ ...properties, note: e.target.value });
								}}
								value={properties?.note}
							/>
							{isPointEdit && (
								<Input
									type="file"
									accept=".jpg,.png,.jpeg"
									id="file2"
									multiple
									bg="transparent"
									h={'auto'}
									pl={'0'}
									my={'1rem'}
									color="#73BCB6"
									borderColor="#73BCB6"
									borderRadius="0.25rem"
									onChange={async (e) => {
										setFiles(e.target.files);
									}}
								/>
							)}
							<SimpleGrid mt="1rem" columns={3} spacing={1}>
								{properties?.pictures?.map((item) => (
									<Box key={item.id} pos="relative" h="100%" w="100%">
										{isPointEdit && (
											<Icon
												zIndex="2"
												title={t('Delete')}
												cursor="pointer"
												onClick={async () => {
													try {
														await api.delete(
															(isDronodat ? getClientAPIUrl(client) : '') +
																`${API.put.ANNOTATIONS}${properties.id}/pictures/${item.id}/`,
														);
														setProperties({
															...properties,
															pictures: properties.pictures.filter(
																(i) => i.id !== item.id,
															),
														});
														toast({
															title: t('missions.picutre.delete.toast.Title'),
															description: t(
																'missions.picutre.delete.toast.Description',
															),
															status: 'success',
															duration: 3000,
															isClosable: true,
															position: 'bottom-right',
														});
														// setIsPointEdit(false);
														setRefresh2(refresh2 + 1);
													} catch {
														toast({
															title: t('Error'),
															description: t('TryAgain'),
															status: 'error',
															duration: 3000,
															isClosable: true,
															position: 'bottom-right',
														});
													}
												}}
												w="1.5rem"
												h="1.5rem"
												as={AiFillDelete}
												pos="absolute"
												left="0.25rem"
												top="0.25rem"
												color="red"
											/>
										)}
										<Zoom
											portalEl={document.getElementById('mapContainer')}
											// zoomZindex={100}
											wrapStyle={{
												width: '100%',
												height: '100%',
											}}
										>
											<Image
												h="100%"
												w="100%"
												objectFit="cover"
												src={item.picture}
												alt={item.picture}
											/>
										</Zoom>
									</Box>
								))}
							</SimpleGrid>
						</ModalBody>
					</ModalContent>
				</Modal>
			</Portal>

			<div id="HallMapContainer" style={{width: '100%',height: '100%'}}>
	
				<Box // PotreeBox
					cursor="pointer"
					
					overflow="hidden"
					h={ThreeDMapWindowheight}
					w={ThreeDMapWindowsize}
					float="left" 
					
					style={mystyles}
				>

					<Potree projectId={'IdFromProject:'+projectId} FireButtonClickAtion={FireButtonClickAtion} DataProjection={DataProjection} MapProjection={MapProjection} CesiumViewerObj={CesiumViewerObj} CesiumObj={CesiumObj} potreeViewerobj={potreeViewerobj} />
					
					<HStack position={'absolute'} bottom="1rem" zIndex={9999} right="2rem" >
						<Button 
							fontSize={"14px"}
							w="120px"
							h="30px"
							bg={DarkGreen.layersBoxColor} 
							color={DarkGreen.ButtonHighlightColor} 
							onClick={() => setMaterial("classification")}> {t("Classification")} 
						</Button>
						<Button 
							fontSize={"14px"}
							w="50px"
							h="30px"
							bg={DarkGreen.layersBoxColor} 
							color={DarkGreen.ButtonHighlightColor} 
							onClick={() => setMaterial("rgb")} > {t("RGB")} 
						</Button>

					</HStack>

				</Box> 
				<Box
					cursor="pointer"
					pos="relative"
					overflow="hidden"
					h="100%"
					w={TwoDMapWindowsize}
					ref={mapContainer}
					id="mapContainer"
					className="project"
					float="left"
					zIndex="1"
				>
					{isOpen2 && (
						<Box //properties box
							pos="absolute"
							top="3.3rem"
							left="1rem"
							bg={DarkGreen.layersBoxColor}
							border= "1px solid"
							borderColor={DarkGreen.layersBoxBorderColor}
							borderRadius="0.25rem"
							p="1rem"
							w="213px"
							maxH="300px"
							overflow="auto"
							zIndex={2}
							dir="rtl"
						>
							<HStack dir="ltr" justify="space-between"> 
								<Accordion defaultIndex={[0]} allowToggle w="full">
									<AccordionItem borderColor="transparent">
										<HStack justifyContent="space-between">
											<Text fontSize="lg" fontWeight="semibold" color={DarkGreen.iconColor}>
												{t('Properties')}
											</Text>
											<AccordionButton w="auto" p="0">
												<AccordionIcon color={DarkGreen.iconColor} />
											</AccordionButton>
										</HStack>
										<AccordionPanel p={'0.5rem 0.5rem 0.5rem 0'}>
											{Object.keys(properties).map((key, index) => (
												<HStack 
													justifyContent="space-between" 
													w="100%"  
													// flexDir={"column"}
													style={{flexWrap: 'wrap'}}
													>
													<Text
													color={DarkGreen.iconColor}
													textAlign={'left'}
													key={index}
													>
													{key} :
													</Text>
													<Text
														// justifyContent="flex-end" 
														color={DarkGreen.layersBoxBorderTextColor}
														textAlign={'left'}
														key={index}
														mt="0"
														>
													{properties[key]}
													</Text>
												</HStack>
											))}
										</AccordionPanel>
									</AccordionItem>
								</Accordion>
							</HStack>
						</Box>
					)}

					{loading && (
						<>
							<Player
								src="/assets/loading.json"
								autoplay
								loop
								style={{
									position: 'absolute',
									left: '50%',
									top: '50%',
									transform: 'translate(-50%,-50%)',
									zIndex: '2',
									height: "200px",
									width: "200px",	
								}}
							/>
							<Box
								pos="absolute"
								zIndex={1}
								w="100%"
								h="100%"
								bg="rgba(0,0,0,0.5)"
							></Box>
						</>
					)}
					{(areaMeasure > 0 || lineMeasure > 0) && (
						<Box
							pos="absolute"
							bottom="2rem"
							right="4rem"
							bg={DarkGreen.layersBoxColor}
							borderRadius="0.25rem"
							p="1rem"
							minW="40px"
							minH="40px"
							zIndex={99}
						>
							<VStack alignItems="flex-start" spacing={0}>
								{areaMeasure && (
									<Text color="black">
										<b>Area: </b>
										{areaMeasure} ㎡
									</Text>
								)}
								{lineMeasure && (
									<Text color="black">
										<b>Line: </b>
										{lineMeasure.toFixed(2)} km
									</Text>
								)}
							</VStack>
						</Box>
					)}
					
				</Box>


			<Box
					pos="absolute" 
					top={isMissionDetails? "5.3rem":isMissionEdit? "2rem":"2rem"}
					right={isMissionDetails? {xl:"6.3rem",md:"6rem", base:"1rem"}:
						isMissionEdit? {xl:"1rem",md:"1rem", base:"1rem"}
						:"1rem"
					} //{{xl:"6.2rem",md:"5rem", base:"1rem"}}
					bg={DarkGreen.layersBoxColor}
					border= "1px solid"
					borderColor={DarkGreen.layersBoxBorderColor}
					borderRadius="0.25rem"
					p="1rem"
					w="240px"
					maxH="300px"
					overflow="auto"
					zIndex={1}
					id="SwitcherMenu"
					>
					<HStack //layerbox Buttons
						>
						<Button 
							id="twoDswitch" 
							background = {twoDbackrColor}
							borderColor={DarkGreen.layersBoxBorderColor}

							_hover={{
								bg : DarkGreen.ButtonHighlightColor
							}}
							onClick={() => ButtonClickAtion('2D')} 
							style={{width: '20px',height: '30px',borderWidth: '1px',marginLeft: '26px',marginRight: '7px',marginBottom:'10px',}}>2D</Button>
						<Button  
							id="threeDswitch"
							onClick={() => ButtonClickAtion('3D')} 
							style={{width: '20px',height:'30px',marginBottom:'10px',background:threeDbackrColor, borderWidth: '1px', borderColor:DarkGreen.layersBoxBorderColor}}>3D</Button>
						<Button 
							bg="rgba(255,255,255,0.9)"//{ DarkGreen.ButtonHighlightColor }//
							style={{marginLeft: '6px',padding: "2px",borderRadius: '7px 7px 7px 7px',width: '1px',height:'30px',borderColor: DarkGreen.layersBoxBorderColor ,borderWidth: "3px",marginBottom:'10px',}} 
							_hover={{
								bg : DarkGreen.ButtonHighlightColor
							}}
							onClick={() => fullscreen()}
							>

							<img width="20px" id= "FullScreenIcon"  src= {fullScreenIcon.url} />
							</Button>

					</HStack>


						<HStack justify="space-between">
							<Accordion allowToggle w="full">
								<AccordionItem borderColor="transparent">
									<HStack justifyContent="space-between">
										<Text fontSize="lg" fontWeight="semibold" color={DarkGreen.layersBoxBorderTextColor}>
											{t('2D Layers')}
										</Text>
										<HStack spacing={0}>
											<AccordionButton p="0">
												<AccordionIcon color={DarkGreen.iconColor}/>
											</AccordionButton>
										</HStack>
									</HStack>
									<AccordionPanel p={'0.5rem 0.5rem 0.5rem 0'}>
										<Layer
											isDronodat={isDronodat}
											onChange={(newData) => {
												const projectId = Object.keys(newData)[0];
												// console.log(projectId)
												onGeoDataChange(Object.values(newData)[0]);
												const tempGeoData = { ...geoData };
												delete tempGeoData[projectId];
												if (
													[].concat(...Object.values({ ...newData })).length >=
													[].concat(...Object.values(geoData)).length
												)
													setRezoom(true);
												else setRezoom(false);
												setGeoData({
													...newData,
												});
											}}
											// is_assigned={missionId? "" : "false"} // FILTER OUT  Lines already assigned to a mission (edit Elemnts also)
											project={{
												id: projectId,
												name: '',
											}}
											mission={missionId}
											setLoading={setLoading}
										/>
										<Accordion
											mt="0.5rem"
											defaultIndex={[0]}
											allowToggle
											w="full"
										>
											<AccordionItem borderColor="transparent">
												<AccordionButton gap="0.5rem" p="0">
													<Text fontSize="sm" color={DarkGreen.layersBoxBorderTextColor}>
														{t('map.MapStyle')}
													</Text>
													<AccordionIcon color={DarkGreen.iconColor}/>
												</AccordionButton>
												<AccordionPanel py="0.5rem">
													<RadioGroup
														flexDir="column"
														display="flex"
														gap="0.5rem"
														defaultValue="mapbox://styles/mapbox/dark-v10"
														color={DarkGreen.layersBoxBorderTextColorMuted}
														onChange={(v) => {
															map.current.setStyle(v);
															setTimeout(() => {
																map.current.loadImage(
																	'/assets/images/power.png',
																	(error, image) => {
																		if (error) return;
																		map.current.addImage('power', image);
																		setRefresh(refresh + 1);
																	},
																);
																map.current.loadImage(
																	'/assets/images/pin.png',
																	(error, image) => {
																		if (error) return;
																		map.current.addImage('pin', image);
																		setRefresh(refresh + 1);
																	},
																);
															}, 500);
														}}
													>
														<Radio
															size="sm"
															colorScheme="gray"
															value="mapbox://styles/mapbox/dark-v10"
														>
															{t('map.Dark')}
														</Radio>
														<Radio
															size="sm"
															colorScheme="gray"
															value="mapbox://styles/mapbox/satellite-v9"
														>
															{t('map.Satellite')}
														</Radio>
													</RadioGroup>
												</AccordionPanel>
											</AccordionItem>
										</Accordion>
									</AccordionPanel>
								</AccordionItem>

								<AccordionItem borderColor="transparent">
								
									<HStack justifyContent="space-between">
										<Text fontSize="lg" fontWeight="semibold" color={DarkGreen.layersBoxBorderTextColor}>
											{t('3D Layers')}
										</Text>
										<HStack spacing={0}>
											<AccordionButton p="0">
												<AccordionIcon color={DarkGreen.iconColor} />
											</AccordionButton>
										</HStack>
									</HStack>
									<AccordionPanel style={{position: 'relative',top: '0px', left: '-29px'}} p={'0.5rem 0.5rem 0.5rem 0'}>
									
											<div id="scene_objects"></div>

									</AccordionPanel>

								</AccordionItem>

								
							</Accordion>
						</HStack>
				</Box>
		
		
			</div>
		</>
	);
}

export default Map;
