import {
	Button,
	useToast,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { FiKey } from 'react-icons/fi';
import { changePassword } from '../../models/auth';
import Subtitle from '../shared/Subtitle';
import Title from '../shared/Title';
import * as Yup from 'yup';
import CustomInput from '../shared/Input';

function ChangePassword() {
	const toast = useToast();

	return (
		<>
			<Title
				data-aos="fade-down"
				title="Change Password"
				px={{ md: '4rem', base: '2rem' }}
			/>
			<Subtitle
				data-aos="fade-down"
				subtitle="Strong password required. Do not include common words or names."
				px={{ md: '4rem', base: '2rem' }}
			/>
			<Formik
				initialValues={{
					old_password: '',
					new_password1: '',
					new_password2: '',
				}}
				validationSchema={Yup.object().shape({
					old_password: Yup.string()
						.min(8, 'Password must be at least 8 characters')
						.required('Password is required'),
					new_password1: Yup.string()
						.min(8, 'Password must be at least 8 characters')
						.matches(
							/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
							'Must contain at least one lowercase letter, one uppercase letter, one number and one special character',
						)

						.required('Password is required'),
					new_password2: Yup.string()
						.oneOf([Yup.ref('new_password1'), null], 'Passwords must match')
						.required('Password confirmation is required'),
				})}
				onSubmit={async (values, actions) => {
					try {
						await changePassword(
							values.old_password,
							values.new_password1,
							values.new_password2,
						);

						toast({
							title: 'Password Changed.',
							description: 'Your password changed successfully',
							status: 'success',
							duration: 3000,
							isClosable: true,
							position: 'bottom-right',
						});
					} catch (e) {
						toast({
							title: 'Something went wrong.',
							description: "Password doesn't match the requirements",
							status: 'error',
							duration: 3000,
							isClosable: true,
							position: 'bottom-right',
						});
						const errors = e.response.data;
						actions.setErrors({
							old_password: errors.old_password?.pop(),
							new_password1: errors.new_password1?.pop(),
							new_password2: errors.new_password2?.pop(),
						});
					}
					actions.setSubmitting(false);
				}}
			>
				{(props) => (
					<Form data-aos="fade-down">
						<Field name="old_password">
							{({ field, form }) => (
								<CustomInput
									label={'Old password'}
									type={'password'}
									placeholder={'********'}
									icon={FiKey}
									error={form.errors.old_password}
									touched={form.touched.old_password}
									field={field}
								/>
							)}
						</Field>
						<Field name="new_password1">
							{({ field, form }) => (
								<CustomInput
									label={'New password'}
									type={'password'}
									placeholder={'********'}
									icon={FiKey}
									error={form.errors.new_password1}
									touched={form.touched.new_password1}
									field={field}
								/>
							)}
						</Field>
						<Field name="new_password2">
							{({ field, form }) => (
								<CustomInput
									label={'Confirm new password'}
									type={'password'}
									placeholder={'********'}
									icon={FiKey}
									error={form.errors.new_password2}
									touched={form.touched.new_password2}
									field={field}
								/>
							)}
						</Field>
						<Button
							mx={{ md: '6rem', base: '1rem' }}
							background="transparent linear-gradient(180deg, #00965B 0%, #065688 100%) 0% 0% no-repeat padding-box;"
							_hover={{
								background:
									'transparent linear-gradient(180deg, #065688 0%, #00965B 100%) 0% 0% no-repeat padding-box;',
							}}
							isLoading={props.isSubmitting}
							type="submit"
							borderRadius='0.25rem'
							color="white"
							minW={{ lg: '310px', base: 'auto' }}
						>
							Submit
						</Button>
					</Form>
				)}
			</Formik>
		</>
	);
}

export default ChangePassword;
