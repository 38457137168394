export default {
	// style object for base or default style
	baseStyle: {
		color: 'white',
	},
	// styles for different sizes ("sm", "md", "lg")
	sizes: {},
	// styles for different visual variants ("outline", "solid")
	variants: {},
	// default values for `size` and `variant`
	defaultProps: {},
};
