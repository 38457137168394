import { Center, Heading, Text, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PAGES } from '../../shared/routes';
import Wrapper from '../shared/Wrapper';

function Error({ title, subtitle }) {
	const navigate = useNavigate();
	const { t } = useTranslation();

	// useEffect(() => {
	// 	if (!PAGES.NOT_FOUND.includes(window.location.pathname) && !PAGES.NOT_AUTHORIZED.includes(window.location.pathname)) {
	// 		navigate(PAGES.NOT_FOUND);
	// 	}
	// });

	return (
		<Wrapper showOverlay>
			<Center data-aos="zoom-out" gap="2rem" flexDir="column" h="100%">
				<Heading color="#2F98A6" size="4xl">
					{title}
				</Heading>
				<Text color="white" fontWeight={300}>
					{subtitle}
				</Text>
				<Button
					background="transparent linear-gradient(180deg, #00965B 0%, #065688 100%) 0% 0% no-repeat padding-box;"
					borderRadius={'0.25rem'}
					w="full"
					maxW="310px"
					color="white"
					// alignSelf="flex-start"
					_hover={{
						background:
							'transparent linear-gradient(180deg, #065688 0%, #00965B 100%) 0% 0% no-repeat padding-box;',
					}}
					onClick={() => {
						navigate(PAGES.HOME);
					}}
				>
					{t('BackToHome')}
				</Button>
			</Center>
		</Wrapper>
	);
}

export default Error;
