import { Container } from '@chakra-ui/react';
import { useStore } from '../../store';
import Wrapper from '../shared/Wrapper';
import Header from './Header';
import Sidebar from './Sidebar';

function Navigator({ children }) {
	const { isOpen, onOpen, onClose } = useStore();

	return (
		<Wrapper isNavigator showOverlay>
			<Header isOpen={isOpen} />
			<Sidebar isOpen={isOpen} onClose={onClose}onOpen={onOpen} />
			<Container
				m="0"
				mt="7.1875rem"
				mb="7.1875rem"

				ml={{ lg: isOpen ? "17rem" : '4rem', base: "2rem" }}
				px="0"
				
				style={{ transition: isOpen ? 'margin-left 0.6s ease-in-out, max-width 0.6s ease-in-out' : 'margin-left 0.6s ease-in-out, max-width 0.6s ease-in-out' }}
				
				maxW={{
					lg: `calc(100% - ${isOpen ? '17rem' : '4rem'})`,
					base: `calc(100% - ${isOpen ? '4rem' : '4rem'})`,
				}}
				// w="100%"
			>
				{children}
			</Container>
		</Wrapper>
	);
}

export default Navigator;
