import {
	Icon,
	Spinner,
	Table,
	TableProps,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Text,
	HStack,
	Container,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { table } from 'console';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	TiArrowUnsorted,
	TiArrowSortedDown,
	TiArrowSortedUp,
} from 'react-icons/ti';
import { DarkGreen } from '../../../shared/enums';
// import { PaginationOptions, Roles, Status }
interface CustomTableProps extends TableProps {
	min_width?: string
	data?: any[];
	isLoading?: boolean;
	columns?: {
		title: string;
		accessor?: string;
		sortable?: boolean;
		variableName?: string;
		padding?:string;
		paddingY?:string;
		tdMaxWidth?:string;
	}[];
	customSort?: (name?: string, order?: string) => void;
}

function CustomTable(props: CustomTableProps) {
	const { min_width ="1100px", data = [], isLoading = false, columns, customSort, ...rest } = props;
	const [sort, setSort] = useState({ type: '', order: 'asc' });
	const [sortedData, setSortedData] = useState(data);
	const { t } = useTranslation();
	// const padding = "1rem"
	// const paddingY =""
	useEffect(() => {
		setSortedData(data);
		// setSort({ type: '', order: 'asc' });
	}, [data]);

	useEffect(() => {
		let tempData = [...data];
		if (sort.type) {
			if (sort.order === 'asc') {
				tempData = tempData.sort((a, b) =>
					a[sort.type] > b[sort.type] ? 1 : -1,
				);
				!customSort && setSortedData(tempData);
			} else {
				tempData = tempData.sort((a, b) =>
					a[sort.type] > b[sort.type] ? -1 : 1,
				);
				!customSort && setSortedData(tempData);
			}
		} else {
			!customSort && setSortedData(tempData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sort]);

	return (
		<Container
			borderWidth=" 1.5px 1.5px 1.5px 1.5px"
			borderColor={DarkGreen.tableBorderColor}
			borderRadius="20px" 
			// bg={DarkGreen.tableBackgroundColor}
			px="0"
			w="100%"
			maxWidth="none"
			minW={min_width}
			// my="1rem"

		>
		<Table 
			width="100%"  
			h="fit-content"
			bg= "transparent"
			style={{ borderCollapse: "collapse",borderRadius:"20px"}}		
			mt="1rem"
			mb="1rem"
			overflowX= "auto"
			text-overflow= "auto"
			{...rest}
		>
			<Thead>
				<Tr>
					{columns.map((column, index) => (
						<Th				 
							// position={"sticky"}
							border="none"
							background= {DarkGreen.tableHeaderColor}
							color="white"
							fontSize="1rem"
							fontWeight="bold"
							key={index}
							h="4.1875rem"
							verticalAlign= "center"
							textAlign="center"
							p= {column.padding? column.padding: "1rem" }
							w={column.tdMaxWidth? column.tdMaxWidth : "auto"}

						>
							{column.title}
							{column.sortable ? (
								sort.type === column.accessor ? (
									sort.order === 'desc' ? (
										<Icon
											as={TiArrowSortedDown}
											ml="0.5rem"
											mb="0.1rem"
											verticalAlign="bottom"
											cursor="pointer"
											color = {DarkGreen.iconColor} 
											onClick={() => {
												customSort && customSort(column.variableName, '');
												setSort({ type: column.accessor, order: 'asc' });
											}}
										/>
									) : (
										<Icon
											as={TiArrowSortedUp}
											ml="0.5rem"
											mb="0.1rem"
											verticalAlign="bottom"
											cursor="pointer"
											color = {DarkGreen.iconColor} 
											onClick={() => {
												customSort && customSort(column.variableName, '');
												setSort({ type: '', order: 'asc' });
											}}
										/>
									)
								) : (
									<Icon
										as={TiArrowUnsorted}
										color = {DarkGreen.iconColor} 
										ml="0.5rem"
										mb="0.1rem"
										verticalAlign="bottom"
										cursor="pointer"
										onClick={() => {
											customSort && customSort(column.variableName, '-');
											setSort({ type: column.accessor, order: 'desc' });
										}}
									/>
								)
							) : (
								<></>
							)}
						</Th>
					))}
				</Tr>
			</Thead>
			{isLoading ? (
					<Tbody>				
					<Tr>
						<Td py="4rem" border="none" textAlign="center" colSpan={99}>
							<Spinner size="xl" />
						</Td>
					</Tr>
				</Tbody>
			) : sortedData.length > 0 ? (
				<Tbody>
					{sortedData.map((item, index) => (
						<Tr 
						borderLeft =  '1px solid '
						borderLeftColor="transparent"
						_hover={{
								background: "rgba(255,255,255,0.1)",//DarkGreen.tableHighlightedColor,
								borderLeftColor: DarkGreen.tableHighlightedColor,
							}}
						key={index}
						>
							
							{columns.map((column, index) => (
								<Td
									p= {column.padding? column.padding : "1rem" }
									maxW={column.tdMaxWidth? column.tdMaxWidth : "auto"}
									// w="100%"
									py={ column.paddingY? column.paddingY: {lg: '1.5rem', base: '0.8rem'} }
									border="none"
									overflowX="auto"
									// h="4.1875rem"
									verticalAlign= "center"
									textAlign="center"
									key={index}
									fontSize="1rem"
									css={{
										'&::-webkit-scrollbar': {
										  width: '0px',
										  background: 'transparent'
										},
										'&::-webkit-scrollbar-thumb': {
										  display: 'none'
										},
										'&::-webkit-scrollbar-track': {
										  display: 'none'
										},
										'-ms-overflow-style': 'none', /* IE and Edge */
										'scrollbar-width': 'none' /* Firefox */
									  }}

								>
									
									{item[column.accessor] ? item[column.accessor] : '-'}
								</Td>
							))}

						</Tr>
						// </Container>

					))}
				</Tbody>
			) : (
				<Tbody>
					<Tr>
						<Td border="none" textAlign="center" colSpan={99}>
							<Text py="4rem" fontSize="24px">
								{t('NoData')}
							</Text>
						</Td>
					</Tr>
				</Tbody>
			)}
			
		</Table>
		</Container>
	);
}


export default CustomTable;
